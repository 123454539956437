<div class="authorization-filter">
  <div class="rkt-form">
    <div class="rkt-form-control">
      <div class="rkt-form-field">
        <div class="rkt-form-select-field">
          <ng-select
            #select
            class="rkt-form-select rkt-form-select-assignee-dropdown"
            [items]="items$ | async"
            placeholder="Select Debit Authorization"
            [virtualScroll]="false"
            [tabIndex]="0"
            [(ngModel)]="selectedItem"
            bindLabel="label"
            bindValue="value"
            [clearable]="true"
            [multiple]="false"
            [searchable]="true"
            (change)="onChange($event)"
            [closeOnSelect]="true"
            [typeahead]="searchInput$"
            [minTermLength]="0"
            appendTo="body"
            [maxSelectedItems]="1"
            (open)="onOpen()"
            (close)="onClose()"
          ></ng-select>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!(debitAuthorizations$ | async)?.length && !loading">
    No valid Debit Authorization found, please select Offline Debit Authorization if authorization has been provided.
  </div>
</div>
