import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Subject, finalize, takeUntil } from 'rxjs';
import { formInput, formRow, formSelect, formTextarea } from '@rocketfinancialcorp/rocket-ui/form';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';
import { CardAccountService, NotificationService } from '@shared/services';
import { DISPUTE_REASONS } from '@shared/enums';
import { CreateDisputeData, FormModel } from '@shared/models';
import { MESSAGE } from '@shared/constants';
import { ErrorUtils } from '@shared/utils';

interface DisputeCreateModel {
  transactionId: string;
  disputeReason: string;
  disputeDescription: string;
  transactionDetailsPage: boolean;
  financialAccountId: string;
}

@Component({
  selector: 'app-dispute-create-modal',
  templateUrl: './dispute-create-modal.component.html',
})
export class DisputeCreateModalComponent implements AfterViewChecked, OnDestroy {
  loading = false;

  submitError = '';

  formModel: FormModel<DisputeCreateModel> = {};

  disputeForm = new FormGroup({});

  disputeFields: FormlyFieldConfig[] = [
    formRow([
      formInput({
        key: 'transactionId',
        label: 'Dispute Transaction ID',
        props: { required: true },
        expressions: {
          'props.disabled': 'model?.transactionDetailsPage',
        },
      }),
    ]),
    formRow([
      formSelect({
        key: 'disputeReason',
        label: 'Dispute Reason',
        props: { options: Object.keys(DISPUTE_REASONS).map((key) => ({ label: DISPUTE_REASONS[key], value: key })), required: true },
      }),
    ]),
    formRow([
      formTextarea({
        key: 'disputeDescription',
        label: 'Dispute Description',
        className: 'dispute-create-description',
        props: { withCounter: true, maxLength: 1000, description: 'Do not enter the card/account details' },
      }),
    ]),
  ];

  selectedType?: string;

  financialAccountId?: string;

  private destroy$ = new Subject<void>();

  constructor(
    public activeModal: ActiveModal,
    private ref: ChangeDetectorRef,
    private cardAccountService: CardAccountService,
    private notificationService: NotificationService,
  ) {}

  modalInitData({ transactionId, financialAccountId }: { transactionId?: string; financialAccountId?: string }) {
    this.formModel = {
      ...this.formModel,
      transactionId: transactionId?.toUpperCase(),
      transactionDetailsPage: !!transactionId,
      financialAccountId,
    };
  }

  ngAfterViewChecked() {
    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onSubmit(): void {
    const { disputeDescription, transactionId, disputeReason, financialAccountId } = this.formModel;

    if (!transactionId || !disputeReason) {
      return;
    }

    const createDisputeData: CreateDisputeData = {
      reasonCode: disputeReason!,
      reasonDescription: disputeDescription,
      transactionId,
    };

    this.loading = true;

    this.cardAccountService
      .createDispute(financialAccountId!, createDisputeData)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.activeModal.close();
          this.notificationService.displaySuccess('Dispute successfully created.');
        },
        error: (error) => {
          this.submitError = error === 403 ? MESSAGE.PERMISSION_DENIED : MESSAGE.GENERIC_ERROR;
          ErrorUtils.catchError('cardAccountService.createDispute error', error);
        },
      });
  }
}
