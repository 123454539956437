<app-attention-modal type="error" [title]="modalTitle" [messageTemplate]="message" [buttonsTemplate]="buttons"></app-attention-modal>

<ng-template #message>
  <div *ngIf="isLimitError" class="error-message">
    {{ errorMessage }}
    <div class="limits-list" [innerHtml]="listOfExceededLimits"></div>
  </div>
  <div *ngIf="!isLimitError">{{ errorMessage }}</div>
</ng-template>

<ng-template #buttons>
  <button rktButton type="button" (click)="activeModal.close(true)" title="Continue">Continue</button>
</ng-template>
