<div class="list-page-header">
  <div class="list-page-title">Batches</div>

  <div class="list-page-controls">
    <rkt-table-search (changed)="onSearch($event)" placeholder="Search by ID"></rkt-table-search>

    <div class="list-filters">
      <button type="button" class="table-header-filter-btn" (click)="filterMenu.toggle($event)" #filterBtn>
        <rkt-icon name="filter" class="table-header-filter-icon"></rkt-icon>
        Filter
        <span class="table-header-filter-counter" *ngIf="activeFiltersCount">{{ activeFiltersCount }}</span>
      </button>
    </div>

    <button
      rktButton
      (click)="onCreateBatchBtnClick()"
      *ngIf="createBatchFlag$ | async"
      title="Add Batch"
      [disabled]="!!(loading | accessControl | async)"
      class="add-entity-btn"
    >
      <rkt-icon name="plus"></rkt-icon>
      Add Batch
    </button>
  </div>
</div>

<rkt-table
  [rows]="(listItems$ | async) || []"
  [columns]="columns"
  (fetchData)="getTransactionBatches($event)"
  [count]="(totalElements$ | async) || 0"
  [loadingIndicator]="loading"
  [offset]="activePage"
  [sortParam]="sortParams"
  [messages]="{ emptyMessage: emptyListMessage }"
  dateTimeFormat="MM/dd/yy HH:mm"
  emptyValuePlaceholder="--"
  (rowActivated)="onRowClick($event)"
></rkt-table>

<app-filter-menu
  #filterMenu
  [initialValues]="activeFilters"
  [filters]="filters"
  (opened)="filterBtn.classList.add('opened')"
  (closed)="filterBtn.classList.remove('opened')"
  (saved)="applyFilters($event)"
></app-filter-menu>
