<h1 class="title">Reverse Transaction</h1>

<button class="close-button" (click)="activeModal.close()"><rkt-icon name="times"></rkt-icon></button>

<form [formGroup]="reasonForm">
  <rkt-form [form]="reasonForm" [fields]="formFields" [model]="formModel"></rkt-form>

  <div class="buttons">
    <button rktButton type="button" btnType="tertiary" (click)="activeModal.close()">Cancel</button>
    <button rktButton type="button" (click)="activeModal.close(formModel.reason)" [disabled]="reasonForm.invalid">Confirm</button>
  </div>
</form>
