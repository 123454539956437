<ng-container *ngIf="disputeDetails$ | async as dispute; else disputeDetailsLoading">
  <ng-container *ngTemplateOutlet="disputeDetails; context: { $implicit: dispute, loading: false }"></ng-container>
</ng-container>
<ng-template #disputeDetailsLoading>
  <ng-container *ngTemplateOutlet="disputeDetails; context: { $implicit: null, loading: true }"></ng-container>
</ng-template>

<ng-template #disputeDetails [appTemplateContext]="disputeDetailsContext" let-dispute let-loading="loading">
  <app-card-pages-breadcrumbs [loading]="loading" cardPage="disputeDetails"></app-card-pages-breadcrumbs>

  <div class="entity-details-container card-account-details-page">
    <div class="details-section-title">
      <div class="title">
        <app-text-with-placeholder [showPlaceholder]="loading">Dispute Details</app-text-with-placeholder>
      </div>
    </div>

    <div class="details-row details-row-with-offset">
      <app-details-item
        [class]="pageClassPrefix + 'financial-account-id'"
        [showPlaceholder]="loading"
        label="Financial Account ID"
        [value]="dispute?.financialAccountId || '' | rktShortId"
      ></app-details-item>

      <app-details-item [showPlaceholder]="loading" label="Dispute Type" [value]="dispute?.disputeType"></app-details-item>

      <app-details-item [showPlaceholder]="loading" label="Dispute Description" [value]="dispute?.disputeDescription"></app-details-item>

      <app-details-item
        [showPlaceholder]="loading"
        label="Disputed Transaction ID"
        [value]="dispute?.disputedTransaction?.transactionId"
      ></app-details-item>

      <app-details-item
        [showPlaceholder]="loading"
        label="Disputed Transaction Amount"
        [value]="dispute?.disputedTransaction?.disputeAmount"
      ></app-details-item>

      <app-details-item
        [showPlaceholder]="loading"
        label="Dispute Reason"
        [value]="dispute?.disputedTransaction?.disputeReason"
      ></app-details-item>

      <app-details-item [showPlaceholder]="loading" label="Dispute Case Number" [value]="dispute?.disputeCaseNumber"></app-details-item>

      <app-details-item [showPlaceholder]="loading" label="Status" [value]="dispute?.disputeStatus"></app-details-item>

      <app-details-item [showPlaceholder]="loading" label="Created At" [value]="dispute?.logDateTime | dateFormat"></app-details-item>
    </div>
  </div>
</ng-template>
