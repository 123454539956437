import {
  RktFormFieldConfig,
  formDatepicker,
  formInput,
  formInputPhone,
  formRadioGroup,
  formRow,
  formSelectCountry,
  formInputSecure,
} from '@rocketfinancialcorp/rocket-ui/form';
import { Patterns } from '@shared/components';
import { getAddressFields } from './beneficiary-form-utils';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Beneficiary } from '@shared/models';

const disableUnverifiedFieldOption = {
  expressions: {
    'templateOptions.disabled': (field: FormlyFieldConfig) => {
      const { actionType, verificationStatus } = field.model;
      return verificationStatus?.status !== 'UNVERIFIED' && actionType === 'edit';
    },
  },
};

export const editFormFields: RktFormFieldConfig[] = [
  formRow([
    formInput({
      key: 'firstName',
      label: 'First Name',
      props: {
        pattern: Patterns.GENERIC_NAME,
        required: true,
        minLength: 1,
        maxLength: 40,
      },
      ...disableUnverifiedFieldOption,
      validation: { messages: { pattern: 'Enter a valid first name' } },
    }),
    formInput({
      key: 'middleName',
      label: 'Middle Name',
      props: {
        pattern: Patterns.GENERIC_NAME,
        minLength: 1,
        maxLength: 40,
      },
      ...disableUnverifiedFieldOption,
      validation: { messages: { pattern: 'Enter a valid middle name' } },
    }),
    formInput({
      key: 'lastName',
      label: 'Last Name',
      props: {
        pattern: Patterns.GENERIC_NAME,
        required: true,
        minLength: 1,
        maxLength: 40,
      },
      ...disableUnverifiedFieldOption,
      validation: { messages: { pattern: 'Enter a valid last name' } },
    }),
  ]),
  formRow([
    {
      type: '#email',
      key: 'primaryEmail',
      props: { pattern: Patterns.EMAIL, minLength: 1, maxLength: 100, disabled: false, required: false },
      validation: {
        messages: {
          pattern: 'Enter a valid email',
        },
      },
    },
    formInputPhone({
      key: 'primaryPhoneNumber',
      label: 'Phone Number',
      className: 'half-row-field',
      props: {
        required: false,
        minLength: 10,
        maxLength: 40,
        countryRestrictions: ['US'],
        restrictedCountryMessage: 'The phone number is not valid for country USA',
      },
      validators: { validation: [{ name: 'isValidPhoneNumber' }] },
    }),
  ]),
  formRow([
    formDatepicker({
      key: 'dateOfBirth',
      label: 'Date Of Birth',
      props: { required: false, placeholder: 'MM/DD/YY' },
      ...disableUnverifiedFieldOption,
    }),
    formInput({
      key: 'cityOfBirth',
      label: 'City of Birth',
      props: { pattern: Patterns.PLACE_OF_BIRTH, required: false, maxLength: 60 },
      validation: {
        messages: {
          pattern: 'Enter a valid place of birth',
        },
      },
    }),
    formSelectCountry({ key: 'countryOfBirth', label: 'Country of Birth', props: { placeholder: 'Select' } }),
  ]),
  formRow([
    formInput({
      key: 'title',
      label: 'Job Title',
      props: {
        pattern: Patterns.GENERIC_NAME,
        minLength: 1,
        maxLength: 100,
      },
      validation: { messages: { pattern: 'Enter a valid job title' } },
    }),
  ]),

  formRow([
    formRadioGroup({
      key: 'sex',
      label: 'Sex',
      className: 'single-row-radio-group',
      props: {
        options: [
          { label: 'Male', value: 'MALE' },
          { label: 'Female', value: 'FEMALE' },
          { label: 'Non-Binary', value: 'NONBINARY' },
        ],
      },
    }),
  ]),
  formRow([
    formRadioGroup({
      key: 'controller',
      label: 'Controller',
      className: 'single-row-radio-group',
      props: {
        required: false,
        options: [
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ],
      },
    }),
  ]),
];

export const editOwningFormFields = (beneficary?: Beneficiary) => {
  return [
    formRow([
      formInput({
        key: 'socialId',
        label: 'Social ID (SSN)',
        props: {
          required: false,
          pattern: Patterns.SOCIAL_SECURITY_NUMBER,
          maxLength: 11,
          minLength: 9,
          maskString: 'XXX-XX-XXXX',
          placeholder: 'XXX-XX-XXXX',
        },
        validation: {
          messages: {
            pattern: 'Please enter a valid social ID number',
          },
        },
        expressions: {
          hide: '!!model.maskedSocialId',
        },
      }),
      formInputSecure({
        key: 'socialId',
        label: 'Social ID (SSN)',
        className: beneficary?.verificationStatus?.status !== 'UNVERIFIED' ? 'secured-input-field field-disable' : 'secured-input-field',
        props: {
          required: false,
          pattern: Patterns.SOCIAL_SECURITY_NUMBER,
          maxLength: 11,
          minLength: 9,
          maskString: 'XXX-XX-XXXX',
          placeholder: 'XXX-XX-XXXX',
          securedValuePrefix: 'XXX-XX-',
        },
        validation: {
          messages: {
            pattern: 'Please enter a valid social ID number',
          },
        },
        expressions: {
          hide: '!model.maskedSocialId',
        },
      }),
      formSelectCountry({
        key: 'socialIdCountryCode',
        label: 'Social ID Issuing Country',
        props: { placeholder: 'Select', countryRestrictions: ['US'], required: false },
      }),
      formSelectCountry({
        key: 'citizenshipCountryCode',
        label: 'Citizenship Country',
        props: { placeholder: 'Select' },
      }),
    ]),
    formRow([
      formInput({
        key: 'ownershipPercentage',
        label: 'Percentage of Business owned',
        className: 'percentage-owned-row-field',
        props: {
          pattern: Patterns.PERCENT100,
        },
        validation: { messages: { pattern: 'Must be less or equal to 100' } },
      }),
    ]),
  ];
};

export const editAddressFormFields = ({
  fieldLinkClick,
  isEdit,
}: {
  fieldLinkClick: (type: 'Mailing' | 'Shipping') => void;
  isEdit: boolean;
}): RktFormFieldConfig[] => {
  return [
    ...getAddressFields({ type: 'Physical', key: 'physicalAddress', isRequired: isEdit, isEdit }),
    ...getAddressFields({
      type: 'Mailing',
      key: 'mailingAddress',
      isRequired: isEdit,
      isEdit,
      fieldLinkClick: () => fieldLinkClick('Mailing'),
    }),
    ...getAddressFields({
      type: 'Shipping',
      key: 'shippingAddress',
      isRequired: isEdit,
      isEdit,
      fieldLinkClick: () => fieldLinkClick('Shipping'),
    }),
  ];
};
