import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { NavigationEnd, Router } from '@angular/router';
import { Subject, filter, takeUntil } from 'rxjs';
import { FinancialAccountDetails, TemplateContext } from '@shared/models';
import { TabItem } from '@shared/components';
import { fromFinancialAccount, CardAccountActions } from '@shared/store';

@Component({
  selector: 'app-integrated-card-account-page',
  templateUrl: './integrated-card-account-page.component.html',
})
export class IntegratedCardAccountPageComponent implements AfterViewChecked, OnDestroy {
  cardAccount$ = this.store.select(fromFinancialAccount.selectCardAccountDetails);

  cardAccountPageContext?: TemplateContext<FinancialAccountDetails>;

  tabItems: TabItem[] = [
    { label: 'Account Information', href: 'account-details' },
    { label: 'Card Transactions', href: 'card-transactions' },
    { label: 'Alerts', href: 'alerts' },
    { label: 'Disputes', href: 'disputes' },
    { label: 'Statements', href: 'statements' },
  ];

  private destroy$ = new Subject<void>();

  constructor(public ref: ChangeDetectorRef, private router: Router, private store: Store) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: () => {
          this.store.dispatch(CardAccountActions.loadCardAccountDetails());
        },
      });
  }

  public ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
