import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { NavigationService } from '@shared/services';
import { TemplateContext, TransactionDetails, TransactionHistory, SingleLegTransactionFinancialAccount } from '@shared/models';
import { SingleLegTransactionActions, TransactionFormActions, selectSingleLegTransactionDetailsWithHistory } from '@shared/store';
import { AttachmentsListComponent } from '@shared/components';

@Component({
  selector: 'app-view-transaction',
  templateUrl: './view-transaction.component.html',
  styleUrls: ['./view-transaction.component.scss'],
})
export class ViewTransactionComponent implements AfterViewChecked, OnDestroy {
  @ViewChild('attachmentsList') attachmentsList!: AttachmentsListComponent;

  pageClassPrefix = 'transaction-details-';

  transactionData$ = this.store.select(selectSingleLegTransactionDetailsWithHistory);

  transactionDetailsContext?: TemplateContext<TransactionDetails>;

  transactionHistoryContext?: TemplateContext<TransactionHistory[]>;

  hasNotes = true;

  isFromFA = this.router.url.split('/').includes('financial-accounts');

  isFromExceptions = this.router.url.split('/').includes('exceptions');

  pageReturnURL = this.router.url.split('/').slice(0, -1).join('/');

  pageReturnURLTwoLevels = this.router.url.split('/').slice(0, -2).join('/');

  breadcrumbList = this.isFromFA
    ? [
        { label: 'Financial Accounts', url: '/app/settings/financial-accounts' },
        { label: 'Account Activity', url: this.pageReturnURL },
        { label: 'Transaction Details' },
      ]
    : this.isFromExceptions
    ? [
        { label: 'Exceptions', url: '/app/exception-management/exceptions' },
        { label: 'Exception Details', url: this.pageReturnURLTwoLevels },
        { label: 'Transaction Details' },
      ]
    : [{ label: 'Transaction Activity', url: this.pageReturnURL }, { label: 'Transaction Details' }];

  isShowMetadata = false;

  constructor(
    private ref: ChangeDetectorRef,
    private router: Router,
    private modalService: ModalService,
    private navigationService: NavigationService,
    private store: Store,
  ) {
    this.navigationService.dispatchOnNavigationEnd(SingleLegTransactionActions.loadDetails());
  }

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    this.store.dispatch(TransactionFormActions.createTransactionPageReset());
    this.navigationService.ngOnDestroy();
    this.modalService.dismissAll();
  }

  onAccountHolderClick(financialAccount: SingleLegTransactionFinancialAccount, transactionId: string): void {
    const { id, accountHolderId, accountHolderType, customer } = financialAccount;

    if (!accountHolderId) {
      this.router.navigateByUrl(`/app/transactions/transactions/${transactionId}/financial-accounts/${id}/account-details`);
    } else if (accountHolderType === 'CUSTOMER' && customer) {
      const customerType = customer.type === 'INDIVIDUAL' ? 'individuals' : 'businesses';
      this.router.navigateByUrl(
        `/app/transactions/transactions/${transactionId}/${customerType}/${accountHolderId}/financial-accounts/${id}/account-details`,
      );
    } else if (accountHolderType === 'RECIPIENT') {
      this.router.navigateByUrl(`/app/transactions/transactions/${transactionId}/recipients/${accountHolderId}`);
    }
  }
}
