import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, Event as NavigationEvent, NavigationEnd } from '@angular/router';
import { BehaviorSubject, catchError, filter, Observable, of, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { TransactionBatch, Breadcrumb } from '@shared/models';
import { NotificationService, SubscriberService, TransactionBatchesService } from '@shared/services';
import { ErrorUtils } from '@shared/utils';
import { ConfirmModalComponent, ErrorModalComponent } from '@shared/components';

@Component({
  selector: 'app-transaction-batch-page',
  templateUrl: './transaction-batch-page.component.html',
  styleUrls: ['./transaction-batch-page.component.scss'],
})
export class TransactionBatchPageComponent implements OnDestroy {
  batchId$ = new BehaviorSubject<string | null>(null);

  pageReturnURL = '/app/transactions/transaction-batches';

  transactionBatch$?: Observable<TransactionBatch | null>;

  tabItems = [
    { label: 'Batch Details', href: 'batch-details' },
    { label: 'Transaction Activity', href: 'transaction-activity' },
    { label: 'Rejected Transactions', href: 'rejected-transactions' },
  ];

  breadcrumbList: Breadcrumb[] = [{ label: 'Transaction Batch', url: this.pageReturnURL }, { label: 'Batch Details' }];

  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private transactionBatchesService: TransactionBatchesService,
    private notificationService: NotificationService,
    private modalService: ModalService,
    protected layoutOptions: SubscriberService<TransactionBatch>,
  ) {
    this.router.events
      .pipe(
        filter((event: NavigationEvent) => event instanceof NavigationEnd),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: () => {
          const { snapshot } = this.route;
          this.batchId$.next(snapshot.paramMap.get('id'));
        },
      });

    this.transactionBatch$ = this.batchId$.pipe(
      switchMap((batchId) => {
        return this.transactionBatchesService.getTransactionBatchById(batchId!).pipe(
          tap({ next: (transactionBatch) => this.layoutOptions.next(transactionBatch) }),
          catchError((error) => {
            ErrorUtils.catchError('transactionBatchesService.getTransactionBatchById error', error);
            this.router.navigateByUrl('/app/transactions/transaction-batches');
            return of(null);
          }),
        );
      }),
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onBatchCancelClick(): void {
    const confirmModalRef = this.modalService.open(ConfirmModalComponent, {
      className: 'confirm-modal',
    });

    confirmModalRef.componentInstance.customText = 'Are you sure? Once this batch is cancelled, you will not be able to recover it.';

    confirmModalRef.result.then(
      (result) => {
        if (result) {
          this.cancelBatchTransaction();
        }
      },
      () => false,
    );
  }

  cancelBatchTransaction(): void {
    this.batchId$
      .pipe(
        switchMap((batchId) => this.transactionBatchesService.cancelTransactionBatch(batchId!)),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: () => {
          this.notificationService.displayWarning('Transaction Batch cancelled');
          this.batchId$.next(this.route.snapshot.paramMap.get('id'));
        },
        error: (error) => {
          const cancelErrorModalRef = this.modalService.open(ErrorModalComponent, { className: 'confirm-modal' });
          cancelErrorModalRef.componentInstance.errorType = error;
        },
      });
  }
}
