import { Injectable } from '@angular/core';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { HttpContext, HttpParams } from '@angular/common/http';
import { compact, find } from 'lodash-es';
import { Store } from '@ngrx/store';
import { concatLatestFrom } from '@ngrx/effects';
import { environment } from '@env';
import { CustomHttpParamEncoder } from '@shared/encoder';
import { BackendService } from '@shared/services/backend.service';
import {
  CardAlert,
  CardAlertRaw,
  CardDispute,
  CardDisputeRaw,
  CardIssue,
  CardRestriction,
  CardRestrictionRaw,
  CardStatement,
  CardStatementListRaw,
  CreateAlertData,
  CreateDisputeData,
  CreateRestrictionData,
  EntityFinancialAccountDetailsRaw,
  FinancialAccountDetails,
  FinancialAccountDetailsWithBalancesRaw,
  CardProgram,
  CardProgramRaw,
  CardReissue,
  MccItem,
  MccItemRaw,
  SupportedLanguage,
  CardProduct,
  CardProductsResponse,
  CardProductRestrictions,
  CardProductVelocityRestriction,
  CardProductGeneralRestrictions,
  CardProductVelocityRestrictionCreateData,
  CardProductVelocityRestrictionsResponse,
  CardMerchantRaw,
  CardMerchant,
  CardMerchantListResponse,
} from '@shared/models';
import { CustomerService } from '@shared/services';
import { IS_EXTENDED_ERRORS } from '@shared/interceptors';
import { AuditActions, fromAuth } from '@shared/store';
import { READ_ONLY_ROLES } from '@shared/constants';
import {
  mapCardAccountDetails,
  mapCardAlertItem,
  mapCardAlertItems,
  mapCardRestrictionItem,
  mapCardRestrictionItems,
} from './card-account-mapping-utils';
import { ErrorUtils } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class CardAccountService {
  constructor(private backendService: BackendService, private customerService: CustomerService, private store: Store) {}

  public issueCardAccount(cardIssueData: CardIssue): Observable<EntityFinancialAccountDetailsRaw> {
    return this.backendService
      .post<EntityFinancialAccountDetailsRaw>(`${environment.cardsEndpoint}/cards`, cardIssueData, {
        context: new HttpContext().set(IS_EXTENDED_ERRORS, true),
      })
      .pipe(catchError((errorRes) => throwError(() => errorRes)));
  }

  public reissueCardAccount(cardAccountId: string, cardReissueData: CardReissue): Observable<EntityFinancialAccountDetailsRaw> {
    return this.backendService
      .post<EntityFinancialAccountDetailsRaw>(`${environment.cardsEndpoint}/cards/${cardAccountId}/reissue`, cardReissueData)
      .pipe(catchError((errorRes) => throwError(() => errorRes)));
  }

  public activateCardAccount({ cardAccountId }: { cardAccountId: string }): Observable<EntityFinancialAccountDetailsRaw> {
    return this.backendService
      .post<EntityFinancialAccountDetailsRaw>(`${environment.cardsEndpoint}/cards/${cardAccountId}/activate`)
      .pipe(catchError((errorRes) => throwError(() => errorRes)));
  }

  public getCardAccountDetails({
    businessAccountId,
    cardAccountId,
  }: {
    businessAccountId: string;
    cardAccountId: string;
  }): Observable<FinancialAccountDetails> {
    const params = new HttpParams({ encoder: new CustomHttpParamEncoder() }).set('embed', 'balances,cardProviderDetails');

    return this.backendService
      .get<FinancialAccountDetailsWithBalancesRaw>(
        `${environment.financialAccountFlow}/business-accounts/${businessAccountId}/financial-accounts/${cardAccountId}`,
        { params },
      )
      .pipe(
        tap({
          next: (cardDetailsResponse) => {
            const { createdBy, updatedBy } = cardDetailsResponse;
            this.store.dispatch(AuditActions.loadTeamMembers({ ids: compact([createdBy, updatedBy]) }));
          },
        }),
        map((response) => mapCardAccountDetails(response)),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  public getCardRestrictions({ financialAccountId }: { financialAccountId: string }): Observable<CardRestriction[]> {
    return this.backendService.get<CardRestrictionRaw[]>(`${environment.cardsEndpoint}/cards/${financialAccountId}/restrictions`).pipe(
      map((response) => mapCardRestrictionItems(response)),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  public getCardRestrictionById({
    financialAccountId,
    restrictionId,
  }: {
    financialAccountId: string;
    restrictionId: string;
  }): Observable<CardRestriction> {
    return this.backendService
      .get<CardRestrictionRaw>(`${environment.cardsEndpoint}/cards/${financialAccountId}/restrictions/${restrictionId}`)
      .pipe(
        map((response) => mapCardRestrictionItem(response)),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  public getCardAlerts({ financialAccountId }: { financialAccountId: string }): Observable<CardAlert[]> {
    return this.backendService.get<CardAlertRaw[]>(`${environment.cardsEndpoint}/cards/${financialAccountId}/alerts`).pipe(
      map((response) => mapCardAlertItems(response)),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  public getCardAlertById({ financialAccountId, alertId }: { financialAccountId: string; alertId: string }): Observable<CardAlert> {
    return this.backendService.get<CardAlertRaw>(`${environment.cardsEndpoint}/cards/${financialAccountId}/alerts/${alertId}`).pipe(
      map((response) => mapCardAlertItem(response)),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  public getCardDisputes({ financialAccountId }: { financialAccountId: string }): Observable<CardDispute[]> {
    return this.backendService.get<CardDisputeRaw[]>(`${environment.cardsEndpoint}/cards/${financialAccountId}/disputes`).pipe(
      map((response) => response),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  public getCardDisputeById({ financialAccountId, disputeId }: { financialAccountId: string; disputeId: string }): Observable<CardDispute> {
    return this.backendService.get<CardDisputeRaw>(`${environment.cardsEndpoint}/cards/${financialAccountId}/disputes/${disputeId}`).pipe(
      map((response) => response),
      catchError((errorRes) => throwError(() => errorRes.message)),
    );
  }

  public getCardStatements({ financialAccountId }: { financialAccountId: string }): Observable<CardStatement[]> {
    return this.backendService.get<CardStatementListRaw>(`${environment.cardsEndpoint}/cards/${financialAccountId}/statements`).pipe(
      mergeMap((response) => {
        return this.customerService.getCustomerById(response.customerId).pipe(
          map((customer) => ({
            ...response,
            customerEmail: find(customer.contacts, ['primary', true])?.primaryEmail?.value ?? customer?.primaryEmail?.value,
          })),
        );
      }),
      concatLatestFrom(() => [this.store.select(fromAuth.selectUserRole)]),
      map(
        ([{ generatedStatements, customerEmail }, userRole]) =>
          generatedStatements?.content.map((item) => ({
            ...item,
            customerEmail,
            emailStatement: !READ_ONLY_ROLES.includes(userRole) ? 'emailStatementBtn' : undefined,
            financialAccountId,
          })) ?? [],
      ),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  public sendCardStatementByEmail({
    financialAccountId,
    month,
    year,
  }: {
    financialAccountId: string;
    month: number;
    year: number;
  }): Observable<boolean> {
    return this.backendService.post(`${environment.cardsEndpoint}/cards/${financialAccountId}/statements/${year}/${month}/email`).pipe(
      map(() => true),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  public createDispute(financialAccountId: string, createDisputeData: CreateDisputeData): Observable<boolean> {
    return this.backendService
      .post(`${environment.cardsEndpoint}/cards/${financialAccountId}/disputes`, {
        ...createDisputeData,
      })
      .pipe(
        map(() => true),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  public createRestriction(financialAccountId: string, createRestrictionData: CreateRestrictionData): Observable<boolean> {
    return this.backendService
      .post(`${environment.cardsEndpoint}/cards/${financialAccountId}/restrictions`, { ...createRestrictionData })
      .pipe(
        map(() => true),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  public editRestriction(id: string, createRestrictionData: CreateRestrictionData): Observable<boolean> {
    return this.backendService
      .update(`${environment.cardsEndpoint}/cards/${createRestrictionData.financialAccountId}/restrictions/${id}`, {
        ...createRestrictionData,
      })
      .pipe(
        map(() => true),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  public createAlert(createAlertData: CreateAlertData): Observable<boolean> {
    return this.backendService
      .post(
        `${environment.cardsEndpoint}/cards/${createAlertData.financialAccountId}/alerts`,
        { ...createAlertData },
        { context: new HttpContext().set(IS_EXTENDED_ERRORS, true) },
      )
      .pipe(
        map(() => true),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  public editAlert(id: string, createAlertData: CreateAlertData): Observable<boolean> {
    return this.backendService
      .update(`${environment.cardsEndpoint}/cards/${createAlertData.financialAccountId}/alerts/${id}`, { ...createAlertData })
      .pipe(
        map(() => true),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  public getCardPrograms(): Observable<CardProgram[]> {
    return this.backendService
      .get<CardProgramRaw[]>(`${environment.cardsEndpoint}/programs`)
      .pipe(catchError((errorRes) => throwError(() => errorRes)));
  }

  public getCardProducts(programId?: string): Observable<CardProduct[]> {
    return this.backendService.get<CardProductsResponse>(`${environment.cardsEndpoint}/programs/${programId}/products`).pipe(
      map((response) => response?.content ?? []),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  public getCardIssueSupportedLanguages(): Observable<SupportedLanguage[]> {
    return this.backendService
      .get<SupportedLanguage[]>(`${environment.cardsEndpoint}/supported-languages`)
      .pipe(catchError((errorRes) => throwError(() => errorRes)));
  }

  public getMccItems(): Observable<MccItem[]> {
    return this.backendService.get<MccItemRaw[]>(`${environment.cardsEndpoint}/mcc`).pipe(
      map((result) => {
        return result.map((item) => ({
          label: `${item.mcc}: ${item.mccDescription}`,
          value: `${item.mcc}`,
        }));
      }),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  public getCardProductDetails({ programId, productId }: { programId: string; productId: string }): Observable<CardProduct> {
    return this.backendService
      .get<CardProduct>(`${environment.cardsEndpoint}/programs/${programId}/products/${productId}`)
      .pipe(catchError((errorRes) => throwError(() => errorRes)));
  }

  public getCardProductRestrictions({
    programId,
    productId,
  }: {
    programId: string;
    productId: string;
  }): Observable<CardProductRestrictions> {
    return forkJoin({
      generalRestrictions: this.backendService
        .get<CardProductGeneralRestrictions>(
          `${environment.cardsEndpoint}/programs/${programId}/products/${productId}/general-restrictions`,
        )
        .pipe(
          catchError((error) => {
            ErrorUtils.catchError(error);
            return of(null);
          }),
        ),

      velocityRestrictions: this.backendService
        .get<CardProductVelocityRestrictionsResponse>(
          `${environment.cardsEndpoint}/programs/${programId}/products/${productId}/velocity-restrictions`,
        )
        .pipe(
          catchError((error) => {
            ErrorUtils.catchError(error);
            return of();
          }),
        ),
    }).pipe(
      map((results) => {
        return {
          generalRestrictions: results.generalRestrictions,
          velocityRestrictions: results.velocityRestrictions.content ?? [],
        };
      }),
    );
  }

  public createCardProductVelocityRestriction({
    programId,
    productId,
    createVelocityRestrictionData,
  }: {
    programId: string;
    productId: string;
    createVelocityRestrictionData: CardProductVelocityRestrictionCreateData;
  }): Observable<boolean> {
    return this.backendService
      .post(`${environment.cardsEndpoint}/pograms/${programId}/products/${productId}/velocity-restrictions`, {
        ...createVelocityRestrictionData,
      })
      .pipe(
        map(() => true),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  public getMerchants({ programId, productId }: { programId: string; productId: string }): Observable<CardMerchant[]> {
    return this.backendService
      .get<CardMerchantListResponse>(`/card/api/portal/v1/programs/${programId}/products/${productId}/merchants`)
      .pipe(
        map(
          (response) =>
            response?.content?.map((item) => ({
              ...item,
              city: item.merchantAddresses?.[0].city,
              state: item.merchantAddresses?.[0].state,
              countryCode: item.merchantAddresses?.[0].countryCode,
            })) ?? [],
        ),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  public getMerchantById({
    programId,
    productId,
    merchantId,
  }: {
    programId: string;
    productId: string;
    merchantId: string;
  }): Observable<CardMerchant> {
    return this.backendService
      .get<CardMerchantRaw>(`/card/api/portal/v1/programs/${programId}/products/${productId}/merchants/${merchantId}`)
      .pipe(
        map((response) => ({ ...response })),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  public updateGeneralRestrictions({
    programId,
    productId,
    generalRestrictions,
  }: {
    programId: string;
    productId: string;
    generalRestrictions: CardProductGeneralRestrictions;
  }): Observable<boolean> {
    return this.backendService
      .update(`/card/api/portal/v1/programs/${programId}/products/${productId}/general-restrictions`, generalRestrictions)
      .pipe(
        map(() => true),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }
}
