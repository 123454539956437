<div class="entity-details-container">
  <div class="details-section basic-info-section">
    <div class="id-row">
      <app-text-with-placeholder [showPlaceholder]="loading">ID: {{ transactionBatch?.id || '' | rktShortId }}</app-text-with-placeholder>
    </div>
  </div>

  <div class="batch-details-wrapper">
    <div class="details-row batch-info">
      <app-details-item
        [class]="pageClassPrefix + 'solution'"
        [showPlaceholder]="loading"
        label="Solution"
        [value]="transactionBatch?.solutionName"
      ></app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'type'"
        [showPlaceholder]="loading"
        label="Type"
        [value]="transactionBatch?.transactionType"
      ></app-details-item>

      <app-details-item
        [class]="pageClassPrefix + 'currency'"
        [showPlaceholder]="loading"
        label="Currency"
        [value]="transactionBatch?.currency"
      ></app-details-item>
    </div>

    <div class="volume-values">
      <div class="details-row">
        <app-details-item
          [class]="pageClassPrefix + 'total-batch-volume'"
          [showPlaceholder]="loading"
          [isDarkBg]="true"
          label="Total Batch Volume"
          [value]="transactionBatch?.transactionCount?.toString()"
        ></app-details-item>

        <app-details-item
          [class]="pageClassPrefix + 'total-batch-value'"
          [showPlaceholder]="loading"
          [isDarkBg]="true"
          label="Total Batch Value"
          [value]="transactionBatch?.totalAmount | currency: transactionBatch?.currency:'symbol-narrow'"
        ></app-details-item>

        <app-details-item
          [class]="pageClassPrefix + 'total-processed-volume'"
          [showPlaceholder]="loading"
          [isDarkBg]="true"
          label="Total Processed Volume"
          [value]="transactionBatch?.processedTransactionCount?.toString()"
        ></app-details-item>

        <app-details-item
          [class]="pageClassPrefix + 'total-processed-value'"
          [showPlaceholder]="loading"
          [isDarkBg]="true"
          label="Total Processed Value          "
          [value]="transactionBatch?.processedTransactionAmount | currency: transactionBatch?.currency:'symbol-narrow'"
        ></app-details-item>

        <app-details-item
          [class]="pageClassPrefix + 'total-failed-volume'"
          [showPlaceholder]="loading"
          [isDarkBg]="true"
          label="Total Failed Volume"
          [value]="transactionBatch?.failedTransactionCount?.toString()"
        ></app-details-item>

        <app-details-item
          [class]="pageClassPrefix + 'total-failed-value'"
          [showPlaceholder]="loading"
          [isDarkBg]="true"
          label="Total Failed Value"
          [value]="transactionBatch?.failedTransactionAmount | currency: transactionBatch?.currency:'symbol-narrow'"
        ></app-details-item>
      </div>
    </div>
  </div>

  <div class="separator-row"></div>

  <div class="batch-details-wrapper batch-dates-wrapper">
    <div class="details-row batch-info">
      <app-details-item
        [class]="pageClassPrefix + 'created-at'"
        [showPlaceholder]="loading"
        label="Created At"
        [value]="transactionBatch?.createdAt | dateFormat"
      ></app-details-item>
    </div>

    <div class="details-row batch-info">
      <app-details-item
        [class]="pageClassPrefix + 'created-by'"
        [showPlaceholder]="loading"
        label="Created By"
        [value]="transactionBatch?.createdBy?.id | teamMemberName | async"
      >
      </app-details-item>
    </div>

    <div class="details-row completed-info">
      <app-details-item
        [class]="pageClassPrefix + 'completed-at'"
        [showPlaceholder]="loading"
        label="Completed At"
        [value]="transactionBatch?.updatedAt | dateFormat"
      ></app-details-item>
    </div>
  </div>

  <div class="batch-details-wrapper batch-dates-wrapper">
    <div class="details-row batch-info">
      <app-details-item
        [class]="pageClassPrefix + 'updated-at'"
        [showPlaceholder]="loading"
        label="Updated At"
        [value]="transactionBatch?.updatedAt | dateFormat"
      ></app-details-item>
    </div>

    <div class="details-row batch-info">
      <app-details-item
        [class]="pageClassPrefix + 'updated-by'"
        [showPlaceholder]="loading"
        label="Updated By"
        [value]="transactionBatch?.updatedBy?.id | teamMemberName | async"
      >
      </app-details-item>
    </div>
  </div>

  <div class="separator-row"></div>

  <div class="batch-details-wrapper">
    <div class="details-row batch-info">
      <app-details-item
        [showPlaceholder]="loading"
        [class]="pageClassPrefix + 'batch-mode'"
        label="Batch Mode"
        [value]="transactionBatch?.batchMode"
      ></app-details-item>

      <app-details-item
        [showPlaceholder]="loading"
        [class]="pageClassPrefix + 'settlement-priority'"
        label="Settlement Priority"
        [value]="transactionBatch?.settlementPriority?.replace('_', ' ') | titlecase"
      ></app-details-item>
    </div>

    <div class="description-item">
      <app-details-item
        [isDarkBg]="true"
        [showPlaceholder]="loading"
        [class]="pageClassPrefix + 'description'"
        label="Description"
        [value]="transactionBatch?.description"
      ></app-details-item>
    </div>
  </div>
</div>
