<div class="modal-header-block title">
  <h2>Create a Dispute</h2>

  <button class="close-button" (click)="activeModal.close()"><rkt-icon name="times"></rkt-icon></button>
</div>

<div class="details-page-edit-form">
  <form [formGroup]="disputeForm">
    <rkt-form [form]="disputeForm" [fields]="disputeFields" [model]="formModel"></rkt-form>
  </form>

  <div *ngIf="submitError" class="details-page-edit-form-error">{{ submitError }}</div>

  <div class="buttons">
    <button rktButton type="button" btnType="tertiary" (click)="activeModal.close()">Cancel</button>

    <button rktButton type="submit" (click)="onSubmit()" [disabled]="loading || !disputeForm.valid" title="Next">Create</button>
  </div>
</div>
