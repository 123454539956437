import { Component } from '@angular/core';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';
import { ModalReviewData } from '@shared/models';

@Component({
  templateUrl: './move-money-review.component.html',
  styleUrls: ['./move-money-review.component.scss'],
})
export class MoveMoneyReviewComponent {
  reviewData?: ModalReviewData;

  get isNextDay(): boolean {
    const { moveHowAchDetails, moveHowWireDetails } = this.reviewData ?? {};
    return moveHowAchDetails?.moveAchType === 'NEXT_DAY' || moveHowWireDetails?.moveWireType === 'NEXT_DAY';
  }

  constructor(public activeModal: ActiveModal) {}

  modalInitData(data: ModalReviewData) {
    this.reviewData = data;
  }
}
