<div class="rejected-transactions">
  <rkt-table
    [rows]="transactions"
    [columns]="columns"
    [count]="totalElements"
    (fetchData)="getTransactions($event)"
    [messages]="{ emptyMessage: 'No Transactions found.' }"
    emptyValuePlaceholder="--"
    [loadingIndicator]="loading"
    [isClickable]="false"
    [placeholderItemsCount]="5"
    [rowHeight]="64"
    [isItemsAlignCenter]="true"
  ></rkt-table>
</div>
