<div class="entity-actions dark-bg">
  <button
    appRemoveFocus
    class="action-button action-button-edit"
    [disabled]="!!(isAchReversal || isAchReturn || isEditDisabled || disabled) | accessControl | async"
    title="Edit Transaction"
    (click)="onEditBtnClick()"
  >
    <rkt-icon name="edit"></rkt-icon>
  </button>

  <button
    appRemoveFocus
    class="action-button action-button-reverse"
    [disabled]="!!(isAchReversal || isAchReturn || isReverseDisabled || disabled) | accessControl | async"
    title="Reverse Transaction"
    (click)="onReverseBtnClick()"
  >
    <rkt-icon name="reverse"></rkt-icon>
  </button>

  <button
    appRemoveFocus
    class="action-button action-button-delete"
    [disabled]="!!(isAchReversal || isAchReturn || isCancelDisabled || disabled) | accessControl | async"
    title="Cancel Transaction"
    (click)="onCancelBtnClick()"
  >
    <rkt-icon name="times"></rkt-icon>
  </button>
</div>
