import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { emptySpacesValidator } from '@shared/validators';
import { Breadcrumb } from '@shared/models';

@Component({
  selector: 'app-transaction-batch-create',
  templateUrl: 'transaction-batch-create.component.html',
  styleUrls: ['transaction-batch-create.component.scss'],
})
export class TransactionBatchCreateComponent {
  pageReturnURL = '/app/transactions/transaction-batches';

  batchUploadData = new FormData();

  note = new UntypedFormControl('', [emptySpacesValidator]);

  breadcrumbList: Breadcrumb[] = [{ label: 'Transaction Batch', url: this.pageReturnURL }, { label: 'Create Batch' }];

  get isSubmitDisabled() {
    return !this.batchUploadData.get('file');
  }

  constructor(private router: Router) {}

  onFileUploaded(file: File | null): void {
    if (!file) {
      this.batchUploadData.delete('file');
    } else {
      this.batchUploadData.set('file', file);
    }
  }

  onNoteFieldBlur(): void {
    this.note.setValue(this.note.value?.trim());
  }

  onDiscardBtnClick() {
    this.router.navigate(['app/transactions/transaction-batches']);
  }

  onSubmitBtnClick() {
    console.warn('create batch');
  }
}
