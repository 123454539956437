import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { PortalSharedLibsModule } from './portal-shared-libs.module';
import {
  HeaderComponent,
  PageLeaveConfirmationModalComponent,
  UserHeaderInfoComponent,
  NotesModalComponent,
  NoteItemComponent,
  AddNoteComponent,
  FinancialAccountActionsComponent,
  FinancialAccountDetailsComponent,
  FinancialAccountPageComponent,
  TransactionLimitsComponent,
  FinancialAccountActivityTableComponent,
  FinancialAccountBalancesComponent,
  NoteFormItemComponent,
  AttentionModalComponent,
  ErrorModalComponent,
  CollapsibleRowComponent,
  FormSelectFieldComponent,
  ConfirmModalComponent,
  SuccessModalComponent,
  HistoryItemComponent,
  DetailsItemComponent,
  DetailsNotesComponent,
  GlobalLoaderComponent,
  LoadingBarComponent,
  PasswordStrengthComponent,
  TextWithPlaceholderComponent,
  BusinessAccountFinancialAccountListComponent,
  FinancialAccountsTransactionLimitsComponent,
  BusinessAccountTransactionLimitsComponent,
  TransactionLimitItemComponent,
  LimitEditItemFieldComponent,
  RecipientDetailsHeaderComponent,
  TeamMemberActivationModalComponent,
  TeamMemberDetailsHeaderComponent,
  TeamMemberEditFormComponent,
  BusinessAccountBalanceComponent,
  CustomerDetailsHeaderComponent,
  AddEntityWithFinancialAccountModalComponent,
  FileUploadComponent,
  SidebarComponent,
  ChildAccountsPageComponent,
  TabsComponent,
  AuthLayoutComponent,
  AuthLayoutFooterComponent,
  AuthPageComponent,
  PrivacyPolicyComponent,
  TermsConditionsComponent,
  TransactionLimitsEditFormComponent,
  SummaryInfoComponent,
  AddCustomerModalComponent,
  AddFinancialAccountComponent,
  FilterDateComponent,
  FilterMenuComponent,
  FilterRadioGroupComponent,
  FilterCustomDateSelectorComponent,
  FilterCustomDateRangeSelectorComponent,
  BreadcrumbsComponent,
  FilterInputComponent,
  FilterAmountComponent,
  FilterChecklistComponent,
  AddIntegratedFinancialAccountComponent,
  AddIntegratedFinancialAccountAgreementsComponent,
  AddFinancialAccountReviewComponent,
  DetailsPageHeaderPlaceholderComponent,
  DetailsItemPlaceholderComponent,
  LinxRequestMoveMoneyHowSelectionComponent,
  LinxRequestBusinessFinancialAccountSelectionComponent,
  LinxRequestDayOrMonthInputControlComponent,
  LinxRequestDebitAuthorizationComponent,
  LinxRequestDocumentAcceptanceComponent,
  LinxRequestMoveMoneyComponent,
  LinxRequestFinancialAccountComponent,
  LinxRequestEnhanceProfileComponent,
  AddCustomerReviewComponent,
  BeneficiaryEditFormComponent,
  BeneficiaryReviewModalComponent,
  BeneficiaryListComponent,
  BeneficiaryAddComponent,
  BeneficiaryDetailsComponent,
  FinancialAccountStatementsComponent,
  DocumentDetailsPageComponent,
  DocumentFilePreviewModalComponent,
  FinancialAccountStatementDetailsComponent,
  DocumentTypeSelectionComponent,
  DocumentCreateFormComponent,
  IntegratedCardAccountPageComponent,
  SubmitIdentityVerificationComponent,
  CardTransactionListComponent,
  RestrictionListComponent,
  DisputeListComponent,
  AlertListComponent,
  StatementListComponent,
  MerchantListComponent,
  RestrictionDetailsComponent,
  AlertDetailsComponent,
  DisputeDetailsComponent,
  StatementEmailModalComponent,
  MerchantDetailsComponent,
  CardPagesBreadcrumbsComponent,
  CardAccountDetailsComponent,
  DocumentListComponent,
  IdentityVerificationListComponent,
  DisputeCreateModalComponent,
  FinancialAccountAcceptedDocumentsComponent,
  AcceptedDocumentFilePreviewModalComponent,
  RestrictionCreateModalComponent,
  AlertCreateModalComponent,
  IdentityVerificationSummaryComponent,
  CardReissueModalComponent,
  FeatureManagementModalComponent,
  AddressAddComponent,
  AddressViewComponent,
  ExpandableRowComponent,
  AddressEditComponent,
  AddressSelectModalComponent,
  PaginationComponent,
  TooltipComponent,
  IdentityVerificationErrorModalComponent,
  ExternalFinancialAccountActivityTableComponent,
  TransactionActivityByFaIdsComponent,
  AccountHolderTransactionActivityComponent,
  CurrencySelectComponent,
  UploadDocumentLinkComponent,
  BusinessAccountListComponent,
  LinxConfirmModalComponent,
  AttachmentsListComponent,
  AttachmentCreateFormComponent,
  NaturalLanguageSearchModalComponent,
  ViewAttachmentModalComponent,
  CreateEntityAttachmentComponent,
  MultipleFileUploadComponent,
  WatchlistEventsListComponent,
  DetailsAccountVerificationComponent,
  LinxTemplateFinancialAccountComponent,
  NoteItemAttachmentsComponent,
  AccountVerificationHistoryComponent,
  ViewPdfDocumentComponent,
  ReportListItemComponent,
  NotesFeedComponent,
  NotesFeedItemComponent,
  ExceptionHistoryModalComponent,
  LinxRequestEnhanceBeneficiaryComponent,
  FileDownloadSelectComponent,
  NotesFeedAddNoteComponent,
  AssigneeFilterComponent,
  LinxIdentityVerificationComponent,
  AssigneeSelectComponent,
  StatusSelectComponent,
  DebitAuthorizationListComponent,
  SupersetDashboardComponent,
  BeneficiaryDetailsHeaderComponent,
  DocumentDetailsHeaderComponent,
  DebitAuthorizationSelectComponent,
} from '../components';
import {
  BusinessFinancialAccountSelectionComponent,
  EntityFinancialAccountSelectionComponent,
  MoveAmountControlComponent,
  MoveReasonSelectionComponent,
  MoveMoneyFinancialAccountSelectionComponent,
  MoveNoteControlComponent,
  MoveMoneyAttentionModalComponent,
  MoveMoneyConfirmChangesModalComponent,
  MoveHowAchDetailsComponent,
  MoveHowSolutionComponent,
  MoveHowDirectionComponent,
  MoveWireSelectionComponent,
  MoveHowWireDetailsComponent,
  MoveAccountAddressFormComponent,
  MoveHowSelectionComponent,
  MoveMoneySchedulerRecurrenceModalComponent,
} from '../components/move-money';

import {
  CreateTransactionAmountComponent,
  CreateTransactionAmountOptionsComponent,
  CreateTransactionFinancialAccountComponent,
  CreateTransactionScreenComponent,
  FinancialAccountSelectionModalComponent,
  MultiLegTransactionListComponent,
  MethodSelectionComponent,
  PaymentReasonSelectionComponent,
  CreateTransactionAchDetailsComponent,
  CreateTransactionWireDetailsComponent,
  CreateTransactionProcessingPriorityComponent,
  CreateTransactionSchedulerComponent,
  CreateTransactionNoteComponent,
  CreateTransactionReviewModalComponent,
  EditTransactionSchedulerComponent,
  CreateTransactionDeliverySpeedComponent,
} from '../components/move-money-mlt';

import {
  PhonePipe,
  NotAvailablePipe,
  PrefixPipe,
  SolutionNamePipe,
  DateFormatPipe,
  AbsNumberPipe,
  UuidFormatPipe,
  CurrencySignPipe,
  AmountPipe,
  SolutionIconPipe,
  TeamMemberNamePipe,
  AccessControlPipe,
  StatusHistoryPipe,
} from '../pipes';

import {
  BodyHighlightDirective,
  FieldDisableDirective,
  FocusRemoveDirective,
  PercentageFieldDirective,
  TemplateContextDirective,
  DragDropAttachmentDirective,
  TooltipDirective,
  TooltipComponent as TooltipComponentDirective,
} from '../directives';
import { TransactionHistoryComponent } from '../../modules/transaction/components';

const directives = [
  FocusRemoveDirective,
  FieldDisableDirective,
  TemplateContextDirective,
  BodyHighlightDirective,
  PercentageFieldDirective,
  DragDropAttachmentDirective,
  TooltipDirective,
];
const pipes = [
  PhonePipe,
  NotAvailablePipe,
  PrefixPipe,
  SolutionNamePipe,
  DateFormatPipe,
  AbsNumberPipe,
  UuidFormatPipe,
  CurrencySignPipe,
  AmountPipe,
  SolutionIconPipe,
  TeamMemberNamePipe,
  AccessControlPipe,
  StatusHistoryPipe,
];

const moveMoneyComponents = [
  BusinessFinancialAccountSelectionComponent,
  EntityFinancialAccountSelectionComponent,
  MoveAmountControlComponent,
  MoveReasonSelectionComponent,
  MoveMoneyFinancialAccountSelectionComponent,
  MoveNoteControlComponent,
  MoveMoneyAttentionModalComponent,
  MoveMoneyConfirmChangesModalComponent,
  MoveHowAchDetailsComponent,
  MoveHowSolutionComponent,
  MoveHowDirectionComponent,
  MoveWireSelectionComponent,
  MoveHowWireDetailsComponent,
  MoveAccountAddressFormComponent,
  MoveHowSelectionComponent,
  MoveMoneySchedulerRecurrenceModalComponent,
];

const moveMoneyMltComponents = [
  CreateTransactionScreenComponent,
  CreateTransactionAmountComponent,
  MultiLegTransactionListComponent,
  CreateTransactionAmountOptionsComponent,
  CreateTransactionFinancialAccountComponent,
  FinancialAccountSelectionModalComponent,
  MethodSelectionComponent,
  PaymentReasonSelectionComponent,
  CreateTransactionAchDetailsComponent,
  CreateTransactionWireDetailsComponent,
  CreateTransactionProcessingPriorityComponent,
  CreateTransactionSchedulerComponent,
  CreateTransactionNoteComponent,
  CreateTransactionReviewModalComponent,
  EditTransactionSchedulerComponent,
  AccountHolderTransactionActivityComponent,
  CreateTransactionDeliverySpeedComponent,
];

const linxComponents = [
  LinxRequestMoveMoneyHowSelectionComponent,
  LinxRequestBusinessFinancialAccountSelectionComponent,
  LinxRequestDayOrMonthInputControlComponent,
  LinxRequestDebitAuthorizationComponent,
  LinxRequestDocumentAcceptanceComponent,
  LinxRequestMoveMoneyComponent,
  LinxRequestFinancialAccountComponent,
  LinxRequestEnhanceProfileComponent,
  LinxTemplateFinancialAccountComponent,
  LinxConfirmModalComponent,
  LinxRequestEnhanceBeneficiaryComponent,
  LinxIdentityVerificationComponent,
];

export const SHARED_COMPONENTS = [
  ...directives,
  ...pipes,
  ...moveMoneyComponents,
  ...moveMoneyMltComponents,
  ...linxComponents,
  HeaderComponent,
  LoadingBarComponent,
  GlobalLoaderComponent,
  UserHeaderInfoComponent,
  PasswordStrengthComponent,
  PageLeaveConfirmationModalComponent,
  DetailsItemComponent,
  TextWithPlaceholderComponent,
  ConfirmModalComponent,
  SuccessModalComponent,
  DetailsNotesComponent,
  NotesModalComponent,
  NotesFeedComponent,
  NoteItemComponent,
  AddNoteComponent,
  FinancialAccountActionsComponent,
  FinancialAccountDetailsComponent,
  FinancialAccountPageComponent,
  TransactionLimitsComponent,
  FinancialAccountActivityTableComponent,
  FinancialAccountBalancesComponent,
  NoteFormItemComponent,
  AttentionModalComponent,
  ErrorModalComponent,
  CollapsibleRowComponent,
  HistoryItemComponent,
  BusinessAccountFinancialAccountListComponent,
  BusinessAccountTransactionLimitsComponent,
  FinancialAccountsTransactionLimitsComponent,
  TransactionLimitItemComponent,
  LimitEditItemFieldComponent,
  RecipientDetailsHeaderComponent,
  TeamMemberActivationModalComponent,
  TeamMemberDetailsHeaderComponent,
  TeamMemberEditFormComponent,
  BusinessAccountBalanceComponent,
  CustomerDetailsHeaderComponent,
  AddEntityWithFinancialAccountModalComponent,
  FileUploadComponent,
  SidebarComponent,
  FormSelectFieldComponent,
  ChildAccountsPageComponent,
  TabsComponent,
  AuthLayoutComponent,
  AuthLayoutFooterComponent,
  AuthPageComponent,
  PrivacyPolicyComponent,
  TermsConditionsComponent,
  TransactionLimitsEditFormComponent,
  SummaryInfoComponent,
  AddFinancialAccountComponent,
  FilterMenuComponent,
  FilterRadioGroupComponent,
  FilterDateComponent,
  FilterCustomDateSelectorComponent,
  FilterCustomDateRangeSelectorComponent,
  BreadcrumbsComponent,
  FilterInputComponent,
  FilterAmountComponent,
  FilterChecklistComponent,
  AddIntegratedFinancialAccountComponent,
  AddIntegratedFinancialAccountAgreementsComponent,
  AddFinancialAccountReviewComponent,
  DetailsPageHeaderPlaceholderComponent,
  DetailsItemPlaceholderComponent,
  AddCustomerModalComponent,
  AddCustomerReviewComponent,
  BeneficiaryEditFormComponent,
  BeneficiaryReviewModalComponent,
  BeneficiaryListComponent,
  BeneficiaryAddComponent,
  BeneficiaryDetailsComponent,
  FinancialAccountStatementsComponent,
  DocumentDetailsPageComponent,
  DocumentFilePreviewModalComponent,
  FinancialAccountStatementDetailsComponent,
  DocumentTypeSelectionComponent,
  DocumentCreateFormComponent,
  IntegratedCardAccountPageComponent,
  SubmitIdentityVerificationComponent,
  CardTransactionListComponent,
  RestrictionListComponent,
  AlertListComponent,
  DisputeListComponent,
  StatementListComponent,
  StatementEmailModalComponent,
  MerchantListComponent,
  RestrictionDetailsComponent,
  AlertDetailsComponent,
  DisputeDetailsComponent,
  MerchantDetailsComponent,
  CardPagesBreadcrumbsComponent,
  CardAccountDetailsComponent,
  DocumentListComponent,
  IdentityVerificationListComponent,
  IdentityVerificationErrorModalComponent,
  DisputeCreateModalComponent,
  FinancialAccountAcceptedDocumentsComponent,
  AcceptedDocumentFilePreviewModalComponent,
  RestrictionCreateModalComponent,
  AlertCreateModalComponent,
  IdentityVerificationSummaryComponent,
  CardReissueModalComponent,
  FeatureManagementModalComponent,
  AddressAddComponent,
  AddressViewComponent,
  ExpandableRowComponent,
  AddressEditComponent,
  AddressSelectModalComponent,
  PaginationComponent,
  TooltipComponent,
  ExternalFinancialAccountActivityTableComponent,
  TransactionActivityByFaIdsComponent,
  AccountHolderTransactionActivityComponent,
  CurrencySelectComponent,
  UploadDocumentLinkComponent,
  BusinessAccountListComponent,
  AttachmentsListComponent,
  AttachmentCreateFormComponent,
  ViewAttachmentModalComponent,
  CreateEntityAttachmentComponent,
  NaturalLanguageSearchModalComponent,
  MultipleFileUploadComponent,
  TooltipComponentDirective,
  NoteItemAttachmentsComponent,
  WatchlistEventsListComponent,
  WatchlistEventsListComponent,
  DetailsAccountVerificationComponent,
  AccountVerificationHistoryComponent,
  ViewPdfDocumentComponent,
  ReportListItemComponent,
  NotesFeedItemComponent,
  ExceptionHistoryModalComponent,
  FileDownloadSelectComponent,
  NotesFeedAddNoteComponent,
  AssigneeFilterComponent,
  AssigneeSelectComponent,
  StatusSelectComponent,
  DebitAuthorizationListComponent,
  DebitAuthorizationSelectComponent,
  SupersetDashboardComponent,
  BeneficiaryDetailsHeaderComponent,
  DocumentDetailsHeaderComponent,
  TransactionHistoryComponent,
];

@NgModule({
  imports: [CommonModule, RouterModule, PortalSharedLibsModule],
  declarations: [...SHARED_COMPONENTS],
  exports: [...SHARED_COMPONENTS, PortalSharedLibsModule],
})
export class PortalSharedComponentsModule {}
