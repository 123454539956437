import { Component, OnInit } from '@angular/core';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';
import { MESSAGE } from '@shared/constants';

@Component({
  selector: 'app-move-money-error-modal',
  styleUrls: ['./move-money-error-modal.component.scss'],
  templateUrl: './move-money-error-modal.component.html',
})
export class MoveMoneyErrorModalComponent implements OnInit {
  errorType?: string | number;

  modalTitle!: string;

  listOfExceededLimits?: string;

  get isLimitError(): boolean {
    return typeof this.errorType === 'string' && this.errorType.includes('insufficient-limit');
  }

  get errorMessage(): string {
    this.listOfExceededLimits = '';

    if (this.errorType === 'non-sufficient-funds') {
      return MESSAGE.MOVE_MONEY_INSUFFICIENT_BALANCE;
    }

    if (this.isLimitError) {
      this.listOfExceededLimits =
        (this.errorType as string)
          .split('__')[1]
          ?.split('::')
          .map((limit) => {
            const [accountType, limitType] = limit.split(':');
            if (!limitType) return accountType.charAt(0).toUpperCase() + accountType.slice(1);
            return `${accountType === 'child_ba' ? 'Business' : 'Financial'} account ${limitType} limit`;
          })
          .join('\n') || '';
      return MESSAGE.MOVE_MONEY_INSUFFICIENT_LIMIT;
    }

    return MESSAGE.MOVE_MONEY_UNKNOWN_ERROR;
  }

  constructor(public activeModal: ActiveModal) {}

  ngOnInit() {
    this.modalTitle = this.errorType === 403 ? MESSAGE.PERMISSION_DENIED_TITLE : 'Error';
  }
}
