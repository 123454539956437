import { HttpHeaders } from '@angular/common/http';
import {
  AchStandardEntryClassCode,
  BankAccount,
  BusinessAccount,
  CustomerDetailRaw,
  FinancialAccountCategory,
  FinancialAccountDetailsRaw,
  FinancialAccountDetailsWithBalancesRaw,
  FinancialAccountSubtype,
  PagingProps,
  PagingResponse,
  RecipientRaw,
  RequestPageParams,
  TransactionMetadata,
  TransactionSettlementPriority,
  TransactionType,
} from '@shared/models';
import { CurrencyCode } from '@shared/utils';

export interface MultiLegTransactionFinancialAccountRaw {
  name?: string;
  subtype: 'CHECKING';
  type: 'BANK';
  bankAccount?: {
    bankName: string;
    nameOnAccount: string;
  };
  card?: {
    firstName: string;
    lastName: string;
  };
}

export interface MultiLegTransactionListItemLegDetailsRaw {
  sequence: 0;
  financialAccountId: string;
  maskedAccountNumber: string;
  amount: number;
  transactionId: string;
  latestStatus: {
    status: string;
  };
  solution: MultiLegSolutionConfigSolutionName;
  financialAccount: MultiLegTransactionFinancialAccountRaw;
  _embedded: {
    financialAccount: MultiLegTransactionFinancialAccountRaw;
  };
  /* UI handled */
  accountInfo?: string;
  solutionIcon?: string;
  solutionName?: string;
  isProcessed?: boolean;
}

export interface MultiLegTransactionListItemRaw {
  id: string;
  stage: string;
  status: string;
  currency: string;
  totalAmount: number;
  debitLegs: MultiLegTransactionListItemLegDetailsRaw[];
  creditLegs: MultiLegTransactionListItemLegDetailsRaw[];
  /* UI handled */
  debitLegsProcessed: number;
  creditLegsProcessed: number;
}

export interface MultiLegTransactionListRaw extends PagingProps {
  content: MultiLegTransactionListItemRaw[];
}

export interface MultiLegTransactionListItem extends MultiLegTransactionListItemRaw {
  // NOT IN THE API
  // moveFrom?: string;
  // moveTo?: string;
  // moveFromDetails?: string;
  // moveToDetails?: string;
  // createdAt?: string;
  // solutionIcon?: string;
  // solutionName?: string;
}

export interface MultiLegTransactionList extends PagingProps {
  items: MultiLegTransactionListItem[];
}

export interface MultiLegTransactionDetailsResponse {
  body: MultiLegTransactionDetailsRaw;
  headers: HttpHeaders;
}

export interface MultiLegTransactionDetailsRaw {
  id: string;
  businessAccountId: string;
  stage: string;
  status: string;
  currency: CurrencyCode;
  totalAmount: number;
  debits: MultiLegTransactionLegDetailsRaw[];
  debitAmountPending: number;
  debitAmountCleared: number;
  credits: MultiLegTransactionLegDetailsRaw[];
  creditAmountPending: number;
  creditAmountSettled: number;
  name: string;
  description: string;
  memo: string;
  createdBy: {
    id: string;
    type: string;
  };
  createdAt: string;
  updatedBy: {
    id: string;
    type: string;
  };
  updatedAt: string;
  paymentReasons: MultiLegPaymentReason[];
  metadata?: {
    rkorACHEntryType?: AchStandardEntryClassCode;
    rkorACHIndividualId?: string;
    rkorACHCheckSerialNumber?: string;
    rkorACHTerminalCity?: string;
    rkorACHTerminalState?: string;
  };
  debitAuthorizationId?: string;

  /* ETag header */
  transactionETag?: string;
  deliverySpeedOptions?: DeliverySpeedOption[];
}

export interface MultiLegTransactionLegDetailsRaw {
  financialAccountId: string;
  amount: number;
  paymentReasonId: string;
  latestStatus?: {
    status: string;
  };
  solution: MultiLegSolutionConfigSolutionName;
  maskedAccountNumber: string;
  transactionId?: string;
  paymentReason?: string;
  settlementPriority?: TransactionSettlementPriority;
  metadata?: TransactionMetadata;
  financialAccount: MultiLegTransactionFinancialAccount;
}

export interface MultiLegTransactionFinancialAccount extends FinancialAccountDetailsRaw {
  /* UI handled */
  accountInfo: string;
  address?: {
    addressLine1: string;
    addressLine2?: string;
    city: string;
    country: string;
    postalCode: string;
    state: string;
  };
  accountHolderInfo: string;
  recipient?: RecipientRaw;
  customer?: CustomerDetailRaw;
  businessAccount?: BusinessAccount;
}

export interface MultiLegTransactionLegDetails extends MultiLegTransactionLegDetailsRaw {
  transactionId?: string;
  /* UI handled */
  paymentReason?: string;
  processingPriority?: string;
  processingPriorityIcon?: string;
  financialAccount: MultiLegTransactionFinancialAccount;
}
export interface MultiLegTransactionDetails extends MultiLegTransactionDetailsRaw {
  /* UI handled */
  debits: MultiLegTransactionLegDetails[];
  credits: MultiLegTransactionLegDetails[];
  processedTransactions?: number;
  totalTransactions?: number;
  metadataItems: Record<string, string | undefined>[];
  estLabel?: string;
  estLabelDate?: string;
  estLabelDay?: string;
  estLabelTime?: string;
}

export interface FinancialAccountSelectionListItemRaw {
  id: string;
  accountHolderId: string;
  accountHolderType: 'CUSTOMER' | 'RECIPIENT' | 'BUSINESS_ACCOUNT';
  state: 'ACTIVE' | 'SUSPENDED';
  financialInstitutionName: string;
  currency: string;
  maskedAccountNumber: string;
  subtype: FinancialAccountSubtype;
  category: FinancialAccountCategory;
  name?: string;
  businessAccountId: string;
  parentId?: string;
  bankAccount?: Partial<BankAccount>;
  /* UI handled */
  financialAccountDetails?: FinancialAccountDetailsWithBalancesRaw;
  recipient?: RecipientRaw;
  customer?: CustomerDetailRaw;
  businessAccount?: BusinessAccount;
  itemError?: string;
  isSelectionDisabled?: boolean;
}

export interface FinancialAccountSelectionListItem {
  id: string;
  state: string;
  currency: string;
  subtype: FinancialAccountSubtype;
  category: FinancialAccountCategory;
  parentId?: string;
  bankAccount?: Partial<BankAccount>;
  accountHolderId?: string;
  accountHolderType?: FinancialAccountSelectionListItemRaw['accountHolderType'];
  businessAccountId?: string;
  /* UI handled */
  accountHolderInfo: string;
  accountCategory: FinancialAccountCategory;
  accountInfo: string;
  accountBalance?: number;
  availableBalance?: number;
  phone?: string;
  email?: string;
  isDisabled?: boolean;
  recipientAccountHolderId?: string;
}

export interface FinancialAccountSelectionListRequest extends RequestPageParams {
  categories?: FinancialAccountCategory[];
  type?: 'from' | 'to';
  financialAccountType?: 'BANK' | 'CARD';
  currency?: CurrencyCode | null;
  recipientAccountHolderId?: string;
}

export interface FinancialAccountSelectionListRaw extends PagingResponse<FinancialAccountSelectionListItemRaw[]> {}

export interface FinancialAccountSelectionList extends PagingProps {
  readonly items: FinancialAccountSelectionListItem[];
}

export interface MultiLegTransactionAchDetailsModel {
  rkorACHEntryType?: AchStandardEntryClassCode;
  rkorACHIndividualId?: string;
  rkorACHCheckSerialNumber?: string;
  rkorACHTerminalCity?: string;
  rkorACHTerminalState?: string;
  isDisabled?: boolean;
  bypassDebitAuthorization?: boolean;
  debitAuthorizationId?: string;
}

export interface MultiLegTransactionWireDetailsModel {
  description?: string;
  memo?: string;
}

export type MultiLegTransactionProcessingPriority = 'SAME_DAY' | 'NEXT_DAY' | 'IMMEDIATE';

export interface MultiLegTransactionScheduler {
  scheduleDateTime?: string;
  scheduleDateTimeDisplayValue?: string;
  scheduleRecurringDisplayValue?: string;
  recurrenceRule?: string;
  timeZone?: string;
  name?: string;
  alreadySentCount?: number;
  isError?: boolean;
}

export type MultiLegPaymentReasonType = 'PULL' | 'PUSH';

export interface MultiLegPaymentReasonRaw {
  id: string;
  reason: string;
  allowedSolutions: MultiLegSolutionConfigSolutionName[];
  state: 'ACTIVE' | string;
  types?: MultiLegPaymentReasonType[];
}

export interface MultiLegPaymentReason {
  label: string;
  value: string;
  allowedSolutions: MultiLegSolutionConfigSolutionName[];
  types: MultiLegPaymentReasonType[];
}

export type MultiLegSolutionConfigTransactionType = 'SEND' | 'REQUEST' | 'REVERSAL' | 'RETURN' | 'ADJUSTMENT';

export type MultiLegSolutionConfigSolutionName = 'wire' | 'transfer' | 'ach' | 'push-to-card';

export type MultiLegSolutionConfigPriority = 'SAME_DAY' | 'NEXT_DAY' | 'IMMEDIATE';

export type MultiLegSolutionConfigAllowedAccountType =
  | 'INTERNAL'
  | 'INTEGRATED'
  | 'EXTERNAL:CHECKING'
  | 'EXTERNAL:SAVING'
  | 'EXTERNAL:DEBIT';

export interface MultiLegSolutionConfig {
  solutionName: MultiLegSolutionConfigSolutionName;
  solutionConfig: {
    transactionMinAmount: number;
    transactionMaxAmount: number;
    allowedCurrencies: string[];
    transactionWindows: {
      priority: MultiLegSolutionConfigPriority;
      weekendsAllowed: boolean;
      holidaysAllowed: boolean;
      timeZone: string;
      startTime: string;
      endTime: string;
    }[];
    transactionTypes: Record<
      MultiLegSolutionConfigTransactionType,
      {
        allowedAccountTypes: {
          debit: MultiLegSolutionConfigAllowedAccountType[];
          credit: MultiLegSolutionConfigAllowedAccountType[];
        };
      }
    >;
  };
}

export interface MultiLegEnabledSolutionVerifiedPermissionRaw {
  accessGranted: boolean;
  actionName: string;
  metadata: { solutionName: MultiLegSolutionConfigSolutionName };
}

export interface MultiLegEnabledSolutionPermissionRaw {
  solutionName: MultiLegSolutionConfigSolutionName;
  verifiedPermissions: MultiLegEnabledSolutionVerifiedPermissionRaw[];
}

export interface MultiLegEnabledSolutionPermissionsRaw {
  enabledSolutions: MultiLegEnabledSolutionPermissionRaw[];
}

export type MultiLegEnabledSolutionPermissions = Record<MultiLegSolutionConfigSolutionName, boolean>;

export const cardAccountSubtypes = ['CREDIT', 'DEBIT', 'GIFT', 'PREPAID'];

export const bankAccountSubtypes = ['CHECKING', 'SAVING'];

export interface CreateTransactionSltData {
  amount?: string;
  debitBalancePercent?: string;
  debitFinancialAccountId: string;
  creditFinancialAccountId: string;
  paymentReasonId: string;
  solution: MultiLegSolutionConfigSolutionName;
  transactionType: TransactionType;
  /* optional */
  currency?: string;
  status?: string;
  settlementPriority?: MultiLegTransactionProcessingPriority;
  description?: string;
  memo?: string;
  metadata?: {
    rkorACHEntryType?: AchStandardEntryClassCode;
    rkorACHIndividualId?: string;
    rkorACHCheckSerialNumber?: string;
    rkorACHTerminalCity?: string;
    rkorACHTerminalState?: string;
  };
  bypassDebitAuthorization?: boolean;
  debitAuthorizationId?: string;
}

export interface CreateTransactionMltData {
  currency?: string;
  debits: {
    financialAccountId: string;
    amount?: string;
    amountPercentage?: string;
    settlementPriority: string;
    solution: string;
    paymentReasonId: string;
    bypassDebitAuthorization?: boolean;
    debitAuthorizationId?: string;
  }[];
  credits: {
    financialAccountId: string;
    solution: string;
    paymentReasonId: string;
    amount: string;
    amountPercentage?: string;
    settlementPriority?: string;
  }[];
  metadata: TransactionMetadata;
  description?: string;
  memo?: string;
}

export interface UpdateScheduledTransactionMltData {
  updateMultiLegSpec: CreateTransactionMltData;
  startDateTime: string;
  timeZone: string;
  /* optional */
  recurrenceRule?: string;
  name?: string;
}

export interface UpdateTransactionMltData {
  currency: string;
  debits?: { financialAccountId: string; amount?: string; amountPercentage?: string }[];
  credits: { financialAccountId: string; solution: string; paymentReasonId: string; amount: string; amountPercentage?: string }[];
  metadata: TransactionMetadata;
  description?: string;
  memo?: string;
}

export interface CreateTransactionSchedulerData {
  startDateTime: string;
  /* optional */
  recurrenceRule?: string;
  name?: string;
}

export interface MltTransactionDetailsDebitCreditLegRaw {
  sequence: number;
  financialAccountId: string;
  maskedAccountNumber: string;
  amount: number;
  paymentReasonId: string;
  latestStatus: {
    status?: string;
  };
  solution: MultiLegSolutionConfigSolutionName;
}

export interface MltTransactionDetailsRaw {
  id: string;
  businessAccountId: string;
  stage: string;
  status: string;
  currency: string;
  totalAmount: number;
  debits: MltTransactionDetailsDebitCreditLegRaw[];
  debitAmountPending: number;
  debitAmountCleared: number;
  credits: MltTransactionDetailsDebitCreditLegRaw[];
  creditAmountPending: number;
  creditAmountSettled: number;
  metadata: TransactionMetadata;
  createdBy: {
    id: string;
    type: string;
  };
  createdAt: string;
  updatedBy: {
    id: string;
    type: string;
  };
  updatedAt: string;
  version: number;
}

export interface DeliverySpeedOption {
  /* UI handled */
  label: string;
  value: string;
  estDate: string;
  estLabelTime: string;
  estLabelDate: string;
  estLabelDay: string;
  fromAccountMethod: MultiLegSolutionConfigSolutionName | null;
  fromAccountProcessingPriority: MultiLegTransactionProcessingPriority | null;
  fromAccountPaymentReason?: string;
  toAccountMethod: MultiLegSolutionConfigSolutionName | null;
  toAccountProcessingPriority: MultiLegTransactionProcessingPriority | null;
}

export interface SettlementPriority {
  estimatedFundsDeliveryDateTime: string;
  estimatedFundsClearanceDateTime?: string;
}

export type FundingSolutionPriorityUsed = {
  [key in MultiLegSolutionConfigSolutionName]: {
    [priorityKey in MultiLegTransactionProcessingPriority]: SettlementPriority;
  };
};

export interface SupportedSettlementPriorityDetails {
  fundingSolutionPriorityUsed: FundingSolutionPriorityUsed;
}

export type SupportedSettlementPriorities = {
  [key in MultiLegTransactionProcessingPriority]: SupportedSettlementPriorityDetails;
};

export interface DisbursingMethodDetails {
  supportedSettlementPriorities: SupportedSettlementPriorities;
}

export type DisbursingFromEscrow = {
  [key in MultiLegSolutionConfigSolutionName]: DisbursingMethodDetails;
};

export type SolutionsDeliverySpeedOptionsResponse = {
  [key in MultiLegSolutionConfigSolutionName]: {
    supportedSettlementPriorities: {
      [priorityKey in MultiLegTransactionProcessingPriority]: SettlementPriority;
    };
  };
};

export interface DeliverySpeedOptionsResponse {
  transactionType: string;
  disbursingFromEscrow?: DisbursingFromEscrow;
  fundingEscrow?: FundingEscrow;
  solutions?: SolutionsDeliverySpeedOptionsResponse;
}

export type FundingEscrow = {
  [key in MultiLegSolutionConfigSolutionName]: {
    supportedSettlementPriorities: SupportedSettlementPriorities;
  };
};
