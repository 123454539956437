<div class="details-page-header-wrapper" *ngIf="transactionBatch$ | async as transactionBatch; else loadingPlaceholder">
  <div class="page-header">
    <div class="details-page-back-title">
      <a [routerLink]="[pageReturnURL]" class="details-page-back-btn">
        <rkt-icon name="arrow-left" class="details-page-back-btn-icon"></rkt-icon>
      </a>
      <h1 class="details-page-title">{{ transactionBatch?.id || '' | rktShortId }}</h1>

      <div
        class="status-tag"
        [class.no-value]="!transactionBatch?.status"
        [ngClass]="'status-tag-' + transactionBatch?.status?.toLowerCase()"
      >
        {{ transactionBatch?.status | titlecase }}
      </div>
    </div>
    <app-breadcrumbs [itemList]="breadcrumbList"></app-breadcrumbs>
  </div>

  <div class="page-header-controls">
    <div class="entity-actions">
      <button
        rktButton
        class="cancel-batch-btn"
        [disabled]="transactionBatch?.status !== 'PENDING'"
        (click)="onBatchCancelClick()"
        title="Cancel Batch"
      >
        <rkt-icon name="times"></rkt-icon>
        Cancel Batch
      </button>
    </div>
  </div>
</div>

<ng-template #loadingPlaceholder>
  <app-details-page-header-placeholder [pageReturnURL]="pageReturnURL"></app-details-page-header-placeholder>
</ng-template>

<app-tabs [tabItems]="tabItems"></app-tabs>

<router-outlet></router-outlet>
