import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { constants } from '@shared/constants';
import { CustomHttpParamEncoder } from '@shared/encoder';
import {
  AdaListRequestParams,
  AdaRegistration,
  AdaRegistrationEditParams,
  AdaRegistrationHistoryList,
  AdaRegistrationHistoryListRaw,
  AdaRegistrationHistoryRequest,
  AdaRegistrationList,
  AdaRegistrationListRaw,
  AdaRegistrationRaw,
  BusinessAccount,
  FilterValues,
} from '@shared/models';
import { BehaviorSubject, catchError, map, Observable, tap, throwError } from 'rxjs';
import { BackendService } from '../backend.service';
import { environment } from '@env';
import { AuditActions } from '@shared/store';
import { compact } from 'lodash-es';
import { Store } from '@ngrx/store';
import { switchMap } from 'rxjs/operators';
import { BusinessAccountService, NotificationService, StorageService } from '@shared/services';
import { getMaskedAccountNumber, getSubtypeDisplayName, toTitleCase } from '@shared/utils';
import { isArray } from 'lodash/index';

@Injectable({
  providedIn: 'root',
})
export class AdaRegistrationService {
  adaRegistrationListFilter = new BehaviorSubject<FilterValues>({});

  constructor(
    private backendService: BackendService,
    private storageService: StorageService,
    private notificationService: NotificationService,
    private store: Store,
    private businessAccountService: BusinessAccountService,
  ) {}

  private getSelectedBusinessAccountId(): Observable<BusinessAccount['id']> {
    return this.businessAccountService.getSelectedBusinessAccountId();
  }

  public getAdaRegistrations({
    page,
    size,
    searchString,
    sortParams,
    activeFilters,
    accountHolderIds,
  }: AdaListRequestParams): Observable<AdaRegistrationList> {
    const pageSize = size ?? constants.TABLE_ROWS;
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });

    params = params.append('size', pageSize);

    if (page) {
      params = params.append('page', page);
    }

    if (searchString) {
      params = params.append('search', searchString);
    }

    if (sortParams) {
      params = params.append('sort', `${sortParams.key},${sortParams.sortDir}`);
    }

    if (accountHolderIds) {
      params = params.append('accountHolderIds', isArray(accountHolderIds) ? accountHolderIds.join(',') : accountHolderIds);
    }

    if (activeFilters) {
      Object.keys(activeFilters).forEach((key) => {
        params = params.append(key, activeFilters[key]!);
      });
    }

    return this.backendService.get<AdaRegistrationListRaw>(`${environment.financialAccountFlow}/v1/debit-authorizations`, { params }).pipe(
      map((response) => {
        const { content = [], totalElements = 0 } = response || {};
        return {
          totalElements,
          items: content.map((registration) => this.mapAdaRegistration(registration)),
        };
      }),
      catchError((errorRes) => throwError(() => errorRes)),
    );
  }

  public getAdaRegistrationById(adaRegistrationId: string): Observable<AdaRegistrationRaw> {
    return this.backendService
      .get<AdaRegistrationRaw>(`${environment.financialAccountFlow}/v1/debit-authorizations/${adaRegistrationId}`)
      .pipe(
        tap({
          next: (response) => {
            const { createdBy, updatedBy } = response;
            this.store.dispatch(AuditActions.loadTeamMembers({ ids: compact([createdBy, updatedBy]) }));
          },
        }),
        switchMap((adaRegistrationDetails) => {
          return this.getAdaRegistrationHistory({ page: 0, size: 200, adaRegistrationId }).pipe(
            map((registrationHistoryData) => {
              return { ...adaRegistrationDetails, registrationHistoryData };
            }),
          );
        }),
        map((adaRegistrationDetails) => {
          return this.mapAdaRegistration(adaRegistrationDetails);
        }),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  public getAdaRegistrationHistory({
    page,
    size,
    adaRegistrationId,
  }: AdaRegistrationHistoryRequest): Observable<AdaRegistrationHistoryList> {
    return this.getSelectedBusinessAccountId().pipe(
      switchMap((businessAccountId) => {
        const pageSize = size ?? constants.TABLE_ROWS;

        const params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
          .set('page', `${page ?? 0}`)
          .set('size', `${pageSize}`)
          .set('businessAccountId', businessAccountId);

        return this.backendService
          .get<AdaRegistrationHistoryListRaw>(
            `${environment.financialAccountFlow}/v1/debit-authorizations/${adaRegistrationId}/histories`,
            {
              params,
            },
          )
          .pipe(
            map((response) => this.mapAdaRegistrationHistoryItems(response)),
            catchError((errorRes) => throwError(() => errorRes)),
          );
      }),
    );
  }

  private mapAdaRegistration(adaRegistration: AdaRegistrationRaw): AdaRegistration {
    const bankName = adaRegistration.financialAccountDetails?.bankAccount?.bankName;
    const bankAccount = compact([
      getMaskedAccountNumber(adaRegistration.financialAccountDetails.maskedAccountNumber),
      getSubtypeDisplayName(adaRegistration.financialAccountDetails?.subtype),
    ]).join(' | ');

    return {
      ...adaRegistration,
      customerName: adaRegistration.accountHolderDetails?.fullName,
      shortId: adaRegistration.id.split('-')[0],
      financialAccountDisplay: `${toTitleCase(adaRegistration.financialAccountDetails?.name) ?? ''}::${compact([
        bankName,
        bankAccount,
      ]).join(' ')}`,
      accountHolderId: adaRegistration.accountHolderDetails?.id,
      accountHolderShortId: adaRegistration.accountHolderDetails?.id.split('-')[0],
      financialAccountShortId: adaRegistration.financialAccountId.split('-')[0],
      authenticationData: adaRegistration.authenticationData
        ? {
            ...adaRegistration.authenticationData,
            currentLocation: `${adaRegistration.authenticationData.geoLocation.latitude}, ${adaRegistration.authenticationData.geoLocation.longitude}`,
          }
        : undefined,
    };
  }

  private mapAdaRegistrationHistoryItems(adaRegistrationHistoryItems: AdaRegistrationHistoryListRaw): AdaRegistrationHistoryList {
    const { content = [], totalElements = 0 } = adaRegistrationHistoryItems || {};

    const items = content?.map((adaHistoryItem) => {
      return {
        ...adaHistoryItem,
        historyItemStatus:
          adaHistoryItem?.changes?.find((item) => item.property === 'status')?.newValue?.toLowerCase() ??
          adaHistoryItem?.description?.split(' ')[0]?.toLowerCase(),
      };
    });

    return {
      items,
      totalElements,
    };
  }

  public setAdaRegistrationFilterParams(filters: FilterValues): void {
    this.storageService.setItem(constants.KOR_ADA_REGISTRATION_FILTER, filters);
    this.updateAdaRegistrationFilterParams(filters);
  }

  public getAdaRegistrationFilterParams(): void {
    const filters = this.storageService.getItem<FilterValues>(constants.KOR_ADA_REGISTRATION_FILTER);

    if (filters && Object.keys(filters).length) {
      this.updateAdaRegistrationFilterParams(filters);
    }
  }

  private updateAdaRegistrationFilterParams(filters: FilterValues): void {
    this.adaRegistrationListFilter.next(filters);
  }

  updateAdaRegistrationStatus(action: string, adaRegistrationId: string): Observable<AdaRegistration> {
    return this.backendService
      .update<AdaRegistrationRaw>(`${environment.financialAccountFlow}/v1/debit-authorizations/${adaRegistrationId}/${action}`)
      .pipe(
        tap({
          next: (response) => {
            const { createdBy, updatedBy } = response;
            this.store.dispatch(AuditActions.loadTeamMembers({ ids: compact([createdBy, updatedBy]) }));
          },
        }),
        switchMap((adaRegistrationDetails) => {
          return this.getAdaRegistrationHistory({ page: 0, size: 200, adaRegistrationId }).pipe(
            map((registrationHistoryData) => {
              return { ...adaRegistrationDetails, registrationHistoryData };
            }),
          );
        }),
        map((adaRegistrationDetails) => {
          return this.mapAdaRegistration(adaRegistrationDetails);
        }),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }

  updateAdaRegistration(editParams: AdaRegistrationEditParams, adaRegistrationId: string): Observable<AdaRegistration> {
    return this.backendService
      .update<AdaRegistrationRaw>(`${environment.financialAccountFlow}/v1/debit-authorizations/${adaRegistrationId}`, editParams)
      .pipe(
        tap({
          next: (response) => {
            const { createdBy, updatedBy } = response;
            this.store.dispatch(AuditActions.loadTeamMembers({ ids: compact([createdBy, updatedBy]) }));
          },
        }),
        switchMap((adaRegistrationDetails) => {
          return this.getAdaRegistrationHistory({ page: 0, size: 200, adaRegistrationId }).pipe(
            map((registrationHistoryData) => {
              return { ...adaRegistrationDetails, registrationHistoryData };
            }),
          );
        }),
        map((adaRegistrationDetails) => {
          this.notificationService.displaySuccess(`Debit authorization updated successfully.`, 'Success');
          return this.mapAdaRegistration(adaRegistrationDetails);
        }),
        catchError((errorRes) => throwError(() => errorRes)),
      );
  }
}
