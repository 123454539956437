<div class="details-page-header-wrapper">
  <div class="page-header">
    <div class="details-page-back-title">
      <a [routerLink]="[pageReturnURL]" class="details-page-back-btn">
        <rkt-icon name="arrow-left" class="details-page-back-btn-icon"></rkt-icon>
      </a>
      <h1 class="details-page-title">Create Batch</h1>
    </div>
    <app-breadcrumbs [itemList]="breadcrumbList"></app-breadcrumbs>
  </div>
</div>

<div class="batch-create-form-container">
  <div class="section-title">Upload file</div>
  <div class="section upload-file-section">
    <div class="section-left">
      <div class="file-drop">
        <app-file-upload (selectedFile)="onFileUploaded($event)"></app-file-upload>
      </div>
      <div class="file-drop-text">Download and fill out the <a href="">batch template</a> file, then upload the completed file above.</div>
    </div>
    <div class="section-right">
      <div class="notes-section">
        <app-note-form-item [errors]="note.errors" [value]="note.value">
          <textarea
            class="note-field"
            #noteField
            (blur)="onNoteFieldBlur()"
            name="new-note"
            id="new-note"
            [formControl]="note"
            maxlength="1000"
          ></textarea>
        </app-note-form-item>
      </div>
    </div>
  </div>
  <div class="buttons-block">
    <button rktButton btnType="link" title="Cancel" (click)="onDiscardBtnClick()">Cancel</button>
    <button rktButton title="Submit" (click)="onSubmitBtnClick()" [disabled]="isSubmitDisabled">Submit</button>
  </div>
</div>
