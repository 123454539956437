<ng-container *ngIf="transactionData$ | async as transaction; else loadingPlaceholder">
  <div class="details-page-header-wrapper dark-bg" *ngIf="transactionData$ | async as transaction; else loadingPlaceholder">
    <div class="page-header">
      <div class="details-page-back-title">
        <a [routerLink]="[isFromExceptions ? pageReturnURLTwoLevels : pageReturnURL]" class="details-page-back-btn">
          <rkt-icon name="arrow-left" class="details-page-back-btn-icon"></rkt-icon>
        </a>
        <h1 class="details-page-title">{{ transaction.transactionDetails.id | rktShortId }}</h1>

        <div class="status-tag" [ngClass]="'status-tag-' + transaction.transactionDetails.latestStatus.status.toLowerCase()">
          {{ transaction.transactionDetails.latestStatus.status | titlecase }}
        </div>
      </div>
      <app-breadcrumbs [itemList]="breadcrumbList"></app-breadcrumbs>
    </div>

    <div class="page-header-controls">
      <app-transaction-detail-actions [transaction]="transaction.transactionDetails" [hasNotes]="hasNotes"></app-transaction-detail-actions>
    </div>
  </div>

  <div class="transaction-details-page">
    <div class="attachments-hover-overlay"></div>
    <div class="transaction-details">
      <div
        class="entity-details-container"
        appDragDropAttachment
        [entityId]="transaction.transactionDetails.id"
        [entityType]="'TRANSACTION'"
        [attachmentsList]="attachmentsList"
      >
        <div class="attachments-hover-overlay"></div>
        <ng-container
          *ngTemplateOutlet="transactionDetails; context: { $implicit: transaction.transactionDetails, loading: false }"
        ></ng-container>

        <ng-container *ngIf="transactionData$ | async as transaction">
          <div class="separator-row"></div>

          <app-attachments-list
            #attachmentsList
            [entityId]="transaction.transactionDetails.id"
            [entityType]="'TRANSACTION'"
          ></app-attachments-list>

          <app-details-notes
            [entityType]="'TRANSACTION'"
            [entityId]="transaction.transactionDetails.id"
            (hasNotesStateChanged)="hasNotes = $event"
          ></app-details-notes>
        </ng-container>
      </div>
    </div>

    <div class="history-column">
      <ng-container
        *ngTemplateOutlet="transactionHistory; context: { $implicit: transaction.transactionHistory, loading: false }"
      ></ng-container>
    </div>
  </div>
</ng-container>

<ng-template #loadingPlaceholder>
  <app-details-page-header-placeholder
    [isDarkBg]="true"
    [pageReturnURL]="isFromExceptions ? pageReturnURLTwoLevels : pageReturnURL"
  ></app-details-page-header-placeholder>

  <div class="transaction-details-page">
    <div class="transaction-details">
      <div class="entity-details-container">
        <ng-container *ngTemplateOutlet="transactionDetails; context: { $implicit: null, loading: true }"></ng-container>
      </div>
    </div>

    <div class="history-column">
      <ng-container *ngTemplateOutlet="transactionHistory; context: { $implicit: null, loading: true }"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #transactionHistory [appTemplateContext]="transactionHistoryContext" let-transactionHistory let-loading="loading">
  <app-transaction-history [transactionHistoryItems]="transactionHistory" [loading]="loading"></app-transaction-history>
</ng-template>

<ng-template #transactionDetails [appTemplateContext]="transactionDetailsContext" let-transaction let-loading="loading">
  <div class="details-section-title">
    <div class="title">Transaction Details</div>
  </div>

  <app-details-item
    [class]="pageClassPrefix + 'id'"
    [showPlaceholder]="loading"
    label="Transaction ID"
    [value]="transaction?.id || '' | rktShortId"
  ></app-details-item>

  <div class="separator-row"></div>

  <div class="amount-row">
    <div class="amount-label">
      <app-text-with-placeholder [showPlaceholder]="loading">Amount</app-text-with-placeholder>
    </div>
    <div class="amount-value">
      <app-text-with-placeholder [showPlaceholder]="loading">
        {{ transaction?.amount | amount: transaction?.currency }} {{ transaction?.currency }}
      </app-text-with-placeholder>
    </div>
  </div>

  <div class="from-to-row">
    <div class="from-to-item">
      <div class="title">
        <app-text-with-placeholder [showPlaceholder]="loading">From Account</app-text-with-placeholder>
      </div>

      <ng-container *ngIf="loading; else debitLegDataLoaded">
        <ng-container *ngTemplateOutlet="fromToAccount; context: { $implicit: undefined, transaction, loading }"></ng-container>
      </ng-container>

      <ng-template #debitLegDataLoaded>
        <ng-container
          *ngTemplateOutlet="fromToAccount; context: { $implicit: transaction?.debitFinancialAccount, transaction, loading }"
        ></ng-container>
      </ng-template>
    </div>

    <div class="from-to-item">
      <div class="title">
        <app-text-with-placeholder [showPlaceholder]="loading">To Account</app-text-with-placeholder>
      </div>

      <ng-container *ngIf="loading; else creditLegDataLoaded">
        <ng-container *ngTemplateOutlet="fromToAccount; context: { $implicit: undefined, transaction, loading }"></ng-container>
      </ng-container>

      <ng-template #creditLegDataLoaded>
        <ng-container
          *ngTemplateOutlet="fromToAccount; context: { $implicit: transaction?.creditFinancialAccount, transaction, loading }"
        ></ng-container>
      </ng-template>
    </div>
  </div>

  <div class="estimate-details" *ngIf="transaction?.estLabel !== null">
    <div class="estimate-details-title">
      {{ transaction?.estLabel }}
    </div>
    <div class="estimate-details-sections">
      <div class="estimate-details-inner">
        <div class="details-item">
          <div class="details-label">Payment Reason:</div>
          <div class="details-value">{{ transaction?.paymentReason | notAvailable }}</div>
        </div>

        <ng-container *ngIf="transaction?.solution === 'wire'">
          <div class="details-item">
            <div class="details-label">Narrative:</div>
            <div class="details-value">{{ transaction?.description }}</div>
          </div>

          <div class="details-item">
            <div class="details-label">Memo:</div>
            <div class="details-value" [class.empty-value]="!transaction?.memo">
              {{ transaction?.memo | notAvailable }}
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="transaction?.solution === 'ach'">
          <div class="details-item" *ngIf="transaction?.isAch && transaction?.transactionType !== 'RETURN'">
            <div class="details-label">SEC Code:</div>
            <div class="details-value">
              {{ transaction.metadata?.rkorACHEntryType || 'CCD' }}
            </div>
          </div>

          <div class="details-item" *ngIf="transaction.metadata?.rkorACHIndividualId">
            <div class="details-label">Individual ID:</div>
            <div class="details-value">{{ transaction.metadata?.rkorACHIndividualId }}</div>
          </div>

          <div class="details-item" *ngIf="transaction.metadata?.rkorACHCheckSerialNumber">
            <div class="details-label">Check Serial Number:</div>
            <div class="details-value">{{ transaction.metadata?.rkorACHCheckSerialNumber }}</div>
          </div>

          <div class="details-item" *ngIf="transaction.metadata?.rkorACHTerminalCity">
            <div class="details-label">Terminal City:</div>
            <div class="details-value">{{ transaction.metadata?.rkorACHTerminalCity }}</div>
          </div>

          <div class="details-item" *ngIf="transaction.metadata?.rkorACHTerminalState">
            <div class="details-label">Terminal State:</div>
            <div class="details-value">{{ transaction.metadata?.rkorACHTerminalState }}</div>
          </div>
        </ng-container>
      </div>
      <div class="estimate-details-inner">
        <div class="details-item">
          <div class="details-label">Funds Delivery Estimate:</div>
          <div class="details-value">
            {{ transaction?.estLabelDay }} – {{ transaction?.estLabelDate }}
            <div class="details-value-estimation">
              We estimate the funds will settle at the receiving institution in the
              <span class="strong">{{ transaction?.estLabelTime }}</span> of
              <span class="strong">{{ transaction?.estLabelDate }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="additional-details">
    <app-details-item
      class="transaction-type"
      [showPlaceholder]="loading"
      label="Transaction Type"
      [value]="transaction?.transactionType"
    ></app-details-item>

    <app-details-item [showPlaceholder]="loading" label="Status Message" [value]="transaction?.latestStatus?.message"></app-details-item>

    <app-details-item
      [showPlaceholder]="loading"
      label="Initiator Account ID"
      [value]="transaction?.initiatorAccountHolder"
    ></app-details-item>

    <app-details-item
      [showPlaceholder]="loading"
      label="External Transaction Initiator ID"
      [value]="transaction?.externalTransactionInitiatorId"
    ></app-details-item>

    <app-details-item
      [showPlaceholder]="loading"
      label="Debit Authorization ID"
      [value]="transaction?.debitAuthorizationId || '' | rktShortId"
    ></app-details-item>

    <app-details-item [showPlaceholder]="false" label="Schedule ID" *ngIf="transaction?.scheduledTransactionId" [contentValue]="true">
      <a
        class="page-link schedule-details-link"
        [routerLink]="['/app/transactions/scheduled-transactions', transaction.scheduledTransactionId]"
        [title]="transaction.scheduledTransactionId"
        >{{ transaction.scheduledTransactionId || '' | rktShortId }}</a
      >
    </app-details-item>

    <app-details-item
      [showPlaceholder]="loading"
      label="Originating Channel"
      [value]="transaction?.originatingChannel"
      [noBreak]="true"
    ></app-details-item>
  </div>

  <div
    class="additional-details additional-details-with-border"
    *ngIf="transaction?.isPushToCard || (transaction?.isAch && transaction?.transactionType === 'RETURN')"
  >
    <ng-container *ngIf="transaction?.isPushToCard">
      <app-details-item
        [showPlaceholder]="loading"
        label="Statement Descriptor"
        [value]="transaction?.statementDescriptor"
      ></app-details-item>

      <app-details-item [showPlaceholder]="loading" label="Solution Payment Type" [value]="transaction?.paymentType"></app-details-item>

      <app-details-item [showPlaceholder]="loading" label="Merchant Category Code" [value]="transaction?.mcc"></app-details-item>

      <app-details-item [showPlaceholder]="loading" label="Card Acceptor ID" [value]="transaction?.cardAcceptorId"></app-details-item>

      <app-details-item
        [showPlaceholder]="loading"
        label="Solution Disbursement Transaction ID"
        [value]="transaction?.disbursementId"
      ></app-details-item>

      <app-details-item [showPlaceholder]="loading" label="Network" [value]="transaction?.network"></app-details-item>

      <app-details-item [showPlaceholder]="loading" label="Network Status Code" [value]="transaction?.networkStatusCode"></app-details-item>

      <app-details-item
        [showPlaceholder]="loading"
        label="Network Status Description"
        [value]="transaction?.networkStatusDescription"
      ></app-details-item>

      <app-details-item
        [showPlaceholder]="loading"
        label="Funds Availability"
        [value]="transaction?.fundsAvailability | titlecase"
      ></app-details-item>
    </ng-container>

    <ng-container *ngIf="transaction?.isAch && transaction?.transactionType === 'RETURN'">
      <app-details-item [showPlaceholder]="loading" label="Return Code" [value]="transaction?.metadata?.rkorReasonCode"></app-details-item>

      <app-details-item
        [showPlaceholder]="loading"
        label="Return Reason"
        [value]="transaction?.metadata?.rkorReasonDescription"
      ></app-details-item>

      <app-details-item
        [showPlaceholder]="false"
        label="Parent Transaction ID"
        *ngIf="transaction?.parentTransactionId"
        [contentValue]="true"
      >
        <a [routerLink]="['/', 'app', 'transactions', 'transactions', transaction.parentTransactionId]">{{
          transaction.parentTransactionId || '' | rktShortId
        }}</a>
      </app-details-item>
    </ng-container>
  </div>

  <ng-container *ngIf="!loading">
    <div class="transaction-metadata">
      <div class="transaction-metadata-title" (click)="isShowMetadata = !isShowMetadata">
        <div class="title">Metadata</div>
        <rkt-icon [name]="isShowMetadata ? 'chevron-down' : 'chevron-right'"></rkt-icon>
      </div>

      <div class="transaction-metadata-items" *ngIf="isShowMetadata">
        <div class="transaction-metadata-item transaction-metadata-header-item">
          <div class="key">Key</div>

          <div class="value">Value</div>
        </div>
        <div class="transaction-metadata-item" *ngFor="let metadataItem of transaction.metadataItems">
          <div class="key">{{ metadataItem.key }}</div>

          <div class="value">
            {{ metadataItem.value }}
          </div>
        </div>
        <div class="transaction-metadata-empty-item" *ngIf="!transaction.metadataItems.length">No Transaction Metadata</div>
      </div>
    </div>
  </ng-container>

  <div class="create-update-details">
    <app-details-item [showPlaceholder]="loading" label="Created At" [value]="transaction?.createdAt | dateFormat"></app-details-item>

    <app-details-item
      [showPlaceholder]="loading"
      label="Created By"
      [value]="transaction?.createdBy?.id | teamMemberName | async"
      [noBreak]="true"
    ></app-details-item>

    <app-details-item [showPlaceholder]="loading" label="Updated At" [value]="transaction?.updatedAt | dateFormat"></app-details-item>

    <app-details-item
      [showPlaceholder]="loading"
      label="Updated By"
      [value]="transaction?.updatedBy?.id | teamMemberName | async"
      [noBreak]="true"
    ></app-details-item>
  </div>
</ng-template>

<ng-template #fromToAccount let-financialAccount let-transaction="transaction" let-loading="loading">
  <div class="item-body">
    <div class="item-body-row" [class.empty-value]="!financialAccount?.accountHolderInfo">
      <div class="label">
        <app-text-with-placeholder class="placeholder" [showPlaceholder]="loading" [darkBg]="true"
          >Account Holder</app-text-with-placeholder
        >
      </div>
      <div class="value two-row-value">
        <div
          class="page-link"
          [class.empty-value]="!(financialAccount?.accountHolderInfo || '' | rktSplit: '::':0)"
          (click)="onAccountHolderClick(financialAccount, transaction.id)"
        >
          <app-text-with-placeholder [showPlaceholder]="loading" [darkBg]="true">
            {{ financialAccount?.accountHolderInfo || '' | rktSplit: '::':0 | notAvailable }}
          </app-text-with-placeholder>
        </div>
        <div [class.empty-value]="!(financialAccount?.accountHolderInfo || '' | rktSplit: '::':1)">
          <app-text-with-placeholder [showPlaceholder]="loading" [darkBg]="true">
            {{ financialAccount?.accountHolderInfo || '' | rktSplit: '::':1 | notAvailable }}
          </app-text-with-placeholder>
        </div>
      </div>
    </div>

    <ng-container
      *ngTemplateOutlet="
        itemBodyRows;
        context: {
          $implicit: [
            { value: financialAccount?.accountInfo, label: 'Account Info', splitable: true },
            { value: financialAccount?.type, label: 'Account Type' },
            { value: financialAccount?.category, label: 'Account Category' },
            {
              value: financialAccount?.address,
              label: 'Address',
              className:
                (!!financialAccount?.bankAccount || !!financialAccount?.token ? 'item-body-row-with-separator ' : '') +
                'item-body-row-tall',
              type: 'address'
            }
          ],
          loading
        }
      "
    ></ng-container>

    <div class="item-body-row" *ngIf="financialAccount?.bankAccount">
      <div class="label">
        <app-text-with-placeholder class="placeholder" [showPlaceholder]="loading" [darkBg]="true">Routing No.</app-text-with-placeholder>
      </div>
      <div class="value">
        <app-text-with-placeholder class="placeholder" [showPlaceholder]="loading" [darkBg]="true">
          {{ financialAccount?.bankAccount?.routingNo | notAvailable }}
        </app-text-with-placeholder>
      </div>
    </div>

    <ng-container *ngIf="transaction?.debitFinancialAccountId === financialAccount?.id">
      <div class="item-body-row" *ngIf="transaction?.isMillikartSolution && financialAccount?.token">
        <div class="label">
          <app-text-with-placeholder [showPlaceholder]="loading" [darkBg]="true">Token</app-text-with-placeholder>
        </div>
        <div class="value">
          <app-text-with-placeholder [showPlaceholder]="loading" [darkBg]="true">
            <a
              (click)="onAccountHolderClick(financialAccount, transaction.id)"
              class="page-link"
              [title]="financialAccount.token | notAvailable"
            >
              {{ financialAccount?.token }}
            </a>
          </app-text-with-placeholder>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="transaction?.creditFinancialAccountId === financialAccount?.id">
      <div class="item-body-row" *ngIf="transaction?.isMillikartSolution && financialAccount?.token">
        <div class="label">
          <app-text-with-placeholder [showPlaceholder]="loading" [darkBg]="true">Token</app-text-with-placeholder>
        </div>
        <div class="value">
          <app-text-with-placeholder [showPlaceholder]="loading" [darkBg]="true">
            <a
              (click)="onAccountHolderClick(financialAccount, transaction.id)"
              class="page-link"
              [title]="financialAccount.token | notAvailable"
            >
              {{ financialAccount?.token }}
            </a>
          </app-text-with-placeholder>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #itemBodyRows let-loading="loading" let-rows>
  <div class="item-body-row" [class.empty-value]="!row.value" [ngClass]="row.className" *ngFor="let row of rows">
    <div class="label" *ngIf="row.label">
      <app-text-with-placeholder class="placeholder" [showPlaceholder]="loading" [darkBg]="true">{{ row.label }}</app-text-with-placeholder>
    </div>
    <div class="value" *ngIf="row.type !== 'address' || (row.type === 'address' && !row.value)">
      <ng-container *ngIf="row.splitable">
        <div [class.empty-value]="!(row.value || '' | rktSplit: '::':0)">
          <app-text-with-placeholder [showPlaceholder]="loading" [darkBg]="true">
            {{ row.value || '' | rktSplit: '::':0 | notAvailable }}
          </app-text-with-placeholder>
        </div>
        <div [class.empty-value]="!(row.value || '' | rktSplit: '::':1)">
          <app-text-with-placeholder [showPlaceholder]="loading" [darkBg]="true">
            {{ row.value || '' | rktSplit: '::':1 | notAvailable }}
          </app-text-with-placeholder>
        </div>
      </ng-container>

      <app-text-with-placeholder *ngIf="!row.splitable" class="placeholder" [showPlaceholder]="loading" [darkBg]="true">
        {{ row.value | notAvailable }}
      </app-text-with-placeholder>
    </div>

    <div class="value" *ngIf="row.type === 'address' && row.value">
      <app-text-with-placeholder class="placeholder" [showPlaceholder]="loading" [darkBg]="true">
        {{ row.value?.addressLine1 }},<br />
        <ng-container *ngIf="row.value?.addressLine2">{{ row.value?.addressLine2 }}<br /></ng-container>
        {{ row.value?.city }}, {{ row.value?.state }}<br />
        {{ row.value?.postalCode }}
        {{ row.value?.country }}
      </app-text-with-placeholder>
    </div>
  </div>
</ng-template>
