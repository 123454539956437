import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { compact } from 'lodash-es';
import { ActiveModal } from '@rocketfinancialcorp/rocket-ui/modal';
import { ErrorUtils, getFormattedAmount } from '@shared/utils';
import {
  Customer,
  EditTransactionModalParams,
  FinancialAccountDetails,
  FinancialAccountListItem,
  PaymentReason,
  Recipient,
  TransactionDetails,
} from '@shared/models';
import {
  achEntryTypeCodes,
  CustomerService,
  mapCustomerFinancialAccountWithEntity,
  mapRecipientFinancialAccountWithEntity,
  RecipientService,
} from '@shared/services';
import { AbstractMoveMoneyBase, MoveMoneyWrapperService } from '@shared/components';
import { fromTransaction } from '@shared/store';

/* deprecated */
@Component({
  selector: 'app-edit-transaction-modal',
  templateUrl: 'edit-transaction-modal.component.html',
  styleUrls: ['edit-transaction-modal.component.scss'],
  providers: [
    {
      provide: AbstractMoveMoneyBase,
      useClass: MoveMoneyWrapperService,
    },
  ],
})
export class EditTransactionModalComponent implements AfterViewChecked, OnDestroy {
  transaction?: TransactionDetails;

  debitFinancialAccount?: FinancialAccountDetails;

  creditFinancialAccount?: FinancialAccountDetails;

  initialCreditFinancialAccount?: FinancialAccountDetails;

  initialDebitFinancialAccount?: FinancialAccountDetails;

  debitAllowedAccountTypes$ = this.store.select(fromTransaction.selectTransactionTypes('debit'));

  creditAllowedAccountTypes$ = this.store.select(fromTransaction.selectTransactionTypes('credit'));

  private destroy$ = new Subject<void>();

  get achEntryTypeDisplayName(): string {
    const { moveAchEntryType } = this.moveMoneyService.moveHowAchDetails ?? {};

    return achEntryTypeCodes.find((item) => item.value === moveAchEntryType)?.label ?? '';
  }

  get achDetailsFieldsCount(): number {
    const { rkorACHIndividualId, rkorACHCheckSerialNumber, rkorACHTerminalCity, rkorACHTerminalState } =
      this.moveMoneyService.moveHowAchDetails || {};

    return compact([rkorACHIndividualId, rkorACHCheckSerialNumber, rkorACHTerminalCity, rkorACHTerminalState]).length;
  }

  get moveAchEntryType(): string {
    return this.moveMoneyService.moveHowAchDetails?.moveAchEntryType ?? '';
  }

  constructor(
    public moveMoneyService: AbstractMoveMoneyBase,
    public activeModal: ActiveModal,
    private ref: ChangeDetectorRef,
    private recipientService: RecipientService,
    private customerService: CustomerService,
    private store: Store,
  ) {}

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  modalInitData(data: EditTransactionModalParams): void {
    const { transaction, creditFinancialAccount, debitFinancialAccount } = data;

    this.transaction = transaction;
    this.creditFinancialAccount = creditFinancialAccount;
    this.debitFinancialAccount = debitFinancialAccount;

    this.moveMoneyService.setTransactionData(this.transaction);
    this.setFinancialAccounts();
  }

  setFinancialAccounts(): void {
    if (!this.creditFinancialAccount || !this.debitFinancialAccount) {
      return;
    }

    this.getDebitFinancialAccountData();
    this.getCreditFinancialAccountData();
  }

  getDebitFinancialAccountData() {
    const { accountHolderId, accountHolderType } = this.debitFinancialAccount!;

    if (accountHolderId && accountHolderType === 'RECIPIENT') {
      this.recipientService
        .getRecipientById(accountHolderId)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (recipient) => {
            this.mapDebitFinancialAccountData({ recipient });
          },
          error: (error) => ErrorUtils.catchError('recipientService.getRecipientById', error),
        });
    } else if (accountHolderId && accountHolderType === 'CUSTOMER') {
      this.customerService
        .getCustomerById(accountHolderId)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (customer) => {
            this.mapDebitFinancialAccountData({ customer });
          },
          error: (error) => ErrorUtils.catchError('customerService.getCustomerById', error),
        });
    } else {
      this.mapDebitFinancialAccountData({});
    }
  }

  getCreditFinancialAccountData() {
    const { accountHolderId, accountHolderType } = this.creditFinancialAccount!;

    if (accountHolderId && accountHolderType === 'RECIPIENT') {
      this.recipientService
        .getRecipientById(accountHolderId)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (recipient) => {
            this.mapCreditFinancialAccountData({ recipient });
          },
          error: (error) => ErrorUtils.catchError('recipientService.getRecipientById', error),
        });
    } else if (accountHolderId && accountHolderType === 'CUSTOMER') {
      this.customerService
        .getCustomerById(accountHolderId)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (customer) => {
            this.mapCreditFinancialAccountData({ customer });
          },
          error: (error) => ErrorUtils.catchError('customerService.getCustomerById', error),
        });
    } else {
      this.mapCreditFinancialAccountData({});
    }
  }

  mapDebitFinancialAccountData({ recipient, customer }: { recipient?: Recipient; customer?: Customer }) {
    const isDebitCustomerOrRecipient =
      this.debitFinancialAccount!.accountHolderType && ['RECIPIENT', 'CUSTOMER'].includes(this.debitFinancialAccount!.accountHolderType);

    let debitFinancialAccount;

    if (isDebitCustomerOrRecipient && this.debitFinancialAccount!.accountHolderType === 'RECIPIENT') {
      debitFinancialAccount = mapRecipientFinancialAccountWithEntity({
        ...this.debitFinancialAccount!,
        accountHolderType: this.debitFinancialAccount?.accountHolderType!,
        recipient,
      });
    } else if (isDebitCustomerOrRecipient && this.debitFinancialAccount!.accountHolderType === 'CUSTOMER') {
      debitFinancialAccount = mapCustomerFinancialAccountWithEntity({
        ...this.debitFinancialAccount!,
        accountHolderType: this.debitFinancialAccount?.accountHolderType!,
        customer,
      });
    }

    if (!debitFinancialAccount) {
      debitFinancialAccount = this.moveMoneyService.mapFinancialAccountData(this.debitFinancialAccount!);
    }

    this.initialDebitFinancialAccount = debitFinancialAccount as FinancialAccountDetails;
    this.moveMoneyService.selectedDebitFinancialAccount = debitFinancialAccount;
  }

  mapCreditFinancialAccountData({ recipient, customer }: { recipient?: Recipient; customer?: Customer }) {
    const isCreditCustomerOrRecipient =
      this.creditFinancialAccount!.accountHolderType && ['RECIPIENT', 'CUSTOMER'].includes(this.creditFinancialAccount!.accountHolderType);

    let creditFinancialAccount;

    if (isCreditCustomerOrRecipient && this.creditFinancialAccount!.accountHolderType === 'RECIPIENT') {
      creditFinancialAccount = mapRecipientFinancialAccountWithEntity({
        ...this.creditFinancialAccount!,
        accountHolderType: this.creditFinancialAccount?.accountHolderType!,
        recipient,
      });
    } else if (isCreditCustomerOrRecipient && this.creditFinancialAccount!.accountHolderType === 'CUSTOMER') {
      creditFinancialAccount = mapCustomerFinancialAccountWithEntity({
        ...this.creditFinancialAccount!,
        accountHolderType: this.creditFinancialAccount?.accountHolderType!,
        customer,
      });
    }

    if (!creditFinancialAccount) {
      creditFinancialAccount = this.moveMoneyService.mapFinancialAccountData(this.creditFinancialAccount!);
    }

    this.initialCreditFinancialAccount = creditFinancialAccount as FinancialAccountDetails;
    this.moveMoneyService.selectedCreditFinancialAccount = creditFinancialAccount;
  }

  onDebitFinancialAccountSelect(financialAccount: FinancialAccountListItem): void {
    this.moveMoneyService.selectedDebitFinancialAccount = financialAccount;
    this.moveMoneyService.expandedRow = undefined;
  }

  onCreditFinancialAccountSelect(financialAccount: FinancialAccountListItem): void {
    this.moveMoneyService.selectedCreditFinancialAccount = financialAccount;
    this.moveMoneyService.expandedRow = undefined;
  }

  isMoveReasonDisabled(): boolean {
    const initialMoveReasonId = this.transaction?.paymentReasonId;
    const selectedMoveReasonId = this.moveMoneyService.paymentReason?.id;

    if (!initialMoveReasonId || !selectedMoveReasonId) {
      return true;
    }

    return initialMoveReasonId === selectedMoveReasonId;
  }

  isMoveFromToDisabled(): boolean {
    return (
      this.moveMoneyService.selectedCreditFinancialAccount?.id === this.initialCreditFinancialAccount?.id &&
      this.moveMoneyService.selectedDebitFinancialAccount?.id === this.initialDebitFinancialAccount?.id
    );
  }

  isAmountSubmitDisabled(): boolean {
    const initialAmount = getFormattedAmount(parseFloat(`${this.transaction?.amount}`)).formattedValue;
    const moveAmount = getFormattedAmount(parseFloat(`${this.moveMoneyService.moveAmount}`)).formattedValue;
    return (
      !!this.moveMoneyService.moveAmountError ||
      !this.moveMoneyService.moveAmount ||
      !this.moveMoneyService.paymentReason?.id ||
      this.moveMoneyService.moveAmount === initialAmount ||
      moveAmount === initialAmount
    );
  }

  isSubmitDisabled(): boolean {
    return this.isMoveReasonDisabled() && this.isMoveFromToDisabled() && this.isAmountSubmitDisabled();
  }

  getMoveMoneyData() {
    const { moveDirection, moveHowType } = this.moveMoneyService.moveHow;
    const { reason } = this.moveMoneyService.paymentReason ?? {};
    return {
      selectedMoveItem: moveDirection,
      selectedMoveHowType: moveHowType,
      paymentReasonName: reason,
      moveAmountValue: this.moveMoneyService.moveAmount,
      selectedMoveTo: this.moveMoneyService.selectedCreditFinancialAccount,
      selectedMoveFrom: this.moveMoneyService.selectedDebitFinancialAccount,
      note: this.moveMoneyService.note,
      achDetails: this.moveMoneyService.moveHowAchDetails,
      moveHowDirectionDisplayValue: this.moveMoneyService.moveHowDirectionDisplayValue,
      moveHowSolutionDisplayValue: this.moveMoneyService.moveHowSolutionDisplayValue,
      moveHowSolutionIcon: this.moveMoneyService.moveHowSolutionIcon,
      achEntryTypeDisplayName: this.achEntryTypeDisplayName,
      moveAchEntryType: this.moveAchEntryType,
      moveHowAchDetails: this.moveMoneyService.moveHowAchDetails,
    };
  }

  onEditContinueBtnClick(): void {
    this.activeModal.close({
      paymentReasonId: this.moveMoneyService.paymentReason?.id,
      amount: this.moveMoneyService.moveAmount,
      creditFinancialAccountId: this.moveMoneyService.selectedCreditFinancialAccount?.id,
      debitFinancialAccountId: this.moveMoneyService.selectedDebitFinancialAccount?.id,
      note: this.moveMoneyService.note,
      reviewData: this.getMoveMoneyData(),
    });
  }

  onPaymentReasonSelect(paymentReason: PaymentReason): void {
    this.moveMoneyService.paymentReason = paymentReason;

    if (this.moveMoneyService.moveAmount && this.moveMoneyService.paymentReason) {
      this.moveMoneyService.expandedRow = undefined;
    }
  }

  onAmountBlur(): void {
    if (this.moveMoneyService.moveAmount && this.moveMoneyService.paymentReason) {
      this.moveMoneyService.expandedRow = undefined;
    }
  }
}
