<div class="rows">
  <div class="row">
    <div class="label">
      Standard Entry Class (SEC) Code<span class="asterisk">*</span
      ><app-tooltip
        class="label-tooltip"
        tooltip="3-letter code that describes a class of ACH transaction. Each class is distinguished by characteristics such as the parties involved
      in the transaction (Corporate vs Individual), the type of authorization required for the transaction, Debit or Credit, Single vs
      Recurring and transaction limits"
      ></app-tooltip>
    </div>
    <div class="value">
      <form [formGroup]="secCodeForm">
        <rkt-form
          [form]="secCodeForm"
          [fields]="secCodeFormFields"
          [model]="achDetailsModel"
          (modelChange)="onAchDetailsChange()"
        ></rkt-form>
      </form>
    </div>
  </div>

  <div class="row" [hidden]="achDetailsModel.rkorACHEntryType ? !['CIE', 'PPD', 'TEL'].includes(achDetailsModel.rkorACHEntryType) : true">
    <div class="label">
      Individual ID<span class="asterisk">*</span><app-tooltip class="label-tooltip" tooltip="Transaction ID"></app-tooltip>
    </div>
    <div class="value">
      <form [formGroup]="individualIdForm">
        <rkt-form
          [form]="individualIdForm"
          [fields]="individualIdFormFields"
          [model]="achDetailsModel"
          (modelChange)="onAchDetailsChange()"
        ></rkt-form>
      </form>
    </div>
  </div>

  <div
    class="row"
    [hidden]="achDetailsModel.rkorACHEntryType ? !['ARC', 'BOC', 'POP', 'RCK'].includes(achDetailsModel.rkorACHEntryType) : true"
  >
    <div class="label">
      Check Serial Number<span class="asterisk">*</span
      ><app-tooltip class="label-tooltip" tooltip="Serial number that appears on a check"></app-tooltip>
    </div>
    <div class="value">
      <form [formGroup]="checkSerialNumberForm">
        <rkt-form
          [form]="checkSerialNumberForm"
          [fields]="checkSerialNumberFormFields"
          [model]="achDetailsModel"
          (modelChange)="onAchDetailsChange()"
        ></rkt-form>
      </form>
    </div>
  </div>

  <div class="row" [hidden]="achDetailsModel.rkorACHEntryType !== 'POP'">
    <div class="label">
      Terminal City<span class="asterisk">*</span
      ><app-tooltip
        class="label-tooltip"
        tooltip="Truncated name or abbreviation of the city, town, village or township in which the electronic terminal is located"
      ></app-tooltip>
    </div>
    <div class="value">
      <form [formGroup]="terminalCityForm">
        <rkt-form
          [form]="terminalCityForm"
          [fields]="terminalCityFormFields"
          [model]="achDetailsModel"
          (modelChange)="onAchDetailsChange()"
        ></rkt-form>
      </form>
    </div>
  </div>

  <div class="row" [hidden]="achDetailsModel.rkorACHEntryType !== 'POP'">
    <div class="label">
      Terminal State<span class="asterisk">*</span
      ><app-tooltip class="label-tooltip" tooltip="The state within the USA. where the electronic terminal is located"></app-tooltip>
    </div>
    <div class="value">
      <form [formGroup]="terminalStateForm">
        <rkt-form
          [form]="terminalStateForm"
          [fields]="terminalStateFormFields"
          [model]="achDetailsModel"
          (modelChange)="onAchDetailsChange()"
        ></rkt-form>
      </form>
    </div>
  </div>

  <div class="row">
    <div class="label">Debit Authorization</div>
    <div class="value">
      <app-debit-authorization-select (changed)="onDebitAuthorizationSelect($event)"></app-debit-authorization-select>
    </div>
  </div>

  <div class="row offline-ada-toggle-button-wrapper">
    <button
      class="offline-ada-toggle-button"
      [class.checked]="achDetailsModel.bypassDebitAuthorization"
      (click)="onOfflineDebitAuthorizationToggle()"
    >
      <rkt-icon [name]="achDetailsModel.bypassDebitAuthorization ? 'checkbox-on' : 'checkbox-off'"></rkt-icon>
      Offline Debit Authorization
    </button>
  </div>
</div>
