import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Observable, map } from 'rxjs';
import { formCheckboxGroup, formDatepicker, formInput, formRow, formSelect } from '@rocketfinancialcorp/rocket-ui/form';
import { I2C_CARD_SHIPPING_METHODS } from '@shared/enums';
import { CardProduct, SupportedLanguage } from '@shared/models';

const expressions = {
  'props.disabled': 'model.category === "EXTERNAL" || model.category === "INTERNAL" || model.isEdit',
};

export const integratedCardFields = ({
  products$,
  supportedLanguages$,
}: {
  products$: Observable<CardProduct[] | null>;
  supportedLanguages$: Observable<SupportedLanguage[]>;
}) => [
  formRow([
    formSelect({
      key: 'card.productId',
      label: 'Card Product',
      props: {
        options: products$.pipe(
          map((products) => {
            if (!products) return [];
            return products.map((product) => ({ label: product.name, value: product.id }));
          }),
        ),
        required: true,
        loading: products$.pipe(map((products) => products === null)),
      },
      expressions: {
        'props.disabled': 'model.category === "EXTERNAL" || model.category === "INTERNAL" || model.isEdit',
      },
    }),
  ]),
  formRow([formInput({ key: 'card.nameOnCard', label: 'Nam\u0435 on Car\u0064', expressions })]),
  formRow([
    formSelect({
      key: 'card.shippingMethod',
      label: 'Shipping Method',
      props: {
        options: Object.keys(I2C_CARD_SHIPPING_METHODS).map((key) => ({ label: I2C_CARD_SHIPPING_METHODS[key], value: key })),
        required: true,
      },
      expressions,
    }),
  ]),
  formRow([
    formSelect({
      key: 'card.issuedLanguageCode',
      label: 'Issue Language',
      props: {
        options: supportedLanguages$.pipe(
          map((languages) => languages.map((language) => ({ label: language.label, value: language.languageTag }))),
        ),
        required: false,
      },
      expressions,
    }),
  ]),
  formRow([
    formDatepicker({
      key: 'card.acceptedTermsDate',
      label: 'Terms and Conditions Acceptance Date',
      className: 'half-row-field',
      props: {
        placeholder: 'DD/MM/YYYY',
        required: true,
        maxDate: NgbDate.from({ day: 1, month: 1, year: new Date().getFullYear() + 120 }),
      },
      expressions,
    }),
  ]),
  formRow([
    formCheckboxGroup({
      key: 'card.flags',
      label: '',
      props: {
        options: [{ value: 'defaultFlag', label: 'Set as default account' }],
        required: false,
      },
      expressions,
    }),
  ]),
];
