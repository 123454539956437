import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { CardDispute, TemplateContext } from '@shared/models';
import { CardAccountActions, fromFinancialAccount } from '@shared/store';
import { NavigationService } from '@shared/services';

@Component({
  templateUrl: './dispute-details.component.html',
})
export class DisputeDetailsComponent implements OnInit, OnDestroy {
  pageClassPrefix = 'dispute-details-';

  disputeDetailsContext?: TemplateContext<CardDispute>;

  disputeDetails$ = this.store.select(fromFinancialAccount.selectCardAccountDisputeDetails);

  private destroy$ = new Subject<void>();

  constructor(private navigationService: NavigationService, private store: Store) {
    this.navigationService.dispatchOnNavigationEnd(CardAccountActions.loadDisputeDetails());
  }

  ngOnInit(): void {
    this.store.dispatch(CardAccountActions.loadCardAccountDetails());
  }

  ngOnDestroy(): void {
    this.navigationService.ngOnDestroy();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
