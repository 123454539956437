import { createFeature, createReducer, on } from '@ngrx/store';
import {
  AdaRegistrationDropdownItem,
  AttachmentCreateModel,
  DeliverySpeedOption,
  FilterValues,
  FinancialAccountSelectionListItem,
  MultiLegEnabledSolutionPermissions,
  MultiLegPaymentReason,
  MultiLegSolutionConfig,
  MultiLegSolutionConfigSolutionName,
  MultiLegTransactionAchDetailsModel,
  MultiLegTransactionProcessingPriority,
  MultiLegTransactionScheduler,
  MultiLegTransactionWireDetailsModel,
} from '@shared/models';
import { CurrencyCode } from '@shared/utils';
import { TransactionFormActions } from './transaction-form.actions';

export type TransactionFormAction = 'CREATE' | 'EDIT_MLT' | 'CANCEL_MLT' | 'EDIT_SLT' | 'EDIT_SCHEDULED_SLT' | 'EDIT_SCHEDULED_MLT';

export interface TransactionFormState {
  amount: string | null;
  amountOption: 'EXACT_AMOUNT' | 'PERCENTAGE' | 'EXACT_AMOUNT_LEFT';
  fromAccount: FinancialAccountSelectionListItem | null;
  toAccount: FinancialAccountSelectionListItem | null;
  fromAccountMethod: MultiLegSolutionConfigSolutionName | null;
  toAccountMethod: MultiLegSolutionConfigSolutionName | null;
  fromAccountPaymentReason: string | null;
  toAccountPaymentReason: string | null;
  fromAccountAchDetails: MultiLegTransactionAchDetailsModel | null;
  toAccountAchDetails: MultiLegTransactionAchDetailsModel | null;
  fromAccountWireDetails: MultiLegTransactionWireDetailsModel | null;
  toAccountWireDetails: MultiLegTransactionWireDetailsModel | null;
  fromAccountProcessingPriority: MultiLegTransactionProcessingPriority | null;
  toAccountProcessingPriority: MultiLegTransactionProcessingPriority | null;
  scheduler: MultiLegTransactionScheduler | null;
  note: string | null;
  financialAccountItems: FinancialAccountSelectionListItem[] | null;
  financialAccountItemsLoading: boolean;
  financialAccountItemsTotalElements: number;
  financialAccountsActiveFilters: FilterValues | null;
  paymentReasons: MultiLegPaymentReason[] | null;
  enabledSolutions: MultiLegEnabledSolutionPermissions;
  enabledSolutionConfigs: MultiLegSolutionConfig[] | null;
  currency: CurrencyCode | null;
  transactionType: 'SLT' | 'MLT' | null;
  unavailableProcessingPriorities: {
    achSameDay: boolean;
    wireSameDay: boolean;
    wireNextDay: boolean;
  };
  isFormLoading: boolean;
  isDebitLegsDisabled: boolean;
  formAction: TransactionFormAction;
  deliveryOption: DeliverySpeedOption | null;
  idempotencyTimeConstraint: string | null;
  attachments: Partial<AttachmentCreateModel>[] | null;
  isWireMltTransaction: boolean;
  isMltDisbursementStage: boolean;
  deliverySpeedOptions: DeliverySpeedOption[];
  debitAuthorization: AdaRegistrationDropdownItem | null;
}

export const transactionFormState: TransactionFormState = {
  amount: null,
  amountOption: 'EXACT_AMOUNT',
  fromAccount: null,
  toAccount: null,
  fromAccountMethod: null,
  toAccountMethod: null,
  fromAccountPaymentReason: null,
  toAccountPaymentReason: null,
  fromAccountAchDetails: null,
  toAccountAchDetails: null,
  fromAccountWireDetails: null,
  toAccountWireDetails: null,
  fromAccountProcessingPriority: null,
  toAccountProcessingPriority: null,
  scheduler: null,
  note: null,
  financialAccountItems: [],
  financialAccountItemsLoading: false,
  financialAccountItemsTotalElements: 0,
  financialAccountsActiveFilters: { accountHolderTypes: 'BUSINESS_ACCOUNT,CUSTOMER,RECIPIENT' },
  paymentReasons: null,
  enabledSolutions: {
    ach: false,
    'push-to-card': false,
    transfer: false,
    wire: false,
  },
  enabledSolutionConfigs: null,
  currency: 'USD',
  transactionType: null,
  unavailableProcessingPriorities: {
    achSameDay: true,
    wireSameDay: true,
    wireNextDay: true,
  },
  isFormLoading: false,
  isDebitLegsDisabled: false,
  formAction: 'CREATE',
  deliveryOption: null,
  idempotencyTimeConstraint: null,
  attachments: null,
  isWireMltTransaction: false,
  isMltDisbursementStage: false,
  deliverySpeedOptions: [],
  debitAuthorization: null,
};

const reducer = createReducer(
  transactionFormState,
  on(TransactionFormActions.setAmount, (state, { amount }): TransactionFormState => {
    return { ...state, amount };
  }),
  on(TransactionFormActions.setAmountOption, (state, { amountOption }): TransactionFormState => {
    return { ...state, amountOption, amount: null };
  }),
  on(TransactionFormActions.setMoveFrom, (state, { financialAccount }): TransactionFormState => {
    return {
      ...state,
      fromAccount: financialAccount,
      toAccount: null,
      fromAccountMethod: null,
      toAccountMethod: null,
      fromAccountPaymentReason: null,
      toAccountPaymentReason: null,
      fromAccountAchDetails: null,
      toAccountAchDetails: null,
      fromAccountWireDetails: null,
      toAccountWireDetails: null,
      fromAccountProcessingPriority: null,
      toAccountProcessingPriority: null,
      deliveryOption: null,
    };
  }),
  on(TransactionFormActions.setMoveTo, (state, { financialAccount }): TransactionFormState => {
    return {
      ...state,
      toAccount: financialAccount,
      fromAccountMethod: null,
      toAccountMethod: null,
      fromAccountPaymentReason: null,
      toAccountPaymentReason: null,
      fromAccountAchDetails: null,
      toAccountAchDetails: null,
      fromAccountWireDetails: null,
      toAccountWireDetails: null,
      fromAccountProcessingPriority: null,
      toAccountProcessingPriority: null,
      deliveryOption: null,
    };
  }),
  on(TransactionFormActions.updateMoveFrom, (state, { financialAccount }): TransactionFormState => {
    return {
      ...state,
      fromAccount: financialAccount,
      deliveryOption: null,
    };
  }),
  on(TransactionFormActions.updateMoveTo, (state, { financialAccount }): TransactionFormState => {
    return {
      ...state,
      toAccount: financialAccount,
      toAccountMethod: null,
      toAccountPaymentReason: null,
      toAccountAchDetails: state.isWireMltTransaction ? null : state.toAccountAchDetails,
      toAccountWireDetails: null,
      toAccountProcessingPriority: null,
      deliveryOption: null,
    };
  }),
  on(TransactionFormActions.setMoveFromMethod, (state, { method }): TransactionFormState => {
    return { ...state, fromAccountMethod: method };
  }),
  on(TransactionFormActions.setMoveToMethod, (state, { method }): TransactionFormState => {
    return { ...state, toAccountMethod: method };
  }),
  on(TransactionFormActions.setMoveFromPaymentReason, (state, { paymentReason }): TransactionFormState => {
    return { ...state, fromAccountPaymentReason: paymentReason };
  }),
  on(TransactionFormActions.setMoveToPaymentReason, (state, { paymentReason }): TransactionFormState => {
    return { ...state, toAccountPaymentReason: paymentReason, fromAccountPaymentReason: 'funds_transfer' };
  }),
  on(TransactionFormActions.setMoveFromAchDetails, (state, { achDetails }): TransactionFormState => {
    return { ...state, fromAccountAchDetails: achDetails };
  }),
  on(TransactionFormActions.setMoveToAchDetails, (state, { achDetails }): TransactionFormState => {
    return { ...state, toAccountAchDetails: achDetails };
  }),
  on(TransactionFormActions.setMoveFromWireDetails, (state, { wireDetails }): TransactionFormState => {
    return { ...state, fromAccountWireDetails: wireDetails };
  }),
  on(TransactionFormActions.setMoveToWireDetails, (state, { wireDetails }): TransactionFormState => {
    return { ...state, toAccountWireDetails: wireDetails };
  }),
  on(TransactionFormActions.setMoveFromProcessingPriority, (state, { processingPriority }): TransactionFormState => {
    return { ...state, fromAccountProcessingPriority: processingPriority };
  }),
  on(TransactionFormActions.setMoveToProcessingPriority, (state, { processingPriority }): TransactionFormState => {
    return { ...state, toAccountProcessingPriority: processingPriority };
  }),
  on(TransactionFormActions.setScheduler, (state, { schedulerDetails }): TransactionFormState => {
    return { ...state, scheduler: schedulerDetails };
  }),
  on(TransactionFormActions.setDebitAuthorization, (state, { debitAuthorization }): TransactionFormState => {
    return { ...state, debitAuthorization };
  }),
  on(TransactionFormActions.setNote, (state, { note }): TransactionFormState => {
    return { ...state, note };
  }),
  on(TransactionFormActions.setFinancialAccountsFilters, (state, { filters }): TransactionFormState => {
    return { ...state, financialAccountsActiveFilters: filters };
  }),
  on(TransactionFormActions.resetFinancialAccountSelection, (state): TransactionFormState => {
    return {
      ...state,
      financialAccountsActiveFilters: transactionFormState.financialAccountsActiveFilters,
      financialAccountItems: null,
      financialAccountItemsTotalElements: 0,
    };
  }),
  on(TransactionFormActions.financialAccountsReset, (state): TransactionFormState => {
    return { ...state, financialAccountItems: null, financialAccountItemsTotalElements: 0, financialAccountItemsLoading: true };
  }),
  on(TransactionFormActions.loadFinancialAccounts, (state): TransactionFormState => {
    return { ...state, financialAccountItems: null, financialAccountItemsLoading: true };
  }),
  on(TransactionFormActions.loadFinancialAccountsSuccess, (state, { financialAccountItems, totalElements }): TransactionFormState => {
    return { ...state, financialAccountItems, financialAccountItemsTotalElements: totalElements, financialAccountItemsLoading: false };
  }),
  on(TransactionFormActions.loadFinancialAccountsFailure, (state): TransactionFormState => {
    return { ...state, financialAccountItems: null, financialAccountItemsTotalElements: 0, financialAccountItemsLoading: false };
  }),
  on(TransactionFormActions.setPaymentReasons, (state, { paymentReasons }): TransactionFormState => {
    return { ...state, paymentReasons };
  }),
  on(TransactionFormActions.loadEnabledSolutionsSuccess, (state, { enabledSolutions }): TransactionFormState => {
    return { ...state, enabledSolutions };
  }),
  on(TransactionFormActions.loadEnabledSolutionConfigsSuccess, (state, { enabledSolutionConfigs }): TransactionFormState => {
    return { ...state, enabledSolutionConfigs };
  }),
  on(TransactionFormActions.setTransactionType, (state, { transactionType }): TransactionFormState => {
    return { ...state, transactionType };
  }),
  on(TransactionFormActions.setUnavailableProcessingPriorities, (state, { achSameDay, wireSameDay, wireNextDay }): TransactionFormState => {
    return { ...state, unavailableProcessingPriorities: { achSameDay, wireSameDay, wireNextDay } };
  }),
  on(TransactionFormActions.createTransactionPageReset, (state): TransactionFormState => {
    return { ...state, ...transactionFormState };
  }),
  on(TransactionFormActions.createTransactionPageClear, (state): TransactionFormState => {
    return {
      ...state,
      ...transactionFormState,
      paymentReasons: state.paymentReasons,
      enabledSolutionConfigs: state.enabledSolutionConfigs,
    };
  }),
  on(TransactionFormActions.updateToAccountAddress, (state, { billingAddress }): TransactionFormState => {
    return { ...state, toAccount: { ...state.toAccount!, bankAccount: { ...state.toAccount!.bankAccount, billingAddress } } };
  }),
  on(TransactionFormActions.formUpdateMltInitialize, (state): TransactionFormState => {
    return { ...state, isFormLoading: true };
  }),
  on(TransactionFormActions.setMultiLegTransactionDetailsFormData, (state, formData): TransactionFormState => {
    const {
      formAction,
      paymentReasons,
      amount,
      fromAccount,
      toAccount,
      fromAccountMethod,
      toAccountMethod,
      transactionType,
      fromAccountPaymentReason,
      toAccountPaymentReason,
      isDebitLegsDisabled,
      fromAccountProcessingPriority,
      toAccountProcessingPriority,
      deliverySpeedOptions,
    } = formData;

    return {
      ...state,
      formAction,
      paymentReasons,
      amount,
      fromAccount,
      toAccount,
      fromAccountMethod,
      toAccountMethod,
      transactionType,
      fromAccountPaymentReason,
      toAccountPaymentReason,
      isDebitLegsDisabled,
      fromAccountProcessingPriority,
      toAccountProcessingPriority,
      isFormLoading: false,
      deliverySpeedOptions,
    };
  }),
  on(TransactionFormActions.setCancelMultiLegTransactionFormData, (state, { formAction, amount, currency }): TransactionFormState => {
    return {
      ...state,
      formAction,
      amount,
      currency,
    };
  }),
  on(TransactionFormActions.formUpdateSltInitialize, (state): TransactionFormState => {
    return { ...state, isFormLoading: true };
  }),
  on(TransactionFormActions.setSingleLegTransactionDetailsFormData, (state, formData): TransactionFormState => {
    const {
      formAction,
      paymentReasons,
      amount,
      fromAccount,
      toAccount,
      fromAccountMethod,
      toAccountMethod,
      transactionType,
      fromAccountPaymentReason,
      toAccountPaymentReason,
      isDebitLegsDisabled,
      fromAccountProcessingPriority,
      toAccountProcessingPriority,
      fromAccountAchDetails,
      deliverySpeedOptions,
    } = formData;

    return {
      ...state,
      formAction,
      paymentReasons,
      amount,
      fromAccount,
      toAccount,
      fromAccountMethod,
      toAccountMethod,
      transactionType,
      fromAccountPaymentReason,
      toAccountPaymentReason,
      isDebitLegsDisabled,
      fromAccountProcessingPriority,
      toAccountProcessingPriority,
      isFormLoading: false,
      fromAccountAchDetails,
      deliverySpeedOptions,
    };
  }),
  on(TransactionFormActions.setScheduledSingleLegTransactionDetailsFormData, (state, formData): TransactionFormState => {
    const {
      formAction,
      paymentReasons,
      amount,
      fromAccount,
      toAccount,
      fromAccountMethod,
      toAccountMethod,
      transactionType,
      fromAccountPaymentReason,
      toAccountPaymentReason,
      isDebitLegsDisabled,
      fromAccountProcessingPriority,
      toAccountProcessingPriority,
      fromAccountAchDetails,
      scheduler,
    } = formData;

    return {
      ...state,
      formAction,
      paymentReasons,
      amount,
      fromAccount,
      toAccount,
      fromAccountMethod,
      toAccountMethod,
      transactionType,
      fromAccountPaymentReason,
      toAccountPaymentReason,
      isDebitLegsDisabled,
      fromAccountProcessingPriority,
      toAccountProcessingPriority,
      isFormLoading: false,
      fromAccountAchDetails,
      scheduler: { ...scheduler },
    };
  }),
  on(TransactionFormActions.setScheduledMultiLegTransactionDetailsFormData, (state, formData): TransactionFormState => {
    const {
      formAction,
      paymentReasons,
      amount,
      fromAccount,
      toAccount,
      fromAccountMethod,
      toAccountMethod,
      transactionType,
      fromAccountPaymentReason,
      toAccountPaymentReason,
      isDebitLegsDisabled,
      fromAccountProcessingPriority,
      toAccountProcessingPriority,
      fromAccountAchDetails,
      scheduler,
    } = formData;

    return {
      ...state,
      formAction,
      paymentReasons,
      amount,
      fromAccount,
      toAccount,
      fromAccountMethod,
      toAccountMethod,
      transactionType,
      fromAccountPaymentReason,
      toAccountPaymentReason,
      isDebitLegsDisabled,
      fromAccountProcessingPriority,
      toAccountProcessingPriority,
      isFormLoading: false,
      fromAccountAchDetails,
      scheduler: { ...scheduler },
    };
  }),
  on(TransactionFormActions.setSelectedDeliveryOption, (state, formData): TransactionFormState => {
    const {
      fromAccountMethod,
      toAccountMethod,
      deliveryOption,
      fromAccountPaymentReason,
      fromAccountProcessingPriority,
      toAccountProcessingPriority,
    } = formData;

    return {
      ...state,
      fromAccountMethod,
      toAccountMethod,
      deliveryOption,
      fromAccountPaymentReason: fromAccountPaymentReason ?? state.fromAccountPaymentReason,
      fromAccountProcessingPriority,
      toAccountProcessingPriority,
    };
  }),
  on(TransactionFormActions.setIdempotencyTimeConstraint, (state, { timestamp }): TransactionFormState => {
    return { ...state, idempotencyTimeConstraint: timestamp };
  }),
  on(TransactionFormActions.setTransactionAttachments, (state, { attachments }): TransactionFormState => {
    return { ...state, attachments: attachments };
  }),
  on(TransactionFormActions.setDeliverySpeedOptions, (state, { deliverySpeedOptions }): TransactionFormState => {
    return { ...state, deliverySpeedOptions };
  }),
);

export const transactionFormFeature = createFeature({
  name: 'transactionFormFeature',
  reducer,
});
