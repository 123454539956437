import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RktFormFieldConfig } from '@rocketfinancialcorp/rocket-ui/form';
import { Address, FormModel } from '@shared/models';
import { updateAddressFields } from './address-edit-form';
import { FormlyFormOptions } from '@ngx-formly/core';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import { AddressSelectModalComponent } from '@shared/components';
import { NotificationService } from '@shared/services';

export interface Addresses {
  addresses: Address[];
}

@Component({
  selector: 'app-address-edit',
  templateUrl: 'address-edit.component.html',
  styleUrls: ['address-edit.component.scss'],
})
export class AddressEditComponent implements OnInit, AfterViewInit {
  @Input() addressType!: string;

  @Input() addresses?: Address[] = [];

  @Input() isRequired?: boolean = false;

  @Output() changed = new EventEmitter<Address[]>();

  @Output() isFormInvalid = new EventEmitter<boolean>();

  addressForm = new FormGroup({});

  options: FormlyFormOptions = {};

  formModel: FormModel<Addresses> = {
    addresses: [{ addressLine1: '', city: '', state: '', country: '', postalCode: '' }],
  };

  addressFields: RktFormFieldConfig[] = [];

  constructor(private ref: ChangeDetectorRef, private modalService: ModalService, private notificationService: NotificationService) {}

  ngOnInit() {
    this.addressFields = [
      ...this.addressFields,
      ...updateAddressFields({
        addressType: this.addressType,
        onMakePrimaryClick: this.onMakePrimaryClick.bind(this),
        onSectionRemove: this.onSectionRemove.bind(this),
        isRequired: this.isRequired,
      }),
    ];
    if (this.addresses?.length) this.formModel.addresses = this.addresses;
  }

  ngAfterViewInit(): void {
    this.addressForm.markAllAsTouched();
    this.isFormInvalid.emit(this.addressForm.invalid);
  }

  onSectionRemove(i: number, removeSection: (index: number) => void) {
    if (this.formModel.addresses && this.formModel.addresses[i] && this.formModel.addresses[i].verified) {
      this.notificationService.displayWarning('Cannot remove verified address');
    } else {
      if (i === 0) {
        switch (this.formModel.addresses?.length) {
          case 1:
            this.options.resetModel!({
              addresses: [{ addressLine1: '', addressLine2: '', city: '', state: '', country: '', postalCode: '' }],
            });
            break;
          case 2:
            removeSection(i);
            break;
          default:
            const primaryAddressSelectModalRef = this.modalService.open(AddressSelectModalComponent, {
              className: 'primary-address-selection-modal',
            });

            primaryAddressSelectModalRef.componentInstance.modalInitData(
              this.formModel.addresses?.filter((address) => !address.primary).map((address) => ({ ...address })),
            );

            primaryAddressSelectModalRef.result.then(
              (newPrimaryAddress) => {
                if (newPrimaryAddress) {
                  newPrimaryAddress.primary = true;
                  removeSection(i);

                  const addresses = this.formModel.addresses
                    ? [...this.formModel.addresses.filter((address) => newPrimaryAddress.id !== address.id)]
                    : [];
                  const updatedAddresses = [newPrimaryAddress, ...addresses];

                  this.formModel = {
                    ...this.formModel,
                    addresses: updatedAddresses,
                  };

                  this.options.resetModel!({ addresses: updatedAddresses });

                  this.changed.emit(this.formModel.addresses);
                  this.isFormInvalid.emit(this.addressForm.invalid);
                }
              },
              () => false,
            );
        }
      } else {
        removeSection(i);
      }
    }
  }

  onMakePrimaryClick(i: number) {
    const addresses = this.formModel.addresses ? [...this.formModel.addresses] : [];
    addresses[0].primary = false;
    addresses[i].primary = true;
    const newPrimaryAddress = addresses.splice(i, 1)[0];
    addresses.unshift(newPrimaryAddress);
    this.formModel.addresses = addresses;
    this.options.resetModel!({ addresses });
  }

  onFormUpdate(): void {
    if (!this.formModel.addresses) return;

    this.formModel.addresses[0].primary = true;
    this.changed.emit(this.formModel.addresses);
    this.isFormInvalid.emit(this.addressForm.invalid);
  }
}
