<div class="title">Please review the transaction</div>

<div class="schedule-preview" *ngIf="reviewData?.scheduleDetails?.scheduleDateTime">
  <div class="scheduled-for">
    <rkt-icon class="schedule-icon" name="calendar"></rkt-icon>

    <div class="scheduled-for-label">Scheduled for:</div>

    <div class="scheduled-for-value">
      <span class="scheduled-date-time">{{ reviewData?.scheduleDetails?.scheduleDateTimeDisplayValue | dateFormat }}</span>
      (Funds will be available to the recipient on {{ isNextDay ? 'the next banking day of' : '' }} the scheduled date)
    </div>
  </div>

  <div class="schedule-recurring" *ngIf="reviewData?.scheduleDetails?.scheduleRecurringDisplayValue as scheduleRecurring">
    Recurrence: {{ scheduleRecurring }}
  </div>
</div>

<div class="move-how">
  <div class="preview-content">
    <div class="preview-content-item move-direction">
      {{ reviewData?.selectedMoveItem === 'push' ? 'Send' : 'Request' }}
    </div>

    <div class="preview-content-item">
      <rkt-icon *ngIf="reviewData?.moveHowSolutionIcon" [name]="reviewData?.moveHowSolutionIcon!"></rkt-icon>
      {{ reviewData?.moveHowSolutionDisplayValue }}

      &nbsp;|&nbsp;{{ reviewData?.paymentReasonName }}

      <ng-container *ngIf="reviewData?.achEntryTypeDisplayName">
        &nbsp;|&nbsp;
        {{ reviewData?.achEntryTypeDisplayName }}
      </ng-container>
      <ng-container *ngIf="reviewData?.moveAchEntryType && ['CIE', 'PPD', 'TEL'].includes(reviewData?.moveAchEntryType!)">
        <span class="preview-label">&nbsp;|&nbsp;Individual ID:&nbsp;</span>
        {{ reviewData?.moveHowAchDetails?.rkorACHIndividualId }}
      </ng-container>

      <ng-container *ngIf="reviewData?.moveAchEntryType && ['ARC', 'BOC', 'POP', 'RCK'].includes(reviewData?.moveAchEntryType!)">
        <span class="preview-label">&nbsp;|&nbsp;Check Serial Number:&nbsp;</span>
        {{ reviewData?.moveHowAchDetails?.rkorACHCheckSerialNumber }}
      </ng-container>

      <ng-container *ngIf="reviewData?.moveAchEntryType && reviewData?.moveAchEntryType === 'POP'">
        <span class="preview-label">&nbsp;|&nbsp;Terminal City:&nbsp;</span>
        {{ reviewData?.moveHowAchDetails?.rkorACHTerminalCity }}
      </ng-container>

      <ng-container *ngIf="reviewData?.moveAchEntryType && reviewData?.moveAchEntryType === 'POP'">
        <span class="preview-label">&nbsp;|&nbsp;Terminal State:&nbsp;</span>
        {{ reviewData?.moveHowAchDetails?.rkorACHTerminalState }}
      </ng-container>
    </div>

    <div class="preview-content-item" *ngIf="reviewData?.selectedMoveHowType === 'ach'">
      <rkt-icon [name]="reviewData?.moveHowAchDetails?.moveAchType === 'NEXT_DAY' ? 'calendar' : 'same-day'"></rkt-icon>
      {{ reviewData?.moveHowAchDetails?.moveAchType?.replace('_', ' ') | titlecase }}
    </div>

    <div class="preview-content-item" *ngIf="reviewData?.selectedMoveHowType === 'wire'">
      <rkt-icon [name]="reviewData?.moveHowWireDetails?.moveWireType === 'NEXT_DAY' ? 'calendar' : 'same-day'"></rkt-icon>
      {{ reviewData?.moveHowWireDetails?.moveWireType?.replace('_', ' ') | titlecase }}
    </div>
  </div>
</div>

<div class="move-separator"></div>

<div class="schedule-name" *ngIf="reviewData?.scheduleDetails?.scheduleDateTime">
  {{
    reviewData?.scheduleDetails?.name ??
      'Scheduled Transaction for ' + (reviewData?.scheduleDetails?.scheduleDateTimeDisplayValue | dateFormat: 'MM/dd/yy')
  }}
</div>

<div class="move-amount-reason">
  <div class="move-amount-label">Amount</div>
  <div class="move-amount-value">{{ reviewData?.moveAmountValue | currency: reviewData?.selectedMoveFrom?.currency:'symbol-narrow' }}</div>
</div>

<div class="from-to-row">
  <div class="from-to-item">
    <div class="title">From Account</div>
    <div class="item-body">
      <div class="item-body-row" *ngIf="reviewData?.selectedMoveFrom?.customer || reviewData?.selectedMoveFrom?.recipient">
        <div class="label">Name:</div>
        <div class="value">
          <span
            class="move-from-to-name"
            *ngIf="reviewData?.selectedMoveFrom?.customer"
            [title]="reviewData?.selectedMoveFrom?.customer?.displayName"
          >
            {{ reviewData?.selectedMoveFrom?.customer?.displayName }}
          </span>

          <span
            class="move-from-to-name"
            *ngIf="reviewData?.selectedMoveFrom?.recipient"
            [title]="reviewData?.selectedMoveFrom?.accountHolderName"
          >
            {{ reviewData?.selectedMoveFrom?.accountHolderName }}
          </span>
        </div>
      </div>
      <div class="item-body-row">
        <div class="label">Account Type:</div>
        <div class="value">
          {{
            reviewData?.selectedMoveFrom?.customer
              ? reviewData?.selectedMoveFrom?.customer?.customerType
              : reviewData?.selectedMoveFrom?.recipient
              ? reviewData?.selectedMoveFrom?.recipient?.recipientTypeName
              : 'Business Account'
          }}
          {{ reviewData?.selectedMoveFrom?.accountHolderType | titlecase }}
        </div>
      </div>
      <div class="item-body-row">
        <div class="label">Account Name & No.:</div>
        <div class="value">
          {{ reviewData?.selectedMoveFrom?.name }}<br *ngIf="reviewData?.selectedMoveFrom?.name" />{{
            reviewData?.selectedMoveFrom?.displayName
          }}
        </div>
      </div>
      <div class="item-body-row" *ngIf="reviewData?.selectedMoveFrom?.category !== 'EXTERNAL'">
        <div class="label">Available Balance:</div>
        <div class="value">
          {{ reviewData?.selectedMoveFrom?.availableBalance | currency: reviewData?.selectedMoveFrom?.currency:'symbol-narrow' }}
        </div>
      </div>
    </div>
  </div>
  <div class="from-to-item">
    <div class="title">To Account</div>
    <div class="item-body">
      <div class="item-body-row" *ngIf="reviewData?.selectedMoveTo?.customer || reviewData?.selectedMoveTo?.recipient">
        <div class="label">Name:</div>
        <div class="value">
          <span
            class="move-from-to-name"
            *ngIf="reviewData?.selectedMoveTo?.customer"
            [title]="reviewData?.selectedMoveTo?.customer?.displayName"
          >
            {{ reviewData?.selectedMoveTo?.customer?.displayName }}
          </span>

          <span
            class="move-from-to-name"
            *ngIf="reviewData?.selectedMoveTo?.recipient"
            [title]="reviewData?.selectedMoveTo?.accountHolderName"
          >
            {{ reviewData?.selectedMoveTo?.accountHolderName }}
          </span>
        </div>
      </div>
      <div class="item-body-row">
        <div class="label">Account Type:</div>
        <div class="value">
          {{
            reviewData?.selectedMoveTo?.customer
              ? reviewData?.selectedMoveTo?.customer?.customerType
              : reviewData?.selectedMoveTo?.recipient
              ? reviewData?.selectedMoveTo?.recipient?.recipientTypeName
              : 'Business Account'
          }}
          {{ reviewData?.selectedMoveTo?.accountHolderType | titlecase }}
        </div>
      </div>
      <div class="item-body-row">
        <div class="label">Account Name & No.:</div>
        <div class="value">
          {{ reviewData?.selectedMoveTo?.name }}<br *ngIf="reviewData?.selectedMoveTo?.name" />{{
            reviewData?.selectedMoveTo?.account || reviewData?.selectedMoveTo?.displayName
          }}
        </div>
      </div>
      <div class="item-body-row" *ngIf="reviewData?.selectedMoveTo?.category !== 'EXTERNAL'">
        <div class="label">Available Balance:</div>
        <div class="value">
          {{ reviewData?.selectedMoveTo?.availableBalance | currency: reviewData?.selectedMoveTo?.currency:'symbol-narrow' }}
        </div>
      </div>

      <div
        class="item-body-row"
        *ngIf="reviewData?.selectedMoveHowType === 'wire' && reviewData?.selectedMoveTo?.bankAccount?.billingAddress"
      >
        <div class="label">Address:</div>
        <div class="value">
          {{ reviewData?.selectedMoveTo?.bankAccount?.billingAddress?.addressLine1 }},
          {{ reviewData?.selectedMoveTo?.bankAccount?.billingAddress?.addressLine2 }}
          {{ reviewData?.selectedMoveTo?.bankAccount?.billingAddress?.city }},
          {{ reviewData?.selectedMoveTo?.bankAccount?.billingAddress?.state }}
          {{ reviewData?.selectedMoveTo?.bankAccount?.billingAddress?.postalCode }}
          {{ reviewData?.selectedMoveTo?.bankAccount?.billingAddress?.country }}
        </div>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="reviewData?.wireDetails?.narrative || reviewData?.wireDetails?.memo">
  <div class="move-separator"></div>
  <div class="move-wire-details">
    <div class="move-wire-details-item" *ngIf="reviewData?.wireDetails?.narrative">
      <div class="move-note-label">Narrative</div>
      <div class="move-note-value" [class.empty-value]="!reviewData?.wireDetails?.narrative">
        {{ reviewData?.wireDetails?.narrative }}
      </div>
    </div>

    <div class="move-wire-details-item" *ngIf="reviewData?.wireDetails?.memo">
      <div class="move-note-label">Memo</div>
      <div class="move-note-value" [class.empty-value]="!reviewData?.wireDetails?.memo">{{ reviewData?.wireDetails?.memo }}</div>
    </div>
  </div>
</ng-container>

<div class="move-separator"></div>

<div class="move-note" *ngIf="reviewData?.note">
  <div class="move-note-label">Notes</div>
  <div class="move-note-value" [class.empty-value]="!reviewData?.note">{{ reviewData?.note }}</div>
</div>

<div class="modal-buttons">
  <button rktButton btnType="secondary" title="Back to Edit" (click)="activeModal.close()">Back to Edit</button>
  <button rktButton title="Confirm" (click)="activeModal.close(true)">Confirm</button>
</div>
