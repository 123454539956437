<h1 class="title">{{ actionType | titlecase }} Beneficial Owner</h1>

<button class="close-button" (click)="activeModal.close()"><rkt-icon name="times"></rkt-icon></button>

<div class="details-page-edit-form">
  <ng-container *ngIf="actionType === 'add'">
    <ng-container *ngTemplateOutlet="beneficiaryInformation"></ng-container>
  </ng-container>

  <ng-container *ngIf="actionType === 'edit'">
    <app-expandable-row
      title="Beneficial Owner Information"
      titleIconName="beneficiary"
      [isCollapsed]="!editForm.invalid || !editOwningForm.invalid"
    >
      <ng-container *ngTemplateOutlet="beneficiaryInformation"></ng-container>
    </app-expandable-row>
  </ng-container>

  <ng-template #beneficiaryInformation>
    <form [formGroup]="editForm">
      <rkt-form [form]="editForm" [fields]="editFormFields" (modelChange)="onFormUpdate()" [model]="editFormModel"></rkt-form>
    </form>

    <div class="separator-line"></div>

    <form [formGroup]="editOwningForm">
      <rkt-form [form]="editOwningForm" [fields]="editOwningFormFields" (modelChange)="onFormUpdate()" [model]="editFormModel"></rkt-form>
    </form>
  </ng-template>

  <app-address-add
    *ngIf="actionType === 'add'"
    (changed)="setNewAddresses($event)"
    (isFormInvalid)="setIsAddressFormInvalid($event)"
    [isPhysicalAddressRequired]="false"
  ></app-address-add>

  <ng-container *ngIf="actionType === 'edit'">
    <app-expandable-row title="Physical Address" titleIconName="location">
      <app-address-edit
        addressType="Physical"
        [addresses]="editFormModel.physicalAddresses"
        (changed)="setEditedAddresses($event, 'PHYSICAL')"
        (isFormInvalid)="setIsAddressFormInvalid($event, 'PHYSICAL')"
      ></app-address-edit>
    </app-expandable-row>

    <app-expandable-row title="Mailing Address" titleIconName="mailing">
      <app-address-edit
        addressType="Mailing"
        [addresses]="editFormModel.mailingAddresses"
        (changed)="setEditedAddresses($event, 'MAILING')"
        (isFormInvalid)="setIsAddressFormInvalid($event, 'MAILING')"
      ></app-address-edit>
    </app-expandable-row>

    <app-expandable-row title="Shipping Address" titleIconName="shipping">
      <app-address-edit
        addressType="Shipping"
        [addresses]="editFormModel.shippingAddresses"
        (changed)="setEditedAddresses($event, 'SHIPPING')"
        (isFormInvalid)="setIsAddressFormInvalid($event, 'SHIPPING')"
      ></app-address-edit>
    </app-expandable-row>
  </ng-container>

  <div *ngIf="actionType === 'add'" class="separator-line"></div>

  <div *ngIf="submitError" class="details-page-edit-form-error">{{ submitError }}</div>

  <div class="buttons">
    <button rktButton type="button" btnType="tertiary" (click)="activeModal.close()">Cancel</button>
    <button
      rktButton
      type="button"
      (click)="onSave()"
      [disabled]="loading || editForm.invalid || editOwningForm.invalid || editAddressForm.invalid || this.isAddressFormInvalid"
    >
      {{ actionType === 'add' ? 'Add ' : 'Update' }} Beneficial Owner
    </button>
  </div>
</div>
