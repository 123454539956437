import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Event as NavigationEvent, NavigationEnd } from '@angular/router';
import { BehaviorSubject, Observable, Subject, catchError, defaultIfEmpty, filter, forkJoin, of, switchMap, takeUntil, tap } from 'rxjs';
import { DatatableColumn, FetchData } from '@rocketfinancialcorp/rocket-ui/table';
import { TransactionBatch, TransactionBatchTransactionItemRaw } from '@shared/models';
import { FinancialAccountService, TransactionBatchesService } from '@shared/services';
import { getMoveFromToFinancialAccount } from '@shared/utils';

@Component({
  selector: 'app-transaction-batch-rejected-list',
  templateUrl: './transaction-batch-rejected-list.component.html',
})
export class TransactionBatchRejectedListComponent implements OnInit, AfterViewChecked, OnDestroy {
  rejectedTransactions: TransactionBatchTransactionItemRaw[] = [];

  transactions: TransactionBatchTransactionItemRaw[] = [];

  columns: DatatableColumn[] = [
    { name: 'Move From', prop: 'debitFinancialAccountName', colType: 'two-line-col', flexSize: 250 },
    { name: 'Move To', prop: 'creditFinancialAccountName', colType: 'two-line-col', flexSize: 250 },
    { name: 'Rejection Reason', prop: 'errorMessage', flexSize: 500, isMultiline: true },
    { name: 'Amount', prop: 'amount', flexSize: 151, colAlign: 'right', colType: 'amount' },
    { name: 'Status', prop: 'latestStatus', fixedSize: 140, colAlign: 'right', colType: 'status-tag' },
  ];

  totalElements = 0;

  loading = true;

  isInitialized = false;

  batchId$ = new BehaviorSubject<string | null>(null);

  transactionBatch$?: Observable<TransactionBatch | null>;

  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private financialAccountService: FinancialAccountService,
    private ref: ChangeDetectorRef,
    private transactionBatchesService: TransactionBatchesService,
  ) {
    this.router.events
      .pipe(
        filter((event: NavigationEvent) => event instanceof NavigationEnd),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: () => {
          const { snapshot } = this.route;
          this.batchId$.next(snapshot.parent?.paramMap.get('id')!);
        },
      });

    this.transactionBatch$ = this.batchId$.pipe(
      switchMap((batchId) => {
        return this.transactionBatchesService.getTransactionBatchById(batchId!).pipe(
          tap({
            next: (transactionBatch) => {
              this.rejectedTransactions = transactionBatch.transactions.filter((item) => !!item.errorMessage && !item.id);
              this.isInitialized = true;
              this.getTransactions({ page: 0 });
            },
          }),
        );
      }),
    );
  }

  public ngOnInit(): void {
    this.transactionBatch$?.pipe(takeUntil(this.destroy$)).subscribe();
  }

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getTransactions({ page }: FetchData): void {
    if (!this.isInitialized) {
      return;
    } else if (this.isInitialized && !this.rejectedTransactions.length) {
      this.loading = false;
      return;
    }

    const curPage = page ?? 0;

    this.loading = true;
    forkJoin(
      this.rejectedTransactions.slice(curPage * 25, (curPage + 1) * 25).map((item) => {
        return this.financialAccountService.getFinancialAccountById(item.debitFinancialAccountId).pipe(
          tap({
            next: (financialAccount) => {
              item.debitFinancialAccountName = getMoveFromToFinancialAccount(financialAccount);
            },
            error: () => {
              item.debitFinancialAccountName = item.debitFinancialAccountId;
            },
          }),
          catchError(() => of({})),
          switchMap(() => {
            return this.financialAccountService.getFinancialAccountById(item.creditFinancialAccountId).pipe(
              tap({
                next: (financialAccount) => {
                  item.creditFinancialAccountName = getMoveFromToFinancialAccount(financialAccount);
                },
                error: () => {
                  item.creditFinancialAccountName = item.creditFinancialAccountId;
                },
              }),
              catchError(() => of({})),
            );
          }),
        );
      }),
    )
      .pipe(takeUntil(this.destroy$), defaultIfEmpty([]))
      .subscribe({
        next: () => {
          this.totalElements = this.rejectedTransactions.length;
          this.transactions = this.rejectedTransactions.slice(curPage * 25, (curPage + 1) * 25);
          this.loading = false;
        },
      });
  }
}
