import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AdaRegistrationListActions, fromAdaRegistration, TransactionFormActions } from '@shared/store';
import { Store } from '@ngrx/store';
import { Observable, Subject, map, debounceTime, tap, switchMap, of, concat, catchError } from 'rxjs';
import { AdaRegistration, AdaRegistrationDropdownItem } from '@shared/models';
import { FetchData } from '@rocketfinancialcorp/rocket-ui/table';
import { NgSelectComponent } from '@ng-select/ng-select';
import { AdaRegistrationService } from '@shared/services';

@Component({
  selector: 'app-debit-authorization-select',
  templateUrl: './debit-authorization-select.component.html',
  styleUrls: ['./debit-authorization-select.component.scss'],
})
export class DebitAuthorizationSelectComponent {
  @ViewChild('select') selectRef!: NgSelectComponent;

  @Input() items$!: Observable<AdaRegistrationDropdownItem[]>;

  @Input() selectedItem?: string;

  @Input() selectedAuthorization?: AdaRegistrationDropdownItem;

  @Output() changed = new EventEmitter<string | undefined>();

  loading = false;

  searchString?: string;

  debitAuthorizations$ = this.store.select(fromAdaRegistration.selectAdaRegistrationList);

  searchInput$ = new Subject<string>();

  constructor(private store: Store, private adaRegistrationService: AdaRegistrationService) {
    this.getRegistrations({ page: 0 });

    this.items$ = this.debitAuthorizations$.pipe(
      switchMap((debitAuthorizations) => {
        return concat(
          of(this.mapADARegistrationDropdown(debitAuthorizations ?? [])),
          this.searchInput$.pipe(
            debounceTime(300),
            tap({
              next: () => (this.loading = true),
            }),
            switchMap((searchTerm) => {
              if (!searchTerm) {
                return of(this.mapADARegistrationDropdown(debitAuthorizations ?? []));
              } else {
                return this.adaRegistrationService.getAdaRegistrations({ page: 0, size: 100, searchString: searchTerm }).pipe(
                  tap({
                    next: () => (this.loading = false),
                  }),
                  map((debitRegistration) => {
                    return this.mapADARegistrationDropdown(debitRegistration.items);
                  }),
                  catchError(() => of([] as AdaRegistrationDropdownItem[])),
                );
              }
            }),
          ),
        );
      }),
    );
  }

  onChange(item?: AdaRegistrationDropdownItem): void {
    this.selectedAuthorization = item;
    this.changed.emit(item?.value);
    this.store.dispatch(TransactionFormActions.setDebitAuthorization({ debitAuthorization: item ?? null }));
  }

  onOpen() {
    this.selectedItem = this.selectedAuthorization?.value;
  }

  onClose() {
    this.searchInput$.next('');
    this.selectRef?.blur();
  }

  getRegistrations({ page, size }: FetchData, searchString?: string): void {
    this.store.dispatch(
      AdaRegistrationListActions.loadAdaRegistrations({
        params: {
          page: page ?? 0,
          size,
          searchString,
        },
      }),
    );
  }

  mapADARegistrationDropdown(authorizations: AdaRegistration[] | null): AdaRegistrationDropdownItem[] {
    let defaultDropdown: AdaRegistrationDropdownItem[] = [];

    if (authorizations?.length) {
      defaultDropdown = [
        ...defaultDropdown,
        ...authorizations.map((adaRegistration) => {
          return {
            label: `${adaRegistration.customerName}::${adaRegistration.transactionOccurrenceType}::$${adaRegistration.amount}`,
            value: adaRegistration.id,
          };
        }),
      ];
    }

    return defaultDropdown;
  }
}
