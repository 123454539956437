<h1 class="title">Edit Transaction</h1>

<button class="close-button" (click)="activeModal.close()"><rkt-icon name="times"></rkt-icon></button>

<div class="entity-details-container">
  <app-collapsible-row number="1" class="move-how-row" label="Move How" [isRowHasValue]="true">
    <div class="registry-form-preview">
      <div class="preview-content">
        <div class="preview-content-item">
          <rkt-icon [name]="moveMoneyService.moveHow.moveDirection === 'push' ? 'send' : 'money-request'"></rkt-icon>
          {{ moveMoneyService.moveHowDirectionDisplayValue }}
        </div>

        <div class="preview-content-item">
          <rkt-icon *ngIf="moveMoneyService.moveHowSolutionIcon" [name]="moveMoneyService.moveHowSolutionIcon"></rkt-icon>
          {{ moveMoneyService.moveHowSolutionDisplayValue }}
        </div>
      </div>

      <button class="preview-edit" [disabled]="true">
        <rkt-icon name="edit"></rkt-icon>
      </button>
    </div>
  </app-collapsible-row>

  <app-collapsible-row
    [hidden]="moveMoneyService.moveHow.moveHowType !== 'ach'"
    number="2"
    class="move-how-ach-details"
    label="ACH Details"
    [isRowHasValue]="true"
    ><div class="registry-form-preview">
      <div class="preview-content">
        <div class="preview-content-item">
          {{ achEntryTypeDisplayName }}

          <ng-container *ngIf="['CIE', 'PPD', 'TEL'].includes(moveAchEntryType)">
            <span class="preview-label">&nbsp;|&nbsp;Individual ID:&nbsp;</span>
            {{ moveMoneyService.moveHowAchDetails.rkorACHIndividualId }}
          </ng-container>

          <ng-container *ngIf="['ARC', 'BOC', 'POP', 'RCK'].includes(moveAchEntryType)">
            <span class="preview-label">&nbsp;|&nbsp;Check Serial Number:&nbsp;</span>
            {{ moveMoneyService.moveHowAchDetails.rkorACHCheckSerialNumber }}
          </ng-container>

          <ng-container *ngIf="moveAchEntryType === 'POP'">
            <span class="preview-label">&nbsp;|&nbsp;Terminal City:&nbsp;</span>
            {{ moveMoneyService.moveHowAchDetails.rkorACHTerminalCity }}
          </ng-container>

          <ng-container *ngIf="moveAchEntryType === 'POP'">
            <span class="preview-label">&nbsp;|&nbsp;Terminal State:&nbsp;</span>
            {{ moveMoneyService.moveHowAchDetails.rkorACHTerminalState }}
          </ng-container>
        </div>

        <div class="preview-content-item">
          <rkt-icon name="calendar"></rkt-icon>
          {{ moveMoneyService.moveHowAchDetails.moveAchType?.replace('_', ' ') | titlecase }}
        </div>
      </div>

      <button class="preview-edit" [disabled]="true">
        <rkt-icon name="edit"></rkt-icon>
      </button>
    </div>
  </app-collapsible-row>

  <app-collapsible-row
    [number]="moveMoneyService.moveHow.moveHowType === 'ach' ? '3' : '2'"
    class="move-reason-amount"
    label="Reason & Amount"
    [isRowExpanded]="moveMoneyService.isRowExpanded('move-reason-amount')"
    [isRowHasValue]="moveMoneyService.isRowHasValue('move-reason-amount')"
  >
    <div
      class="registry-form-preview"
      *ngIf="moveMoneyService.isRowHasValue('move-reason-amount') && !moveMoneyService.isRowExpanded('move-reason-amount')"
    >
      <div class="preview-content">
        <div class="preview-content-item">{{ moveMoneyService.paymentReason?.reason }}</div>
        <div class="preview-content-item">
          <rkt-icon name="money-bag"></rkt-icon>
          {{ (moveMoneyService.moveAmount | number: '1.2-2') || '' | mask: 'separator':',' | prefix: '$ ' }}
        </div>
      </div>

      <button class="preview-edit" (click)="moveMoneyService.handleRowExpand('move-reason-amount')">
        <rkt-icon name="edit"></rkt-icon>
      </button>
    </div>
    <div class="registry-form" [hidden]="!moveMoneyService.isRowExpanded('move-reason-amount')">
      <div class="move-reason-amount-wrapper">
        <div class="payment-reasons-wrapper">
          <app-move-reason-selection
            [directionType]="moveMoneyService.moveHow.moveDirection"
            [moveHowType]="moveMoneyService.moveHow.moveHowType"
            [initialValue]="moveMoneyService.paymentReason?.id"
            (selected)="onPaymentReasonSelect($event)"
          ></app-move-reason-selection>
        </div>

        <div class="move-amount-wrapper">
          <app-move-amount-control
            [transactionType]="moveMoneyService.moveHow.moveHowType"
            [transactionWindowType]="moveMoneyService.moveHowAchDetails.moveAchType"
            [achEntryType]="moveMoneyService.moveHowAchDetails.moveAchEntryType"
            [initialValue]="transaction?.amount"
            (changed)="moveMoneyService.onAmountChange($event)"
            (blurred)="onAmountBlur()"
            (errored)="moveMoneyService.onAmountError($event)"
          ></app-move-amount-control>
        </div>
      </div>
    </div>
  </app-collapsible-row>

  <app-collapsible-row
    [number]="moveMoneyService.moveHow.moveHowType === 'ach' ? '4' : '3'"
    class="move-from"
    label="From Account"
    [isRowExpanded]="moveMoneyService.isRowExpanded('move-from')"
    [isRowHasValue]="moveMoneyService.isRowHasValue('move-from')"
  >
    <div class="registry-form-preview" *ngIf="moveMoneyService.isRowHasValue('move-from') && !moveMoneyService.isRowExpanded('move-from')">
      <div class="preview-content">
        <div class="preview-content-item" *ngIf="!moveMoneyService.selectedDebitFinancialAccount?.accountHolderType">
          {{ moveMoneyService.selectedDebitFinancialAccount?.accountHolderTypeDisplayName }}
        </div>

        <div class="preview-content-item" *ngIf="moveMoneyService.selectedDebitFinancialAccount?.customer">
          <span class="preview-label"
            >{{ moveMoneyService.selectedDebitFinancialAccount?.customer?.type | titlecase }}
            {{ moveMoneyService.selectedDebitFinancialAccount?.accountHolderTypeDisplayName }}:&nbsp;</span
          >
          {{ moveMoneyService.selectedDebitFinancialAccount?.customer?.displayName }}
        </div>

        <div class="preview-content-item" *ngIf="moveMoneyService.selectedDebitFinancialAccount?.recipient">
          <span class="preview-label"
            >{{ moveMoneyService.selectedDebitFinancialAccount?.recipient?.recipientType | titlecase }}
            {{ moveMoneyService.selectedDebitFinancialAccount?.accountHolderTypeDisplayName }}:&nbsp;</span
          >
          {{ moveMoneyService.selectedDebitFinancialAccount?.recipient?.displayName }}
        </div>

        <div class="preview-content-item">
          <rkt-icon [name]="moveMoneyService.selectedDebitFinancialAccount?.type === 'BANK' ? 'bank' : 'card'"></rkt-icon>
          {{ moveMoneyService.selectedDebitFinancialAccount?.name }}
          *
          {{
            moveMoneyService.selectedDebitFinancialAccount?.bankAccount?.accountNumberTail ||
              moveMoneyService.selectedDebitFinancialAccount?.card?.cardNumberTail
          }}
          {{ moveMoneyService.selectedDebitFinancialAccount?.subtype | titlecase }}
          {{ moveMoneyService.selectedDebitFinancialAccount?.type === 'BANK' ? 'Acc' : 'Card' }}
        </div>

        <div class="preview-content-item" *ngIf="moveMoneyService.selectedDebitFinancialAccount?.category === 'INTERNAL'">
          <span class="preview-label">Available Balance:&nbsp;</span>
          {{
            (moveMoneyService.selectedDebitFinancialAccount?.availableBalance | number: '1.2-2') || ''
              | mask: 'separator':','
              | prefix: '$ '
          }}
        </div>

        <div class="preview-content preview-content-account-info" *ngIf="moveMoneyService.selectedDebitFinancialAccount?.customer">
          <div class="preview-content-item">{{ moveMoneyService.selectedDebitFinancialAccount?.customer?.primaryEmail?.value }}</div>

          <div class="preview-content-item">{{ moveMoneyService.selectedDebitFinancialAccount?.customer?.primaryPhoneNumber?.number }}</div>
        </div>

        <div class="preview-content preview-content-account-info" *ngIf="moveMoneyService.selectedDebitFinancialAccount?.recipient">
          <div class="preview-content-item">{{ moveMoneyService.selectedDebitFinancialAccount?.recipient?.email }}</div>

          <div class="preview-content-item">{{ moveMoneyService.selectedDebitFinancialAccount?.recipient?.phoneNumber }}</div>
        </div>
      </div>

      <button class="preview-edit" (click)="moveMoneyService.handleRowExpand('move-from')">
        <rkt-icon name="edit"></rkt-icon>
      </button>
    </div>
    <div class="registry-form" [hidden]="!moveMoneyService.isRowExpanded('move-from')">
      <app-move-money-financial-account-selection
        #moveFromSelection
        *ngIf="moveMoneyService.moveHow.moveDirection && moveMoneyService.moveHow.moveHowType"
        [stickyHeader]="false"
        [transaction]="transaction"
        [type]="moveMoneyService.isMoveDirectionPull ? 'not-business' : 'business'"
        (selectedFinancialAccount)="onDebitFinancialAccountSelect($event)"
        [isMoveFieldsDisabled]="!moveMoneyService.isRowHasValue('move-how')"
        [allowedTypes]="debitAllowedAccountTypes$ | async"
        [selectedMoveHowType]="moveMoneyService.moveHow.moveHowType"
        [selectedMoveDirection]="moveMoneyService.moveHow.moveDirection"
        [isRowExpanded]="moveMoneyService.isRowExpanded('move-from')"
      ></app-move-money-financial-account-selection>
    </div>
  </app-collapsible-row>
  <app-collapsible-row
    [number]="moveMoneyService.moveHow.moveHowType === 'ach' ? '5' : '4'"
    class="move-to"
    label="To Account"
    [isRowExpanded]="moveMoneyService.isRowExpanded('move-to')"
    [isRowHasValue]="moveMoneyService.isRowHasValue('move-to')"
  >
    <div class="registry-form-preview" *ngIf="moveMoneyService.isRowHasValue('move-to') && !moveMoneyService.isRowExpanded('move-to')">
      <div class="preview-content">
        <div class="preview-content-item" *ngIf="!moveMoneyService.selectedCreditFinancialAccount?.accountHolderType">
          {{ moveMoneyService.selectedCreditFinancialAccount?.accountHolderTypeDisplayName }}
        </div>

        <div class="preview-content-item" *ngIf="moveMoneyService.selectedCreditFinancialAccount?.customer">
          <span class="preview-label"
            >{{ moveMoneyService.selectedCreditFinancialAccount?.customer?.type | titlecase }}
            {{ moveMoneyService.selectedCreditFinancialAccount?.accountHolderTypeDisplayName }}:&nbsp;</span
          >
          {{ moveMoneyService.selectedCreditFinancialAccount?.customer?.displayName }}
        </div>

        <div class="preview-content-item" *ngIf="moveMoneyService.selectedCreditFinancialAccount?.recipient">
          <span class="preview-label"
            >{{ moveMoneyService.selectedCreditFinancialAccount?.recipient?.recipientType | titlecase }}
            {{ moveMoneyService.selectedCreditFinancialAccount?.accountHolderTypeDisplayName }}:&nbsp;</span
          >
          {{ moveMoneyService.selectedCreditFinancialAccount?.recipient?.displayName }}
        </div>

        <div class="preview-content-item">
          <rkt-icon [name]="moveMoneyService.selectedCreditFinancialAccount?.type === 'BANK' ? 'bank' : 'card'"></rkt-icon>
          {{ moveMoneyService.selectedCreditFinancialAccount?.name }}
          *
          {{
            moveMoneyService.selectedCreditFinancialAccount?.bankAccount?.accountNumberTail ||
              moveMoneyService.selectedCreditFinancialAccount?.card?.cardNumberTail
          }}
          {{ moveMoneyService.selectedCreditFinancialAccount?.subtype | titlecase }}
          {{ moveMoneyService.selectedCreditFinancialAccount?.type === 'BANK' ? 'Acc' : 'Card' }}
        </div>

        <div class="preview-content-item" *ngIf="moveMoneyService.selectedCreditFinancialAccount?.category === 'INTERNAL'">
          <span class="preview-label">Available Balance:&nbsp;</span>
          {{
            (moveMoneyService.selectedCreditFinancialAccount?.availableBalance | number: '1.2-2') || ''
              | mask: 'separator':','
              | prefix: '$ '
          }}
        </div>

        <div class="preview-content preview-content-account-info" *ngIf="moveMoneyService.selectedCreditFinancialAccount?.customer">
          <div class="preview-content-item">{{ moveMoneyService.selectedCreditFinancialAccount?.customer?.primaryEmail?.value }}</div>

          <div class="preview-content-item">
            {{ moveMoneyService.selectedCreditFinancialAccount?.customer?.primaryPhoneNumber?.number }}
          </div>
        </div>

        <div class="preview-content preview-content-account-info" *ngIf="moveMoneyService.selectedCreditFinancialAccount?.recipient">
          <div class="preview-content-item">{{ moveMoneyService.selectedCreditFinancialAccount?.recipient?.email }}</div>

          <div class="preview-content-item">{{ moveMoneyService.selectedCreditFinancialAccount?.recipient?.phoneNumber }}</div>
        </div>
      </div>

      <button class="preview-edit" (click)="moveMoneyService.handleRowExpand('move-to')">
        <rkt-icon name="edit"></rkt-icon>
      </button>
    </div>
    <div class="registry-form" [hidden]="!moveMoneyService.isRowExpanded('move-to')">
      <app-move-money-financial-account-selection
        #moveToSelection
        *ngIf="moveMoneyService.moveHow.moveDirection && moveMoneyService.moveHow.moveHowType"
        [stickyHeader]="false"
        [transaction]="transaction"
        [isCardAccount]="moveMoneyService.moveHow.moveHowType === 'push-to-card'"
        [type]="moveMoneyService.isMoveDirectionPush && moveMoneyService.moveHow.moveHowType !== 'transfer' ? 'not-business' : 'business'"
        [allowedTypes]="creditAllowedAccountTypes$ | async"
        (selectedFinancialAccount)="onCreditFinancialAccountSelect($event)"
        [isMoveFieldsDisabled]="!moveMoneyService.isRowHasValue('move-how')"
        [selectedMoveHowType]="moveMoneyService.moveHow.moveHowType"
        [selectedDebitFinancialAccount]="moveMoneyService.selectedDebitFinancialAccount"
        [selectedMoveDirection]="moveMoneyService.moveHow.moveDirection"
        [isRowExpanded]="moveMoneyService.isRowExpanded('move-to')"
      ></app-move-money-financial-account-selection>
    </div>
  </app-collapsible-row>

  <app-move-note-control (changed)="moveMoneyService.onNoteChange($event)"></app-move-note-control>

  <div class="move-money-buttons">
    <button rktButton btnType="tertiary" title="Cancel" (click)="activeModal.close()">Cancel</button>

    <button rktButton title="Continue" (click)="onEditContinueBtnClick()" [disabled]="isSubmitDisabled()">Continue</button>
  </div>
</div>
