import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, AfterViewChecked } from '@angular/core';
import { compact, isEmpty } from 'lodash-es';
import { Observable, of, Subject } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap, switchMap, take, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import { ModalService } from '@rocketfinancialcorp/rocket-ui/modal';
import {
  EnabledSolutionConfig,
  FinancialAccountListItem,
  MoveHowAchDetails,
  MoveHowWireDetails,
  MoveMoneyRequest,
  MoveWireDetails,
  PaymentReason,
  ScheduledTransactionDetails,
  TransactionDetails,
} from '@shared/models';
import { TransactionService, NoteService, NotificationService, achEntryTypeCodes, ScheduledTransactionsService } from '@shared/services';
import { ErrorUtils, isBankingHoliday, isTimeExpired, toTitleCase } from '@shared/utils';
import { fromAuth, fromTransaction, TransactionSolutionActions } from '@shared/store';
import {
  AbstractMoveMoneyBase,
  ConfirmModalComponent,
  MoveAmountControlComponent,
  MoveHowAchDetailsComponent,
  MoveHowSelection,
  MoveHowSelectionComponent,
  MoveHowWireDetailsComponent,
  MoveMoneyAttentionModalComponent,
  MoveMoneyFinancialAccountSelectionComponent,
  MoveMoneyWrapperService,
  MoveNoteControlComponent,
  MoveReasonSelectionComponent,
  MoveRowType,
  MoveWireSelectionComponent,
} from '@shared/components';
import { MoveMoneyModalComponent } from '../move-money-modal/move-money-modal.component';
import { MoveMoneyErrorModalComponent } from '../move-money-error-modal/move-money-error-modal.component';
import { MoveMoneyReviewComponent } from '../move-money-review/move-money-review.component';
import { concatLatestFrom } from '@ngrx/effects';

const SAME_DAY_ACH_LIMIT = 100000;
const ACH_PROVIDER = 'PAYGEARS';

@Component({
  selector: 'app-move-money',
  templateUrl: './move-money.component.html',
  styleUrls: ['./move-money.component.scss'],
  providers: [
    {
      provide: AbstractMoveMoneyBase,
      useClass: MoveMoneyWrapperService,
    },
  ],
})
export class MoveMoneyComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild(MoveHowSelectionComponent) moveHowSelection!: MoveHowSelectionComponent;

  @ViewChild(MoveHowAchDetailsComponent) moveHowAchDetailsSelection!: MoveHowAchDetailsComponent;

  @ViewChild(MoveHowWireDetailsComponent) moveHowWireDetailsSelection!: MoveHowWireDetailsComponent;

  @ViewChild(MoveReasonSelectionComponent) reasonSelect!: MoveReasonSelectionComponent;

  @ViewChild(MoveAmountControlComponent) amountField!: MoveAmountControlComponent;

  @ViewChild(MoveWireSelectionComponent) wireDetailsSelection!: MoveWireSelectionComponent;

  @ViewChild(MoveNoteControlComponent) noteField!: MoveNoteControlComponent;

  @ViewChild('moveFromSelection') moveFromSelection!: MoveMoneyFinancialAccountSelectionComponent;

  @ViewChild('moveToSelection') moveToSelection!: MoveMoneyFinancialAccountSelectionComponent;

  enabledSolutionPermissions$ = this.store.select(fromTransaction.selectEnabledSolutions);

  enabledSolutionConfigs$ = this.store.select(fromTransaction.selectEnabledSolutionConfigs);

  debitAllowedAccountTypes$ = this.store.select(fromTransaction.selectTransactionTypes('debit'));

  creditAllowedAccountTypes$ = this.store.select(fromTransaction.selectTransactionTypes('credit'));

  submitLoader = false;

  isSubmitCompleted = false;

  transactionTimeZone?: string;

  sameDayAchSubmitDeadline?: string;

  sameDayWireSubmitDeadline?: string;

  isSameDayAchWindowDisabled = false;

  isSameDayWireWindowDisabled = false;

  isNextDayWireWindowDisabled = false;

  idempotencyKey: string;

  protected destroy$ = new Subject<void>();

  get isSubmitDisabled(): boolean {
    const { moveHowType } = this.moveMoneyService.moveHow;
    const rows = [
      this.moveMoneyService.isRowHasValue('move-how'),
      moveHowType === 'ach' ? this.moveMoneyService.isRowHasValue('move-how-ach-details') : true,
      this.moveMoneyService.isRowHasValue('move-reason-amount'),
      this.moveMoneyService.isRowHasValue('move-from'),
      this.moveMoneyService.isRowHasValue('move-to'),
    ];

    return compact(rows).length !== rows.length;
  }

  get isDataUnchanged(): boolean {
    const { moveHowType } = this.moveMoneyService.moveHow;
    return (
      compact([
        !isEmpty(this.moveMoneyService.moveHow),
        moveHowType === 'ach' && !isEmpty(this.moveMoneyService.moveHowAchDetails),
        moveHowType === 'wire' && !isEmpty(!!this.moveMoneyService.wireDetails),
        !!this.moveMoneyService.paymentReason,
        !!this.moveMoneyService.moveAmount,
        !!this.moveMoneyService.selectedDebitFinancialAccount,
        !!this.moveMoneyService.selectedCreditFinancialAccount,
        !!this.moveMoneyService.note,
      ]).length === 0
    );
  }

  get achEntryTypeDisplayName(): string {
    const { moveAchEntryType } = this.moveMoneyService.moveHowAchDetails;

    return achEntryTypeCodes.find((item) => item.value === moveAchEntryType)?.label ?? '';
  }

  get achDetailsFieldsCount(): number {
    const { rkorACHIndividualId, rkorACHCheckSerialNumber, rkorACHTerminalCity, rkorACHTerminalState } =
      this.moveMoneyService.moveHowAchDetails;

    return compact([rkorACHIndividualId, rkorACHCheckSerialNumber, rkorACHTerminalCity, rkorACHTerminalState]).length;
  }

  get moveAchEntryType(): string {
    const { moveAchEntryType = '' } = this.moveMoneyService.moveHowAchDetails;
    return moveAchEntryType;
  }

  constructor(
    public ref: ChangeDetectorRef,
    private router: Router,
    private modalService: ModalService,
    private transactionService: TransactionService,
    private scheduledTransactionsService: ScheduledTransactionsService,
    private noteService: NoteService,
    private notificationService: NotificationService,
    public moveMoneyService: AbstractMoveMoneyBase,
    private store: Store,
  ) {
    this.idempotencyKey = `idemp-${uuidv4()}`;
    this.moveMoneyService.handleRowExpand('move-how');
  }

  ngOnInit(): void {
    this.store.dispatch(TransactionSolutionActions.loadEnabledSolutions());
    this.enabledSolutionConfigs$.subscribe({ next: (config) => this.onConfigFetched(config) });
  }

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public canDeactivate(): boolean {
    return this.isSubmitCompleted ? true : this.isDataUnchanged;
  }

  onMoveHowSelect(moveHow: MoveHowSelection): void {
    this.moveMoneyService.moveHow = moveHow;
    const { moveDirection, moveHowType } = moveHow;

    if (moveDirection && moveHowType) {
      this.moveHowAchDetailsSelection?.setAchEntryTypeOptions();
      this.expandNotFilledRow();
    }
  }

  onMoveHowAchDetailsSelect(achDetails: MoveHowAchDetails): void {
    this.moveMoneyService.moveHowAchDetails = achDetails;
  }

  onMoveHowWireDetailsSelect(wireDetails: MoveHowWireDetails): void {
    this.moveMoneyService.moveHowWireDetails = wireDetails;
    this.expandNotFilledRow();
  }

  onMoveHowAchDetailsBlur(): void {
    if (this.moveMoneyService.isRowHasValue('move-how-ach-details')) {
      this.expandNotFilledRow();
    }
  }

  onPaymentReasonSelect(paymentReason: PaymentReason): void {
    this.moveMoneyService.paymentReason = paymentReason;

    this.onReasonAmountElBlur();
  }

  onReasonAmountElBlur(): void {
    if (this.moveMoneyService.isRowHasValue('move-reason-amount')) {
      this.expandNotFilledRow();
    }
  }

  onDebitFinancialAccountSelect(financialAccount: FinancialAccountListItem): void {
    this.moveMoneyService.selectedDebitFinancialAccount = financialAccount;
    this.moveMoneyService.debitFinancialAccountError = false;
    this.expandNotFilledRow();
  }

  onCreditFinancialAccountSelect(financialAccount: FinancialAccountListItem): void {
    this.moveMoneyService.selectedCreditFinancialAccount = financialAccount;
    this.expandNotFilledRow();
  }

  resetForm(): void {
    this.moveMoneyService.moveHow = {};
    this.moveMoneyService.moveHowAchDetails = {};
    this.moveHowAchDetailsSelection?.resetValue();
    this.moveHowWireDetailsSelection?.resetValue();
    this.moveHowSelection?.resetValue();
    this.wireDetailsSelection?.resetValue();
    this.reasonSelect?.resetValue();
    this.amountField?.resetValue();
    this.moveFromSelection?.resetValue();
    this.moveToSelection?.resetValue();
    this.noteField?.resetValue();
    this.moveMoneyService.moveAmountError = undefined;
    this.moveMoneyService.handleRowExpand('move-how');
  }

  onClearBtnClick(): void {
    const confirmModalRef = this.modalService.open(ConfirmModalComponent, { className: 'confirm-modal' });

    confirmModalRef.componentInstance.customText =
      'The data you have captured on this page will be lost. Are you sure you want to clear all information and selections?';

    confirmModalRef.result.then(
      (result) => {
        if (result) {
          this.resetForm();
        }
      },
      () => false,
    );
  }

  expandNotFilledRow() {
    const isACH = this.moveMoneyService.moveHow.moveHowType === 'ach';
    const isWire = this.moveMoneyService.moveHow.moveHowType === 'wire';
    const rows: MoveRowType[] = compact([
      'move-how',
      isACH ? 'move-how-ach-details' : undefined,
      isWire ? 'move-how-wire-details' : undefined,
      'move-reason-amount',
      'move-from',
      'move-to',
    ]);

    rows.every((row) => {
      if (!this.moveMoneyService.isRowHasValue(row)) {
        this.moveMoneyService.handleRowExpand(row);
        return false;
      }

      return true;
    });

    const isAllRowsHaveValues =
      compact([
        !this.moveMoneyService.isRowHasValue('move-how'),
        this.moveMoneyService.moveHow.moveHowType === 'ach' && !this.moveMoneyService.isRowHasValue('move-how-ach-details'),
        this.moveMoneyService.moveHow.moveHowType === 'wire' && !this.moveMoneyService.isRowHasValue('move-how-wire-details'),
        !this.moveMoneyService.isRowHasValue('move-reason-amount'),
        !this.moveMoneyService.isRowHasValue('move-from'),
        !this.moveMoneyService.isRowHasValue('move-to'),
      ]).length === 0;

    if (isAllRowsHaveValues) {
      this.moveMoneyService.handleRowExpand();
    }
  }

  onMoveHowReset(): void {
    this.reasonSelect?.resetValue();
    this.amountField?.resetValue();
    this.moveHowAchDetailsSelection?.resetValue();
    this.moveHowWireDetailsSelection?.resetValue();
    this.wireDetailsSelection?.resetValue();
    this.moveFromSelection?.resetValue();
    this.moveToSelection?.resetValue();
    this.noteField?.resetValue();

    this.moveMoneyService.handleRowExpand('move-how');
  }

  getMoveMoneyData() {
    const { moveDirection, moveHowType } = this.moveMoneyService.moveHow;
    const { reason } = this.moveMoneyService.paymentReason ?? {};

    return {
      selectedMoveItem: moveDirection,
      selectedMoveHowType: moveHowType,
      paymentReasonName: reason,
      moveAmountValue: this.moveMoneyService.moveAmount,
      selectedMoveTo: this.moveMoneyService.selectedCreditFinancialAccount,
      selectedMoveFrom: this.moveMoneyService.selectedDebitFinancialAccount,
      note: this.moveMoneyService.note,
      achDetails: this.moveMoneyService.moveHowAchDetails,
      moveHowDirectionDisplayValue: this.moveMoneyService.moveHowDirectionDisplayValue,
      moveHowSolutionDisplayValue: this.moveMoneyService.moveHowSolutionDisplayValue,
      moveHowSolutionIcon: this.moveMoneyService.moveHowSolutionIcon,
      achEntryTypeDisplayName: this.achEntryTypeDisplayName,
      moveAchEntryType: this.moveAchEntryType,
      moveHowAchDetails: this.moveMoneyService.moveHowAchDetails,
      moveHowWireDetails: this.moveMoneyService.moveHowWireDetails,
      wireDetails: this.moveMoneyService.wireDetails,
      scheduleDetails: this.moveMoneyService.scheduleDetails,
    };
  }

  moveMoneyCheckSubmitConditions(): void {
    if (isEmpty(this.moveMoneyService.moveHow)) {
      return;
    }

    if (this.moveMoneyService.moveHow.moveHowType === 'push-to-card') {
      this.moveMoneyReview();
      return;
    }
    if (
      this.moveMoneyService.moveHow.moveHowType === 'ach' &&
      this.moveMoneyService.moveHowAchDetails.moveAchType === 'SAME_DAY' &&
      this.moveMoneyService.moveAmount &&
      parseFloat(this.moveMoneyService.moveAmount) > SAME_DAY_ACH_LIMIT
    ) {
      const errorModalRef = this.modalService.open(MoveMoneyAttentionModalComponent, { className: 'confirm-modal' });

      errorModalRef.componentInstance.attentionType = 'sameDayACHLimit';

      errorModalRef.result.finally(() => {
        this.moveMoneyService.handleRowExpand('move-reason-amount');
      });
    } else if (
      this.moveMoneyService.moveHowAchDetails.moveAchType === 'SAME_DAY' &&
      (isBankingHoliday() || isTimeExpired(this.sameDayAchSubmitDeadline, this.transactionTimeZone))
    ) {
      const confirmModalRef = this.modalService.open(MoveMoneyAttentionModalComponent, { className: 'confirm-modal' });

      confirmModalRef.componentInstance.attentionType = 'sameDayWindowClosed';

      confirmModalRef.result.then(
        (result) => {
          if (result) {
            this.moveHowAchDetailsSelection.onAchTypeSelect('NEXT_DAY');
            this.moveMoneyReview();
          }
        },
        () => {
          this.moveMoneyService.handleRowExpand('move-how');
        },
      );
    } else {
      this.moveMoneyReview();
    }
  }

  moveMoneyReview(): void {
    const reviewModalRef = this.modalService.open(MoveMoneyReviewComponent, {
      className: 'move-money-review-modal',
    });
    reviewModalRef.componentInstance.modalInitData(this.getMoveMoneyData());

    reviewModalRef.result.then(
      (result) => {
        if (result) {
          this.moveMoneySubmit();
        }
      },
      () => false,
    );
  }

  moveMoneySubmit(): void {
    this.submitLoader = true;
    const paymentReasonId = this.moveMoneyService.paymentReason!.id!;
    const note = this.moveMoneyService.note;
    const isACH = this.moveMoneyService.moveHow.moveHowType === 'ach';
    const isWire = this.moveMoneyService.moveHow.moveHowType === 'wire';
    const isScheduled = !!this.moveMoneyService.scheduleDetails?.scheduleDateTime;

    const { moveAchEntryType, rkorACHIndividualId, rkorACHCheckSerialNumber, rkorACHTerminalCity, rkorACHTerminalState } =
      this.moveMoneyService.moveHowAchDetails;

    const wireSettlementPriorityOrUndefined = isWire ? this.moveMoneyService.moveHowWireDetails.moveWireType : undefined;

    const moveMoneyData: MoveMoneyRequest = {
      debitFinancialAccountId: this.moveMoneyService.selectedDebitFinancialAccount!.id,
      creditFinancialAccountId: this.moveMoneyService.selectedCreditFinancialAccount!.id,
      paymentReasonId,
      amount: this.moveMoneyService.moveAmount!,
      solution: this.moveMoneyService.moveHow.moveHowType!,
      transactionType: this.moveMoneyService.isMoveDirectionPull ? 'REQUEST' : 'SEND',
      settlementPriority: isACH ? this.moveMoneyService.moveHowAchDetails.moveAchType : wireSettlementPriorityOrUndefined,
    };

    if (isACH) {
      moveMoneyData.metadata = {
        rkorProvider: ACH_PROVIDER,
        rkorACHEntryType: moveAchEntryType!,
        rkorACHIndividualId,
        rkorACHCheckSerialNumber,
        rkorACHTerminalCity,
        rkorACHTerminalState,
      };
    }

    if (isWire) {
      moveMoneyData.description = this.moveMoneyService.wireDetails.narrative;
      moveMoneyData.memo = this.moveMoneyService.wireDetails.memo;
    }

    if (isScheduled) {
      moveMoneyData.startDateTime = this.moveMoneyService.scheduleDetails?.scheduleDateTime;
      moveMoneyData.recurrenceRule = this.moveMoneyService.scheduleDetails?.recurrenceRule
        ? this.moveMoneyService.scheduleDetails.recurrenceRule
        : undefined;
      moveMoneyData.name = this.moveMoneyService.scheduleDetails?.name;

      of(true)
        .pipe(
          concatLatestFrom(() => [
            this.store.select(fromAuth.selectBusinessAccountId),
            this.store.select(fromTransaction.selectSolutionCurrency(this.moveMoneyService.moveHow.moveHowType!)),
          ]),
          mergeMap(([, activeBusinessAccountId, currency]) => {
            return this.scheduledTransactionsService
              .createScheduledTransaction(activeBusinessAccountId, { ...moveMoneyData, currency }, this.idempotencyKey)
              .pipe(
                switchMap((scheduledTransactionResponse) => this.addNoteToScheduledTransaction(scheduledTransactionResponse, note)),
                take(1),
                takeUntil(this.destroy$),
              );
          }),
        )
        .subscribe({
          next: (scheduledTransaction) => this.onScheduleSuccess(scheduledTransaction.id),
          error: (error) => {
            this.onSubmitError(error);
          },
        });
    } else {
      this.store
        .select(fromTransaction.selectSolutionCurrency(this.moveMoneyService.moveHow.moveHowType!))
        .pipe(
          exhaustMap((currency) =>
            this.transactionService.createMoveMoneyTransaction({ ...moveMoneyData, currency }, this.idempotencyKey).pipe(
              switchMap((transactionResponse) => this.addNoteToTransaction(transactionResponse, note)),
              take(1),
              takeUntil(this.destroy$),
            ),
          ),
        )
        .subscribe({
          next: (transaction) => this.onSubmitSuccess(transaction),
          error: (error) => {
            this.onSubmitError(error);
          },
        });
    }
  }

  addNoteToTransaction(transactionResponse: TransactionDetails, note?: string): Observable<TransactionDetails> {
    if (!note) {
      return of(transactionResponse);
    }

    return this.noteService
      .addNote(
        {
          entityId: transactionResponse.id,
          entityType: 'TRANSACTION',
          contentText: note,
        },
        'Transaction created successfully. Unable to create note for Transaction',
      )
      .pipe(
        catchError((error) => {
          ErrorUtils.catchError('noteService.addNote', error);
          return of(transactionResponse);
        }),
        map(() => transactionResponse),
      );
  }

  onSubmitSuccess(transaction: TransactionDetails): void {
    this.submitLoader = false;

    const successModalRef = this.modalService.open(MoveMoneyModalComponent, {
      className: 'move-money-success-modal',
    });
    successModalRef.componentInstance.id = transaction.id;
    successModalRef.componentInstance.solution = compact([
      this.moveMoneyService.moveHowSolutionDisplayValue,
      this.achEntryTypeDisplayName,
    ]).join(' | ');
    successModalRef.componentInstance.settlementPriority = toTitleCase(
      this.moveMoneyService.moveHowAchDetails.moveAchType?.replace('_', ' '),
    );
    successModalRef.componentInstance.fromAccountName = this.moveMoneyService.selectedDebitFinancialAccount?.accountHolderType
      ? compact([
          this.moveMoneyService.selectedDebitFinancialAccount?.customer?.displayName,
          this.moveMoneyService.selectedDebitFinancialAccount?.recipient?.displayName,
          '|',
          toTitleCase(this.moveMoneyService.selectedDebitFinancialAccount?.accountHolderType),
          toTitleCase(this.moveMoneyService.selectedDebitFinancialAccount?.customer?.type),
          toTitleCase(this.moveMoneyService.selectedDebitFinancialAccount?.recipient?.recipientType),
        ]).join(' ')
      : undefined;
    successModalRef.componentInstance.fromAccountNumber = compact([
      this.moveMoneyService.selectedDebitFinancialAccount?.name,
      this.moveMoneyService.selectedDebitFinancialAccount?.displayName,
    ]).join(' ');
    successModalRef.componentInstance.fromAccountBalance = this.moveMoneyService.selectedDebitFinancialAccount?.availableBalance;
    successModalRef.componentInstance.toAccountName = this.moveMoneyService.selectedCreditFinancialAccount?.accountHolderType
      ? compact([
          this.moveMoneyService.selectedCreditFinancialAccount?.customer?.displayName,
          this.moveMoneyService.selectedCreditFinancialAccount?.recipient?.displayName,
          '|',
          toTitleCase(this.moveMoneyService.selectedCreditFinancialAccount?.accountHolderType),
          toTitleCase(this.moveMoneyService.selectedCreditFinancialAccount?.customer?.type),
          toTitleCase(this.moveMoneyService.selectedCreditFinancialAccount?.recipient?.recipientType),
        ]).join(' ')
      : undefined;
    successModalRef.componentInstance.toAccountNumber = compact([
      this.moveMoneyService.selectedCreditFinancialAccount?.name,
      this.moveMoneyService.selectedCreditFinancialAccount?.displayName,
    ]).join(' ');
    successModalRef.componentInstance.toAccountBalance = this.moveMoneyService.selectedCreditFinancialAccount?.availableBalance;
    successModalRef.componentInstance.toAccountAddress = this.moveMoneyService.selectedCreditFinancialAccount?.bankAccount?.billingAddress;
    successModalRef.componentInstance.reason = this.moveMoneyService.paymentReason?.reason;
    successModalRef.componentInstance.amount = this.moveMoneyService.moveAmount;
    successModalRef.result.then(
      (result) => {
        if (result) {
          this.isSubmitCompleted = true;
          this.router.navigate(['app', 'transactions', 'transactions', transaction.id]);
        } else {
          this.onSubmitResultModalClose();
        }
      },
      () => {
        this.onSubmitResultModalClose();
      },
    );
  }

  addNoteToScheduledTransaction(transactionResponse: ScheduledTransactionDetails, note?: string): Observable<ScheduledTransactionDetails> {
    if (!note) {
      return of(transactionResponse);
    }

    return this.noteService
      .addNote(
        {
          entityId: transactionResponse.id,
          entityType: 'TRANSACTION',
          contentText: note,
        },
        'Transaction scheduled successfully. Unable to create note for Scheduled Transaction',
      )
      .pipe(
        catchError((error) => {
          ErrorUtils.catchError('noteService.addNote', error);
          return of(transactionResponse);
        }),
        map(() => transactionResponse),
      );
  }

  onScheduleSuccess(transactionId: ScheduledTransactionDetails['id']): void {
    this.submitLoader = false;

    const successModalRef = this.modalService.open(MoveMoneyModalComponent, {
      className: 'move-money-success-modal',
    });

    const fromAccountName = this.moveMoneyService.selectedDebitFinancialAccount?.accountHolderType
      ? compact([
          this.moveMoneyService.selectedDebitFinancialAccount?.customer?.displayName,
          this.moveMoneyService.selectedDebitFinancialAccount?.recipient?.displayName,
          '|',
          toTitleCase(this.moveMoneyService.selectedDebitFinancialAccount?.accountHolderType),
          toTitleCase(this.moveMoneyService.selectedDebitFinancialAccount?.customer?.type),
          toTitleCase(this.moveMoneyService.selectedDebitFinancialAccount?.recipient?.recipientType),
        ]).join(' ')
      : undefined;

    const toAccountName = this.moveMoneyService.selectedCreditFinancialAccount?.accountHolderType
      ? compact([
          this.moveMoneyService.selectedCreditFinancialAccount?.customer?.displayName,
          this.moveMoneyService.selectedCreditFinancialAccount?.recipient?.displayName,
          '|',
          toTitleCase(this.moveMoneyService.selectedCreditFinancialAccount?.accountHolderType),
          toTitleCase(this.moveMoneyService.selectedCreditFinancialAccount?.customer?.type),
          toTitleCase(this.moveMoneyService.selectedCreditFinancialAccount?.recipient?.recipientType),
        ]).join(' ')
      : undefined;

    successModalRef.componentInstance.modalInitData({
      id: transactionId,
      solution: compact([this.moveMoneyService.moveHowSolutionDisplayValue, this.achEntryTypeDisplayName]).join(' | '),
      settlementPriority: toTitleCase(this.moveMoneyService.moveHowAchDetails.moveAchType?.replace('_', ' ')),
      fromAccountName,
      fromAccountNumber: compact([
        this.moveMoneyService.selectedDebitFinancialAccount?.name,
        this.moveMoneyService.selectedDebitFinancialAccount?.displayName,
      ]).join(' '),
      fromAccountBalance: this.moveMoneyService.selectedDebitFinancialAccount?.availableBalance,
      toAccountName,
      toAccountNumber: compact([
        this.moveMoneyService.selectedCreditFinancialAccount?.name,
        this.moveMoneyService.selectedCreditFinancialAccount?.displayName,
      ]).join(' '),
      toAccountBalance: this.moveMoneyService.selectedCreditFinancialAccount?.availableBalance,
      toAccountAddress: this.moveMoneyService.selectedCreditFinancialAccount?.bankAccount?.billingAddress,
      reason: this.moveMoneyService.paymentReason?.reason,
      amount: this.moveMoneyService.moveAmount,
      name: this.moveMoneyService.scheduleDetails?.name,
      scheduleDateTimeDisplayValue: this.moveMoneyService.scheduleDetails?.scheduleDateTimeDisplayValue,
      scheduleRecurringDisplayValue: this.moveMoneyService.scheduleDetails?.scheduleRecurringDisplayValue,
      title: 'Transaction Scheduled',
    });

    successModalRef.result.then(
      (result) => {
        if (result) {
          this.isSubmitCompleted = true;
          this.router.navigate(['app', 'transactions', 'scheduled-transactions', transactionId]);
        } else {
          this.onSubmitResultModalClose();
        }
      },
      () => {
        this.onSubmitResultModalClose();
      },
    );
  }

  onSubmitError(error?: string | number): void {
    this.submitLoader = false;
    ErrorUtils.catchError('transactionService.createMoveMoneyTransaction', error);

    const errorModalRef = this.modalService.open(MoveMoneyErrorModalComponent, { className: 'confirm-modal' });
    errorModalRef.componentInstance.errorType = error;
    errorModalRef.result.finally(() => {
      this.moveMoneyErrorHandler(error);
      this.isSubmitCompleted = true;
    });
  }

  onSubmitResultModalClose(): void {
    this.isSubmitCompleted = true;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(
      () => {
        this.router.navigate(['app', 'transactions', 'move-money']);
      },
      () => null,
    );
  }

  onConfigFetched(config: EnabledSolutionConfig[] | null): void {
    if (!config) return;

    this.transactionTimeZone = config[0]?.solutionConfig?.transactionWindows[0]?.timeZone;
    this.sameDayAchSubmitDeadline = this.getEndTime(config, 'ach');
    this.sameDayWireSubmitDeadline = this.getEndTime(config, 'wire');

    if (isBankingHoliday() || isTimeExpired(this.sameDayAchSubmitDeadline, this.transactionTimeZone)) {
      this.isSameDayAchWindowDisabled = true;
    }

    if (isBankingHoliday() || isTimeExpired(this.sameDayWireSubmitDeadline, this.transactionTimeZone)) {
      this.isSameDayWireWindowDisabled = true;
    }

    this.isNextDayWireWindowDisabled = !this.isSameDayWireWindowDisabled;
  }

  getEndTime(config: EnabledSolutionConfig[], solution: string): string {
    return config
      .filter((item) => item.solutionName === solution)[0]
      ?.solutionConfig?.transactionWindows.filter((window) => window.priority === 'SAME_DAY')[0]?.endTime;
  }

  moveMoneyErrorHandler(errorType?: string | number) {
    if (!errorType) {
      return;
    }

    if (errorType === 'non-sufficient-funds') {
      this.moveMoneyService.handleRowExpand('move-reason-amount');
      this.moveMoneyService.moveAmountError = true;
    } else if (typeof errorType === 'string' && errorType.includes('insufficient-limit')) {
      this.moveMoneyService.handleRowExpand('move-from');
      this.moveMoneyService.debitFinancialAccountError = true;
    }
  }

  onWireDetailsChange(wireDetails: MoveWireDetails): void {
    this.moveMoneyService.wireDetails = wireDetails;
  }
}
