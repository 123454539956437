<div class="history-title">History</div>

<div class="history-items" #contentElementRef>
  <ng-container *ngIf="!loading; else historyLoading">
    <app-history-item
      *ngFor="let item of transactionHistoryItems; let i = index"
      [item]="item"
      [isLastItem]="transactionHistoryItems ? i === transactionHistoryItems.length - 1 : false"
      [isFirstItem]="i === 0"
      [isExpandedByDefault]="item.id === firstItemWithSubItems"
      [loading]="loading"
      [currentTransactionId]="transactionId"
    ></app-history-item>

    <div *ngIf="!transactionHistoryItems?.length" class="empty-history">Transaction history is empty.</div>
  </ng-container>

  <ng-template #historyLoading>
    <app-history-item [loading]="true" [isFirstItem]="true"></app-history-item>
    <app-history-item [loading]="true"></app-history-item>
    <app-history-item [loading]="true" [isLastItem]="true"></app-history-item>
  </ng-template>
</div>
