import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { TransactionBatch } from '@shared/models';
import { SubscriberService } from '@shared/services';

@Component({
  templateUrl: './transaction-batch-details.component.html',
  styleUrls: ['./transaction-batch-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionBatchDetailsComponent implements OnInit, AfterViewChecked, OnDestroy {
  pageClassPrefix = 'transaction-batch-details-';

  transactionBatch!: TransactionBatch | null;

  private destroy$ = new Subject<void>();

  get loading() {
    return !this.transactionBatch;
  }

  constructor(public ref: ChangeDetectorRef, protected layoutOptions: SubscriberService<TransactionBatch>) {}

  public ngOnInit(): void {
    this.layoutOptions.subscribe((transactionBatch) => {
      this.transactionBatch = { ...transactionBatch };
    });
  }

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
