import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-expandable-row',
  moduleId: module.id,
  templateUrl: 'expandable-row.component.html',
  styleUrls: ['expandable-row.component.scss'],
})
export class ExpandableRowComponent {
  @Input() title?: string;

  @Input() titleIconName?: string;

  @Input() isCollapsed: boolean = true;

  toggleRow() {
    this.isCollapsed = !this.isCollapsed;
  }
}
